import axios from 'axios';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';

const BuyBondStepFour = ({ bondCount, setBondCount, payCoin }) => {
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const {
    ownerEmail,
    appColorCode,
    setActionFlow,
    selectedBond,
    setSelectedBond,
    selectedCurrency,
    appCode,
  } = useAppContextDetails();

  const [amount, setAmount] = useState(0);
  const [allVaults, setAllVaults] = useState([]);
  const [filteredVaults, setFilteredVaults] = useState([]);
  const [query, setQuery] = useState('');

  const [loading, setLoading] = useState(false);
  const [updatedBalance, setUpdatedBalance] = useState(null);

  const getUpdatedBalance = async () => {
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email: email,
        include_coins: [`${payCoin.coinSymbol}`],
      })
      .then(({ data }) => {
        console.log(data, 'jqdjkbwekdbwekdw');
        setUpdatedBalance(data.coins_data[0].coinValue);
      });
  };

  useEffect(() => {
    getUpdatedBalance();
  }, []);

  return (
    <>
      <div>
        <div style={{ background: appColorCode, height: '15vh' }}>&nbsp;</div>
        <div className="imgWrapper">
          <div className="imgCointainer">
            <img
              src={selectedBond?.bankerData?.profilePicURL}
              alt=""
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        </div>
        <div
          style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}
        >
          {selectedBond?.bankerData?.displayName} Bond
        </div>
        <div style={{ textAlign: 'center' }}>
          <span>Terms</span> {`>`}{' '}
          <span style={{ fontWeight: 'bold' }}>Success</span>
        </div>
        <div className="dataContainer">
          <div>
            <div
              style={{
                paddingTop: '30px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Step 4: Success
            </div>
            <div>
              You Have Successfully Purchased {bondCount}{' '}
              {selectedBond.coinsData[0].coin} Bonds
            </div>
          </div>

          {/* Updated Balance */}
          <div>
            <div className="cardlabel">
              Updated {payCoin.coinSymbol} Balance
            </div>
            <div
              className="cardStyle"
              style={{ justifyContent: 'space-between' }}
            >
              <div className="cardText">
                {updatedBalance ? updatedBalance : 'Loading ...'}
              </div>
              <img src={payCoin.coinImage} className="cardImage" />
            </div>
          </div>

          <div className="doubleButton">
            <div className="submitButton" style={{ background: 'white' }}>
              <div style={{ fontWeight: 'bold' }}>See Bond Hash</div>
            </div>
            <div
              className="submitButton"
              style={{ background: appColorCode }}
              // onClick={handleBuy}
              //   onClick={confirmQuoteFunction}
              onClick={(e) => setActionFlow('')}
            >
              <div style={{ color: 'white', fontWeight: 'bold' }}>
                Back to Market
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBondStepFour;
