import React, { createContext, useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import * as d3 from 'd3';
import { BankContext } from './Context';
import { useQuery } from 'react-query';
import { FormatCurrency } from '../utils/FunctionTools';
import affiliateBankIcon from '../static/images/assetClassIcons/affiliateBank.svg';
import cryptoCurrenciesIcon from '../static/images/assetClassIcons/cryptoCurrencies.svg';
import fiatCurrenciesIcon from '../static/images/assetClassIcons/fiatCurrencies.svg';
import fixedIncomeIcon from '../static/images/assetClassIcons/fixedIncome.svg';
import investmentsIcon from '../static/images/assetClassIcons/investments.svg';
import loansIcon from '../static/images/assetClassIcons/loans.svg';
import moneyMarketsIcon from '../static/images/assetClassIcons/moneyMarkets.svg';
import { useAppContextDetails } from './AppContext';

export const NetWorthContext = createContext();

const getEqtCoins = async ({ queryKey }) => {
  const [_key, investmentSubType] = queryKey;
  const { data } = await Axios.get(
    `https://comms.globalxchange.io/coin/investment/path/user/vault/balances/get?email=shorupan@gmail.com&investmentType=EQT&investmentSubType=${investmentSubType}`
  );
  return data?.vaultsData || [];
};

const getTotalFundsData = async ({ queryKey }) => {
  const [_key, { email, appCurrencyName }] = queryKey;
  const { data } = await Axios.get(
    `https://comms.globalxchange.io/coin/vault/service/user/total/funds/data/get?email=${email}&displayCurrency=${appCurrencyName}`
  );
  return data;
};

function NetWorthContextProvider({ children }) {
  const colors = d3.scaleOrdinal(d3.schemeTableau10);
  const { email, coinListObject } = useContext(BankContext);
  const [totalBalance, setTotalBalance] = useState(0);
  const [fiatBalance, setFiatBalance] = useState(0);
  const [cryptoBalance, setCryptoBalance] = useState(0);
  const [cryptoCount, setCryptoCount] = useState(0);
  const [fiatCount, setFiatCount] = useState(0);
  const [netWorth, setNetworth] = useState(0);
  const [liquidCrypto, setLiquidCrypto] = useState(0);
  const [liquidFiat, setLiquidFiat] = useState(0);

  const { appCurrencyName } = useAppContextDetails();

  const { data: portfolioData, isLoading: loadingAppBalance } = useQuery(
    ['getTotalFundsData', { email, appCurrencyName }],
    getTotalFundsData
  );

  useEffect(() => {
    const data = portfolioData;
    setTotalBalance(data?.networth_dc);
    setFiatBalance(data?.fiat?.balanceIn_dc);
    setFiatCount(data?.fiat?.count);
    setCryptoBalance(data?.crypto?.balanceIn_dc);
    setCryptoCount(data?.crypto?.count);
    setNetworth(data?.networth?.toFixed(2));
    setLiquidCrypto(data?.crypto?.liquid?.toFixed(2));
    setLiquidFiat(data?.fiat?.liquid?.toFixed(2));

    // Eqity
    const eqts =
      (data?.investmentTokensData?.pathTypes?.filter(
        (subType) => subType.investmentType === 'EQT'
      ) &&
        (data?.investmentTokensData?.pathTypes?.filter(
          (subType) => subType.investmentType === 'EQT'
        ))[0]?.subTypes) ||
      [];
  }, [portfolioData]);

  // User Apps List

  const [userApps, setUserApps] = useState([]);
  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
    ).then(({ data }) => {
      if (data.status) {
        setUserApps(data.userApps);
      }
    });
    return () => {};
  }, [email]);

  //Cards On First page
  const [mainCards, setMainCards] = useState([]);
  useEffect(() => {
    setMainCards([
      {
        name: 'Cryptocurrency',
        value: cryptoBalance,
        color: '#F7931A',
        percent: (cryptoBalance / totalBalance) * 100,
        assets: cryptoCount,
        assetText: 'Assets',
        type: 'asset_class',
        img: cryptoCurrenciesIcon,
      },
      {
        name: 'Forex Currencies',
        value: fiatBalance,
        color: '#04AF76',
        percent: (fiatBalance / totalBalance) * 100,
        assets: fiatCount,
        assetText: 'Assets',
        type: 'asset_class',
        img: fiatCurrenciesIcon,
      },
      {
        name: 'MoneyMarkets',
        value: portfolioData?.MoneyMarkets_Data?.totalMMBalance_dc,
        color: '#186AB4',
        percent:
          (portfolioData?.MoneyMarkets_Data?.totalMMBalance_dc / totalBalance) *
          100,
        assets: portfolioData?.MoneyMarkets_Data?.coins?.length,
        assetText: 'Assets',
        type: 'asset_class',
        tbd: true,
        img: moneyMarketsIcon,
        valueTwo: portfolioData?.MoneyMarkets_Data?.totalMMEarnings_dc,
        labelTwo: 'Lifetime Earnings',
        labelThree: "Today's Earnings",
        disabled: true,
      },
      {
        img: fixedIncomeIcon,
        name: 'Fixed Income',
        value: portfolioData?.Bonds_Data?.totalBondBalance_dc,
        color: '#50C7AD',
        percent:
          (portfolioData?.Bonds_Data?.totalBondBalance_dc / totalBalance) * 100,
        assets: portfolioData?.Bonds_Data?.coins?.length,
        assetText: 'Assets',
        type: 'asset_class',
        valueTwo: portfolioData?.Bonds_Data?.totalBondEarnings_dc,
        labelTwo: 'Lifetime Earnings',
        labelThree: "Today's Earnings",
        tbd: true,
        disabled: true,
      },
      {
        img: affiliateBankIcon,
        name: 'AffiliateBank',
        assetText: 'In 1 Asset',
        value:
          portfolioData?.commissionsData?.gxbroker_account_balance /
          coinListObject?.[appCurrencyName]?.coinPriceUSD,
        color: '#08152D',
        percent:
          (portfolioData?.commissionsData?.gxbroker_account_balance /
            coinListObject?.[appCurrencyName]?.coinPriceUSD /
            totalBalance) *
          100,
        type: 'asset_class',
        valueTwo:
          portfolioData?.commissionsData?.totalVolume /
          coinListObject?.[appCurrencyName]?.coinPriceUSD,
        labelTwo: 'Lifetime Earnings',
        valueThree:
          portfolioData?.commissionsData?.withdraw_sum /
          coinListObject?.[appCurrencyName]?.coinPriceUSD,
        labelThree: 'Total Withdrawn',
        tbd: true,
        disabled: true,
      },
      {
        name: 'Investments',
        value: portfolioData?.investmentTokensData?.totalHoldings_dc,
        color: '#002A51',
        percent:
          (portfolioData?.investmentTokensData?.totalHoldings_dc /
            totalBalance) *
          100,
        assets: portfolioData?.investmentTokensData?.pathTypes?.length,
        assetText: 'Investment Types',
        type: 'asset_class',
        tbd: true,
        valueTwo: portfolioData?.investmentTokensData?.totalInvested_dc,
        labelTwo: 'Total Investment',
        img: investmentsIcon,
      },
      {
        name: 'Loans',
        value: 0,
        percent: 0,
        color: '#FBD10D',
        assetText: 'Assets',
        type: 'coming_soon',
        img: loansIcon,
      },
    ]);
    return () => {};
  }, [
    totalBalance,
    fiatBalance,
    cryptoBalance,
    fiatCount,
    cryptoCount,
    coinListObject?.[appCurrencyName]?.coinPriceUSD,
  ]);

  // Tab Selected
  const [assetClass, setAssetClass] = useState(null);
  const [assetCoin, setAssetCoin] = useState(null);
  const [liquidity, setLiquidity] = useState(null);
  const [isBondRedeemed, setIsBondRedeemed] = useState(false);
  const [assetClassCode, setAssetClassCode] = useState('');

  useEffect(() => {
    if (assetClass === 'Cryptocurrency') {
      setAssetClassCode('crypto');
    } else if (assetClass === 'Forex Currencies') {
      setAssetClassCode('fiat');
    }
  }, [assetClass]);

  const { data: eqtCoinList } = useQuery(
    ['eqtCoinList', assetCoin],
    getEqtCoins
  );

  // Card List To Populate & Selected Total Balance
  const [cardList, setCardList] = useState([]);
  const [selectedTotalBalance, setSelectedTotalBalance] = useState(0);
  useEffect(() => {
    if (portfolioData?.status)
      switch (true) {
        // Set Data If Bond Redeemed
        case Boolean(isBondRedeemed):
          {
            const coinData =
              portfolioData &&
              portfolioData[assetClassCode].coins.filter(
                (coin) => coin.coinName === assetCoin
              )[0];
            let arr = [];
            coinData.bonds.bonds
              .filter((contract) => contract.status === 'redeemed')
              .forEach((contract, i) => {
                arr.push({
                  img: contract?.app_icon,
                  name: contract?.appName,
                  value: contract?.nativeValue,
                  color: colors(i),
                  percent:
                    (contract?.nativeValue / coinData?.bonds?.total) * 100,
                  type: 'app',
                  assets: `${contract?.totalDays - contract?.daysLeft} / ${
                    contract?.totalDays
                  }`,
                  assetText: 'Day Bond',
                  id: contract.bond_id,
                  status: contract.status,
                });
              });
            setCardList(arr);
          }
          break;
        // Set data for eqt sub type tokens
        // Set Data If Liquid/Bond Selected
        case Boolean(assetClass === 'Investments' && assetCoin && liquidity):
          {
            let arr = [];
            const pathType =
              portfolioData?.investmentTokensData?.pathTypes?.filter(
                (pathType) => pathType?.investmentType_data?.name === assetCoin
              )[0];
            const subType = pathType?.subTypes?.filter(
              (subType) => subType?.investmentSubType_data?.name === liquidity
            )[0];
            if (subType.tokens.length) {
              subType.tokens.forEach((token, i) => {
                arr.push({
                  img: token?.tokenImage,
                  name: token?.tokenName,
                  color: colors(i),
                  type: 'token',
                  assetText: `${token?.token} Balance: ${FormatCurrency(
                    token?.holdings
                  )}`,
                  value: token?.holdings_dc,
                  tbd: true,
                  valueTwo: token?.investedAmount_dc,
                  labelTwo: 'Total Investment',
                  valueThree: token?.holdings_dc - token?.investedAmount_dc,
                  labelThree: 'Open P/L',
                  percent:
                    (token?.holdings_dc /
                      subType?.investmentSubType_data?.stats
                        ?.subType_totalHoldings_dc) *
                    100,
                });
              });
            }
            setCardList(arr);
            setSelectedTotalBalance(
              subType?.investmentSubType_data?.stats?.subType_totalHoldings_dc
            );
          }
          break;
        case Boolean(liquidity):
          {
            const coinData =
              portfolioData &&
              portfolioData[assetClassCode]?.coins.filter(
                (coin) => coin.coinName === assetCoin
              )[0];
            if (liquidity === 'Liquid') {
              let arr = [];
              coinData.liquid.forEach((app, i) => {
                arr.push({
                  img: app?.app_icon,
                  name: app?.appName,
                  value: app?.nativeValue,
                  color: colors(i),
                  percent: (app?.dc_value / coinData.liquidtotal_dc) * 100,
                  type: 'app',
                  assetText: 'GX Vault',
                });
              });
              setCardList(arr);
              setSelectedTotalBalance(coinData?.liquidtotal_dc);
            } else {
              let arr = [];
              coinData?.bonds &&
                coinData.bonds.bonds
                  .filter((contract) => contract.status !== 'redeemed')
                  .forEach((contract, i) => {
                    arr.push({
                      img: contract?.app_icon,
                      name: contract?.appName,
                      value: contract?.nativeValue,
                      color: colors(i),
                      percent:
                        (contract?.nativeValue / coinData?.bonds?.total) * 100,
                      type: 'app',
                      assets: `${contract?.totalDays - contract?.daysLeft} / ${
                        contract?.totalDays
                      }`,
                      assetText: 'Day Bond',
                      id: contract.bond_id,
                      status: contract.status,
                    });
                  });
              setCardList(arr);
              setSelectedTotalBalance(coinData?.bondsTotalUSD);
            }
          }
          break;
        // Set data for eqt sub types
        case Boolean(assetClass === 'Investments' && assetCoin):
          {
            let arr = [];
            const pathType =
              portfolioData?.investmentTokensData?.pathTypes?.filter(
                (pathType) => pathType?.investmentType_data?.name === assetCoin
              )[0];
            if (pathType.subTypes.length) {
              pathType.subTypes.forEach((subType, i) => {
                arr.push({
                  img: subType?.investmentSubType_data?.icon,
                  name: subType?.investmentSubType_data?.name,
                  color: `#${
                    subType?.investmentSubType_data?.color_codes[0] || '000000'
                  }`,
                  type: 'subType',
                  assetText: `Holdings In ${subType?.tokens?.length} ${subType?.investmentSubType_data?.name}`,
                  value:
                    subType?.investmentSubType_data?.stats
                      ?.subType_totalHoldings_dc,
                  tbd: true,
                  valueTwo:
                    subType?.investmentSubType_data?.stats
                      ?.subType_totalInvested_dc,
                  labelTwo: 'Total Investment',
                  valueThree:
                    subType?.investmentSubType_data?.stats
                      ?.subType_totalHoldings_dc -
                    subType?.investmentSubType_data?.stats
                      ?.subType_totalInvested_dc,
                  labelThree: 'Open P/L',
                  percent:
                    (subType?.investmentSubType_data?.stats
                      ?.subType_totalHoldings_dc /
                      pathType?.investmentType_data?.stats
                        ?.type_totalHoldings_dc) *
                    100,
                });
              });
            }
            setCardList(arr);
            setSelectedTotalBalance(
              pathType?.investmentType_data?.stats?.type_totalHoldings_dc
            );
          }
          break;
        //Set Data For Selected Coin Cards
        case Boolean(assetCoin):
          {
            const coinData =
              portfolioData &&
              portfolioData[assetClassCode].coins.filter(
                (coin) => coin.coinName === assetCoin
              )[0];
            let arr = [
              {
                name: 'Liquid',
                value: coinData?.liquidtotal_dc,
                color: colors(0),
                percent: (coinData?.liquidtotal_dc / coinData?.dc_value) * 100,
                type: 'liquidity',
                assets: coinData?.liquid?.length,
                assetText: 'Wallets',
              },
              {
                name: 'Bonds',
                value: coinData?.bondsTotal_dc,
                color: colors(1),
                percent: (coinData?.bondsTotal_dc / coinData?.dc_value) * 100,
                type: 'liquidity',
                assets: coinData?.bonds?.bonds?.length,
                assetText: 'Bonds',
              },
            ];
            setCardList(arr);
            setSelectedTotalBalance(coinData?.dc_value);
          }
          break;

        case assetClass === 'Investments':
          {
            let arr = [];
            if (portfolioData?.investmentTokensData?.pathTypes?.length) {
              portfolioData.investmentTokensData.pathTypes.forEach(
                (pathType, i) => {
                  arr.push({
                    img: pathType?.investmentType_data?.icon,
                    name: pathType?.investmentType_data?.name,
                    color: `#${
                      pathType?.investmentType_data?.color_codes[0] || '000000'
                    }`,
                    type: 'investmentCoin',
                    assetText: `Holdings In ${pathType?.subTypes?.length} Types Of ${pathType?.investmentType_data?.name}`,
                    value:
                      pathType?.investmentType_data?.stats
                        ?.type_totalHoldings_dc,
                    tbd: true,
                    valueTwo:
                      pathType?.investmentType_data?.stats
                        ?.type_totalInvested_dc,
                    labelTwo: 'Total Investment',
                    valueThree:
                      pathType?.investmentType_data?.stats
                        ?.type_totalHoldings_dc -
                      pathType?.investmentType_data?.stats
                        ?.type_totalInvested_dc,
                    labelThree: 'Open P/L',
                    percent:
                      (pathType?.investmentType_data?.stats
                        ?.type_totalHoldings_dc /
                        portfolioData?.investmentTokensData?.totalHoldings_dc) *
                      100,
                  });
                }
              );
            }
            setCardList(arr);
            setSelectedTotalBalance(
              portfolioData?.investmentTokensData?.totalHoldings_dc
            );
          }
          break;
        //Set Data For Fiat / Crypto Cards
        case Boolean(assetClass):
          {
            let arr = [];
            if (portfolioData && portfolioData[assetClassCode]) {
              const coinList = portfolioData[assetClassCode].coins;
              coinList.forEach((coin, i) => {
                arr.push({
                  img: coin.coinImage,
                  name: coin.coinName,
                  value: coin.dc_value,
                  native: coin.nativeValue,
                  color: colors(i),
                  percent:
                    (coin.dc_value /
                      portfolioData[assetClassCode]?.balanceIn_dc) *
                    100,
                  type: 'coin',
                  assets: 2,
                  assetText: 'Liquidity Profiles',
                });
              });

              setCardList(arr);
              setSelectedTotalBalance(
                portfolioData[assetClassCode]?.balanceIn_dc
              );
            }
          }
          break;

        default:
          //Set Data For Home Cards
          setCardList(mainCards);
          setSelectedTotalBalance(totalBalance);
          break;
      }
  }, [
    mainCards,
    assetClass,
    assetClassCode,
    assetCoin,
    liquidity,
    isBondRedeemed,
    eqtCoinList,
  ]);

  const [tabData, setTabData] = useState({
    key: {
      assetClass,
      assetCoin,
      liquidity,
    },
  });

  const [tabIndex, setTabIndex] = useState('key');
  useEffect(() => {
    setTabData({
      ...tabData,
      [tabIndex]: {
        assetClass,
        assetCoin,
        liquidity,
        isBondRedeemed,
      },
    });
    // eslint-disable-next-line
  }, [assetClass, assetCoin, liquidity, isBondRedeemed]);

  return (
    <NetWorthContext.Provider
      value={{
        loadingAppBalance, //Show Balance In Loading State
        totalBalance,
        fiatBalance,
        cryptoBalance,
        cardList,
        selectedTotalBalance,
        tabData,
        assetClass,
        setAssetClass,
        assetCoin,
        setAssetCoin,
        liquidity,
        setLiquidity,
        isBondRedeemed,
        setIsBondRedeemed,
        userApps,
        // icedValues,
        tabData,
        setTabData,
        tabIndex,
        setTabIndex,
        portfolioData,
        // userApps,
        // userObjects,
        netWorth,
        liquidCrypto,
        liquidFiat,
      }}
    >
      {children}
    </NetWorthContext.Provider>
  );
}

export default NetWorthContextProvider;
