import React, { useContext, useEffect } from 'react';
import classNames from './bplanner.module.scss';
import Layout from '../../Layout/Layout';
import useWindowDimensions from '../../utils/WindowSize';
import NewMobileLayout from '../../Layout/MobilePages/NewMobileLayout';
import { BankContext } from '../../context/Context';
import { Redirect, useParams } from 'react-router-dom';
import ProgressPage from '../../components/ProgressPage';
import { useMarketContext } from '../../context/MarketContext';
import 'react-loading-skeleton/dist/skeleton.css';

import { LoadingAnimation } from '../../components/LoadingAnimation';
import EditSidebar from './Components/EditSidebar';
import VisionBoard from '../../components/VisionBoard';
import Sidebar from './Components/Sidebar';
import RSIPComponent from '../../components/RSIP';
import ConnectSipSidebar from '../../components/RSIP/Components/ConnectSipSidebar';
import Configuration from '../../components/ConfigurationPlanB';
import Progress from '../../components/Progress';
import PropsalPage from '../PropsalPage/PropsalPage';
function PlanBProgressPage() {
  const { width, height } = useWindowDimensions();
  const { email, selectedTopItem } = useContext(BankContext);
  const { globalappIcon, iconLoading } = useMarketContext();

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {width > 768 || width > height ? (
        <Layout
          active="bplanner"
          className={classNames.bplannerPage}
          hideFooter
        >
          {selectedTopItem == 'Progress' ? (
            // <ProgressPage />
            <Progress />
          ) : selectedTopItem == 'Vision Board' ? (
            <VisionBoard />
          ) : selectedTopItem == 'RSIP’s' ? (
            <RSIPComponent />
          ) : selectedTopItem == 'Configurations' ? (
            <Configuration />
          ) : 
          selectedTopItem == 'Proposals' ? (
          <PropsalPage/>
          ):
          (
            ''
          )}

          {/* <EditSidebar /> */}
          <Sidebar />
          <ConnectSipSidebar />

          {iconLoading ? (
            <div className="loadingIcon">
              <LoadingAnimation icon={globalappIcon} width={200} />
            </div>
          ) : (
            ''
          )}
        </Layout>
      ) : (
        <NewMobileLayout />
      )}
    </>
  );
}

export default PlanBProgressPage;
