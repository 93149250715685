import React, { useState } from 'react';
import { useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import './stakings.scss';
import axios from 'axios';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { useCoinContextData } from '../../../context/CoinContext';
import { useMarketContext } from '../../../context/MarketContext';
import { GlobalContext } from '../../../context/GlobalContext';

const Staking = () => {
  const history = useHistory();
  const { selectedContract, setSelectedContract } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const [isloading, setIsloading] = useState(true);
  const [value, setValue] = useState();
  const [coin, setCoin] = useState('');
  const [contractData, setContractData] = useState([]);
  const { pathname } = useLocation();
  const { coinType, setCoinType } = useCoinContextData();

  const { setCoinSelected, contractId } = useMarketContext();
  // const { setSelectedBondsNav } = useContext(GlobalContext);

  useEffect(() => {
    setIsloading(true);
    let mailId = email;
    if (contractId) {
      axios
        .get(
          `https://comms.globalxchange.io/coin/iced/contract/get?email=${mailId}&coin=${contractId}`
        )
        .then((response) => {
          let result = response?.data?.icedContracts[0]?.contracts;
          let stakevalue = response?.data?.icedContracts[0]?.currentVoc;
          let stakecoin = response?.data?.icedContracts[0]?._id;
          // console.log(result, 'contract result');
          setContractData(result);
          setValue(stakevalue);
          setCoin(stakecoin);
          setIsloading(false);
        })
        .catch((error) => {
          console.log(error, 'contract error');
        });
    }
  }, [contractId]);

  return (
    <>
      <div className="contract-container1">
        <div className="table-header1">
          <div style={{ paddingLeft: '2rem' }}>Date</div>
          <div>Contracts</div>
          <div>Investment</div>
          <div>Earnings</div>
          <div>Unpaid Earnings</div>
          <div>Days Till Expiry</div>
          <div style={{ textAlign: 'center' }}>Hash</div>
        </div>
        <div className="table-data1">
          <div className="scroll-div1">
            {isloading
              ? Array(10)
                  .fill(' ')
                  .map((item, index) => {
                    return (
                      <div className="table-card1" key={index}>
                        <div
                          className="date-val1"
                          style={{ paddingLeft: '2rem' }}
                        >
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val1">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val1">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val1">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val1">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div className="date-val1">
                          <Skeleton width={75} height={25} />
                        </div>
                        <div
                          className="date-val1"
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div
                            className="hash-btn1"
                            style={{ background: 'transparent' }}
                          >
                            <Skeleton width={75} height={25} />
                          </div>
                        </div>
                      </div>
                    );
                  })
              : contractData?.map((e, i) => {
                  return (
                    <div className="table-card1" key={i}>
                      <div
                        className="date-val1"
                        style={{ paddingLeft: '2rem' }}
                      >
                        {moment(e?.original_date).format('MMM Do YYYY')}
                      </div>
                      <div className="date-val1">{e?.num_of_bonds}</div>
                      <div className="date-val1">{e?.investment}</div>
                      <div className="date-val1">
                        {e?.completed_stats?.earnings?.toFixed(3)}
                      </div>
                      <div className="date-val1">
                        {e?.remaining_stats?.earnings?.toFixed(3)}
                      </div>
                      <div className="date-val1">
                        {e?.remaining_stats?.days}
                      </div>
                      <div
                        className="date-val1"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Link
                          className="hash-btn1"
                          // to={`/defi/explorer/${e?._id}`}
                          to="#"
                          onClick={() => {
                            setCoinSelected('defihash');
                            // setSelectedBondsNav('Stats');
                            window.open(
                              `https://defi.markets/hash/${e?._id}`,
                              '_blank'
                            );
                          }}
                        >
                          Hash
                        </Link>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Staking;
