import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BankContext } from '../../../context/Context';
import classNames from './commonComponents.module.scss';

import { InputDivs } from '../../../components/BuyBlocks/components/CreatePlanB';
import { uploadImageFunc } from '../../../utils/imageUpload';
import editColourIcon from "../../../static/images/editColourIcon.svg"

import uploadImg from '../../../static/images/goalUploadArrow.svg';
import { LoadingAnimation } from '../../../components/LoadingAnimation';
import axios from 'axios';
import { useMarketContext } from '../../../context/MarketContext';
import { toast } from 'react-toastify';
import MetaDataComponent from './MetaDataComponent';
import cloudUploadIcon from "../../../static/images/cloudUploadIcon.svg"
import loadingGif from '../../../static/images/loadingGif.gif';
import "./fileUpload.scss";


const Sidebar = () => {
  const {
    goalsDetails,
    setGoalsDetails,
    createGoals,
    setcreateGoals,
    goalsList,
    goalCreating,
  } = useContext(BankContext);
  const { globalappIcon } = useMarketContext();
  const [displayGoalDetails, setDisplayGoalDetails] = useState([]);
  const [shouldEditIcon, setShouldEditIcon] = useState(false)

  return (
    <div
      className={classNames.progressPage}
      style={{ right: goalsDetails || createGoals ? '0px' : '' }}
    >
      {goalsDetails ? (
        <GoalsDetailsSidebar shouldEditIcon={shouldEditIcon} setShouldEditIcon={setShouldEditIcon} setGoalsDetails={setGoalsDetails} setcreateGoals={setcreateGoals} />
      ) : createGoals ? (
        <CreateGoals />
      ) : (
        ''
      )}

      <div
        className={classNames.closeBtn}
        onClick={() => {
          setGoalsDetails('');
          setcreateGoals('');
          setShouldEditIcon(false)
        }}
        style={{
          pointerEvents: goalCreating ? 'none' : '',
          opacity: goalCreating ? '0.5' : '',
        }}
      >
        x
      </div>

      {goalCreating ? (
        <div className="loadingIcon">
          <LoadingAnimation icon={globalappIcon} width={200} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Sidebar;

const GoalsDetailsSidebar = ({ shouldEditIcon, setShouldEditIcon, setGoalsDetails, setcreateGoals }) => {
  const { sidebarShowProgress, refreshGoals, setRefreshGoals
  } = useContext(BankContext);

  const [imgLoading, setImgLoading] = useState(false)
  const [updatedData, setUpdatedData] = useState("")


  console.log("editIcon ", shouldEditIcon)

  const updateImg = () => {
    let obj = {
      image: updatedData
    }
    axios
      .put(`https://comms.globalxchange.io/retired/vision/update/visionplan?vision_id=${sidebarShowProgress[0]?.vision_id}`, obj)
      .then((response) => {
        if (response?.data?.status) {
          toast.success("Cover Updated Successfully")
          setGoalsDetails('');
          setcreateGoals('');
          setShouldEditIcon(false)
          setRefreshGoals(!refreshGoals)
          setUpdatedData("")
        }
      })
      .catch((error) => {
        console.log(error, 'error');
      });
  }

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  const uploadImage = async (e, setUpdatedData, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);

    let { data } = await axios.post(
      `https://publications.apimachine.com/article/upload`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setUpdatedData(data[0].urlName);
    setLoading(false);
    console.log(data[0].urlName)
  };

  console.log("visionData", sidebarShowProgress)

  return (
    shouldEditIcon ? (
      <div>
        <div className={classNames.editHead}>Edit Vision Board</div>
        <div className={classNames.editDesc}>Upload the new image for this vision board item</div>
        <div className="filesUpload" style={{ height: "120px", padding: 0, marginLeft: "40px", marginTop: "20px" }}>
          <label
            className="fileInp icon"
            style={{
              flex: "0 0 100%",
              maxWidth: "103px",
              height: "100px",
              borderRadius: "60px",
              background: "white"
            }}
          >
            <img
              style={{ width: updatedData ? "100px" : "46px", height: updatedData ? "100px" : "46px" }}
              className={`${Boolean(updatedData)}`}
              src={
                imgLoading
                  ? loadingGif
                  : updatedData || cloudUploadIcon
              }
              alt=""
            />
            <input
              type="file"
              onChange={(e) => {
                uploadImage(e, setUpdatedData, setImgLoading);
              }}
              accept="image/*"
            />
            <div className="hovTxt">
              Upload
              <br />
              New
            </div>
          </label>
        </div>
        <p style={{ marginLeft: "40px" }}>Upload Picture </p>
        <div className='submitIconBtn' onClick={() => updateImg()}>Submit Update</div>
      </div>
    ) : (
      <div className={classNames.goalsDetails}>
        <div style={{ position: "relative", padding: "0px" }}>
          <img
            src={
              sidebarShowProgress?.length > 0 ? sidebarShowProgress[0]?.image : ''
            }
            alt="theIcon"
          />
          <div className={classNames.editIconBtn} onClick={() => setShouldEditIcon(true)}>
            <img src={editColourIcon} alt="editIcon" />
          </div>
        </div>

        <div>
          <div className={classNames.titleText}>
            {sidebarShowProgress?.length > 0 ? sidebarShowProgress[0]?.name : ''}
          </div>
          <div className={classNames.details}>
            {sidebarShowProgress?.length > 0
              ? sidebarShowProgress[0]?.description
              : ''}
          </div>
        </div>
        <div>
          <a
            href={
              sidebarShowProgress?.length > 0 ? sidebarShowProgress[0]?.link : ''
            }
            target="_blank"
          >
            Link
          </a>
          <img
            src={
              sidebarShowProgress?.length > 0 ? sidebarShowProgress[0]?.image : ''
            }
            alt=""
          />
          <MetaDataComponent
            datalink={
              sidebarShowProgress?.length > 0 &&
                sidebarShowProgress[0]?.link != ''
                ? sidebarShowProgress[0]?.link
                : ''
            }
          />
        </div>
      </div>
    )
  );
};

const CreateGoals = () => {
  let { retiredid } = useParams();
  const {
    email,
    token,
    goalName,
    selectedTopItem,
    setgoalName,
    goalDesc,
    setgoalDesc,
    goalLink,
    setgoalLink,
    goalImage,
    setGoalImage,
    goalImageUploading,
    setGoalImageUploading,
    goalCreating,
    setgoalCreating,
    setGoalsDetails,
    setcreateGoals,
  } = useContext(BankContext);

  function createGoal() {
    setgoalCreating(true);
    let obj = {
      email: email,
      token: token,
      name: goalName,
      retirement_plan_id: retiredid,
      link: goalLink,
      image: goalImage ? goalImage : 'test',
      description: goalDesc,
    };
    console.log('sddd', obj);
    axios
      .post('https://comms.globalxchange.io/retired/vision/add/visionplan', obj)
      .then((response) => {
        if (response?.data?.status) {
          goalCreated();
        } else {
          goalCreationFailed();
        }
        setGoalsDetails('');
        setcreateGoals('');
        setgoalCreating(false);
      })
      .catch((error) => {
        console.log(error, 'error');
        goalCreationFailed(false);
        setgoalCreating(false);
      });
    // setgoalCreating(false);
    setgoalName('');
    setgoalLink('');
    setGoalImage('');
    setgoalDesc('');
  }

  function goalCreated() {
    toast('Goal Created...', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function goalCreationFailed() {
    toast('Goal creation failed...', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  return (
    <div className={classNames.createGoals}>
      <div className={classNames.title}>Add Goal To Vision Board</div>
      <div className={classNames.imageUpload}>
        <label for="goalUpdateImage" className={classNames.uploadFileDiv}>
          <input
            className={classNames.uploadNewPicPlanB}
            type="file"
            onChange={(e) => {
              uploadImageFunc(e, setGoalImage, setGoalImageUploading);
            }}
            accept="image/*"
            id="goalUpdateImage"
          />
          <img
            src={goalImage ? goalImage : uploadImg}
            alt="uploadImg"
            style={{
              width: goalImage ? '100%' : '',
              height: goalImage ? '100%' : '',
              objectFit: goalImage ? 'cover' : '',
            }}
          />
          <div style={{ display: goalImage ? 'none' : '' }}>
            {goalImageUploading ? 'Uploading...' : 'Upload Image Of Goal'}
          </div>
        </label>
      </div>
      <div>
        <InputDivs
          heading="Name The Goal"
          placeholderText="Name..."
          setFunc={setgoalName}
          funcValue={goalName}
        />
        <InputDivs
          heading="Describe Your Goal?"
          placeholderText="Description..."
          setFunc={setgoalDesc}
          funcValue={goalDesc}
        />
        <InputDivs
          heading="Add A Link"
          placeholderText="Enter Link"
          setFunc={setgoalLink}
          funcValue={goalLink}
        />
      </div>
      <div className={classNames.addGoalBtn} onClick={createGoal}>
        Add Goal To Vision Board
      </div>
    </div>
  );
};
