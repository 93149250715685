export function editConfiguration(edititem, changeState, selectedCurrency) {
  if (edititem == 'name') {
    changeState({
      title: 'Enter New Name',
      placeholder: 'Name..',
      body: 'retirement_plan_name',
      type: 'input',
    });
  } else if (edititem == 'description') {
    changeState({
      title: 'Enter New Description',
      placeholder: 'Description..',
      body: 'description',
      type: 'input',
    });
  } else if (edititem == 'accomplishmentamount') {
    changeState({
      title: 'Enter New Accomplishment Amount',
      placeholder: 'Amount..',
      body: 'amountToAccomplish',
      currency: selectedCurrency,
      type: 'input',
    });
  } else if (edititem == 'planbtype') {
    changeState({
      title: 'Select New PlanB Type',
      placeholder: 'Search...',
      body: 'goal_id',
      type: 'search',
    });
  } else if (edititem == 'accomplishmentdate') {
    changeState({
      title: 'When Do You Want To Accomplish Your Plan?',
      //   placeholder: 'Search...',
      body: 'date_of_accomplishment',
      type: 'date',
    });
  } else if (edititem == 'planicon') {
    changeState({
      title: 'Upload An Icon For This Plan',
      body: 'icon',
      type: 'image',
    });
  } else if (edititem == 'accomplishmentcurrency') {
    changeState({
      title: 'Select New Accomplishment Currency',
      body: 'CurrencyForTheAmount',
      type: 'search',
    });
  }
}
