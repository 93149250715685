import axios from 'axios';
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { planBContext } from '../../../context/PlanBContext';
import classNames from './progresscomponents.module.scss';
import moment from 'moment';
import { useState } from 'react';

const TimeBar = () => {
  let { retiredid } = useParams();

  const { timeProgressbar, settimeProgressbar } = useContext(planBContext);
  const [completionDate, setCompletionDate] = useState('');

  const overallProgressBar = '';

  useEffect(() => {
    getPlanBDetails();
  }, []);

  function getPlanBDetails() {
    axios
      .get(
        `https://comms.globalxchange.io/retired/block/get/All/retirementplans?retirement_plan_id=${retiredid}`
      )
      .then((response) => {
        // console.log(response?.data?.data[0], 'responseee');

        if (
          response?.data?.data?.length > 0 &&
          response?.data?.data[0]?.date_of_accomplishment
        ) {
          let date =
            response?.data?.data[0]?.date_of_accomplishment?.split('/');

          if (date?.length > 1) {
            date = date[1] + '/' + date[0] + '/' + date[2];
          }
          setCompletionDate(date);
        }
        // console.log(response?.data, 'time');
        settimeProgressbar(response?.data);
      });
  }

  return (
    <div className={classNames.overallBar}>
      <div className={classNames.headDiv}>
        <div>
          <div className={classNames.title}>Time Progress</div>
          <div className={classNames.description}>
            Representing the percentage of the total PlanB time
          </div>
        </div>
        <div className={classNames.completedOverall}>
          <div>
            {timeProgressbar?.NumberOfDaysElapsedPercentage
              ? timeProgressbar?.NumberOfDaysElapsedPercentage?.toFixed(2) + '%'
              : '0%'}
          </div>
          <div>Completed</div>
        </div>
      </div>
      <div className={classNames.barDiv}>
        <div className={classNames.wholeBar}>
          <div
            className={classNames.completedPerc}
            style={{
              width: timeProgressbar?.NumberOfDaysElapsedPercentage
                ? timeProgressbar?.NumberOfDaysElapsedPercentage + '%'
                : '',
            }}
          ></div>
        </div>
        <div className={classNames.contentDiv}>
          <div>
            {timeProgressbar?.data?.length > 0 &&
            timeProgressbar?.data[0]?.startingDate
              ? moment(timeProgressbar?.data[0]?.startingDate).format(
                  'MMM Do [20]YY'
                )
              : ''}
          </div>
          <div>
            <div>Completion</div>
            <div className={classNames.totalAmount}>
              {completionDate
                ? moment(completionDate).format('MMM Do [20]YY')
                : ''}
            </div>
          </div>
          {/* <div
            className={classNames.currentPerc}
            style={{
              left: timeProgressbar?.NumberOfDaysElapsedPercentage
                ? timeProgressbar?.NumberOfDaysElapsedPercentage - 3 + '%'
                : '0%',
            }}
          >
            <div>Today</div>
            <div>
              {blockData &&
              overallProgressBar?.data?.length > 0 &&
              overallProgressBar?.data[0]?.CurrencyForTheAmount
                ? blockData?.overallTotalBlockValue +
                  ' ' +
                  overallProgressBar?.data[0]?.CurrencyForTheAmount
                : blockData && overallProgressBar?.data?.length > 0
                ? blockData?.overallTotalBlockValue
                : ''}
            </div>
          </div> */}
        </div>
      </div>
      <div className={classNames.detailsDiv}>
        <div>
          <div>Total Duration:</div>
          <div>
            {timeProgressbar?.NumberOfDaysForAccomplishment
              ? timeProgressbar?.NumberOfDaysForAccomplishment
              : ''}
          </div>
        </div>
        <div>
          <div>Completed Days:</div>
          <div>
            {timeProgressbar?.NumberOfDaysElapsed
              ? timeProgressbar?.NumberOfDaysElapsed
              : ''}
          </div>
        </div>
        <div>
          <div>Remaining Days:</div>
          <div>
            {timeProgressbar?.RemainingDays
              ? timeProgressbar?.RemainingDays
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeBar;
