import Axios from 'axios';
import MailSlurp from 'mailslurp-client';
import React, { useContext, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { BankContext } from '../../context/Context';
import moment from 'moment';
import OpenMailView from './OpenMailView';

const API_KEY = process.env.REACT_APP_MAILSLURP_KEY;

function InboxSidebar() {
  const { username, name, profileImg } = useContext(BankContext);
  const [mailBoxId, setMailBoxId] = useState('');
  const [mailList, setMailList] = useState([]);
  const mailslurp = new MailSlurp({
    apiKey: API_KEY,
  });
  const inboxController = mailslurp.inboxController;
  const updateMailList = async (mailBoxId) => {
    const mails = await inboxController.getEmails(
      mailBoxId,
      10,
      0,
      undefined,
      undefined,
      undefined,
      'DESC'
    );
    setMailList(mails);
  };

  useEffect(() => {
    if (username) {
      Axios.get('https://api.mailslurp.com/inboxes/paginated', {
        params: { search: username },
        headers: {
          'x-api-key': API_KEY,
        },
      }).then(async ({ data }) => {
        let mailBoxId = '';
        const boxMailId = `${username}@blockcheck.io`;
        data.content.forEach((inbox) => {
          if (inbox.emailAddress === boxMailId) {
            mailBoxId = inbox.id;
          }
        });
        if (!mailBoxId) {
          const inbox = await inboxController.createInbox(
            '',
            boxMailId,
            new Date(0),
            false,
            name,
            ['assets.io']
          );
          mailBoxId = inbox.id;
        }
        setMailBoxId(mailBoxId);
        updateMailList(mailBoxId);
      });
    }
  }, [username]);

  const [openMailId, setopenMailId] = useState('');
  const [mailDetail, setMailDetail] = useState('');
  const openMail = async (openMailId) => {
    const mailDetail = await mailslurp.getEmail(openMailId);
    setMailDetail(mailDetail);
  };
  useEffect(() => {
    if (openMailId) openMail(openMailId);
  }, [openMailId]);

  return (
    <div className="inboxSidebar">
      <div className="profile">
        <img
          className="profilePic"
          src={
            profileImg || `https://ui-avatars.com/api/?name=${name || username}`
          }
          alt=""
        />
        <div className="nameEmail">
          <div className="name">{name || username}</div>
          <div className="email">{username}@blockcheck.io</div>
        </div>
      </div>
      {openMailId ? (
        <OpenMailView
          mailDetail={mailDetail}
          onClose={() => setopenMailId('')}
        />
      ) : (
        <Scrollbars className="mailList">
          {mailList.map((mail) => (
            <div
              key={mail.id}
              className={`mailItem ${mail.read}`}
              onClick={() => setopenMailId(mail.id)}
            >
              <img
                src={`https://ui-avatars.com/api/?name=${mail.from}`}
                alt=""
                className="profileImg"
              />
              <div className="texts">
                <div className="from">{mail.from}</div>
                <div className="subject">{mail.subject}</div>
                <div className="time">
                  {moment(mail.created).format(
                    'MMMM Do YYYY [at] h:mm:ss A zz'
                  )}
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      )}{' '}
    </div>
  );
}

export default InboxSidebar;
