import portfolioIcon from '../../static/images/sidebar-icons/portfolio.svg';
import vaultsIcon from '../../static/images/sidebar-icons/vaults.svg';
import advesior from '../../static/images/advesior.svg';

import reactimg from '../../static/images/sidebar-icons/react.svg';
import reactIcon from '../../static/images/sidebar-icons/reactNew.svg';
import marketsIcon from '../../static/images/sidebar-icons/marketsIcon.svg';
import affiliateIcon from '../../static/images/sidebar-icons/affiliateIcon.svg';
import taxesIcon from '../../static/images/sidebarIcons/taxes.svg';
import newTaxesIcon from '../../static/images/sidebarIcons/newTaxes.svg';
import supportIcon from '../../static/images/sidebar-icons/supportIcon.svg';
import sipIcon from '../../static/images/sidebar-icons/sip.svg';
import blocksIcon from '../../static/images/sidebar-icons/blocks.svg';
import planbIcon from '../../static/images/sidebar-icons/planB.svg';
import coachesIcon from '../../static/images/sidebar-icons/coaches.svg';
import streamIcon from '../../static/images/sidebar-icons/stream.svg';
import sharesToken from '../../static/images/login/sharestoken.svg';
import defiIcon from '../../static/images/sidebar-icons/defiIcon.svg';
import verseNewLogo from '../../static/images/sidebar-icons/verseNewLogo.svg';
import stakingIcon from '../../static/images/sidebar-icons/stakingIcon.svg';

import displayIcon from '../../static/images/settings/displayCurrencyIcon.svg';
import themeIcon from '../../static/images/settings/themeIcon.svg';
import securityIcon from '../../static/images/settings/securityIcon.svg';
import kycIcon from '../../static/images/settings/kycIcon.svg';
import bankingIcon from '../../static/images/settings/banking.svg';
import logoutIcon from '../../static/images/settings/logoutIcon.svg';

import qrAddress from '../../static/images/actionSidebar/qrAddress.svg';
import connectIcon from '../../static/images/vaultCpanelIcons/connect.svg';
import withdraw from '../../static/images/vaultCpanelIcons/withdraw.svg';
import arrowUp from '../../static/images/clipIcons/arrowUp.svg';
import arrowDown from '../../static/images/clipIcons/arrowDown.svg';

import supportNewIcon from '../../static/images/sidebar-icons/supportNew.svg';
import tellersIcon from '../../static/images/sidebar-icons/tellersIcon.svg';
import settingsIcon from '../../static/images/settings/settingsIcon.svg';

//air assests
import apwIcon from '../../static/images/airLogos/apw.svg';
import viralverseIcon from '../../static/images/airLogos/viralverse.svg';
import retiredIcon from '../../static/images/airLogos/retired.svg';
import tradeStreamIcon from '../../static/images/airLogos/tradeStream.svg';
import accountantsIcon from '../../static/images/airLogos/accountants.svg';
import signalsIcon from '../../static/images/airLogos/signals.svg';
import web3todayIcon from '../../static/images/airLogos/web3today.svg';
import investmentbotsIcon from '../../static/images/airLogos/investmentbots.svg';

import hoilday from '../../static/images/airLogos/hoilday.svg';
import mall from '../../static/images/airLogos/mall.svg';
import assets from '../../static/images/airLogos/assets.svg';
import x3money from '../../static/images/airLogos/x3money.svg';
import single from '../../static/images/airLogos/single.svg';

import rio from '../../static/images/airLogos/rio.svg';
import influencer from '../../static/images/airLogos/influencer.svg';
import air from '../../static/images/airLogos/air.svg';
import advisor from '../../assets/wealthbos.svg'
import valuts from '../../assets/images/valuts.svg'
import marketverse from '../../assets/images/marketverse.svg'

import hoildays from '../../assets/images/hoilday.svg'

export const SIDEBAR_CONTENTS = [
  {
    id: 1,
    name: 'PlanB',
    icon: planbIcon,
    subtitle: 'Your Planner',
  },
  {
    id: 2,
    name: 'Portfolio.ai',
    icon: portfolioIcon,
    subtitle: 'Your Networth',
  },
  // {
  //   id: 3,
  //   name: 'Blocks',
  //   icon: blocksIcon,
  //   subtitle: 'Your Tools',
  // },
  {
    id: 4,
    name: 'Vaults',
    icon: vaultsIcon,
    subtitle: 'Your Assets',
  },
  // {
  //   id: 5,
  //   name: 'Staking',
  //   icon: stakingIcon,
  // },
  {
    id: 6,
    name: 'Holidays',
    icon: verseNewLogo,
  },
  {
    id: 9,
    name: 'Advisors',
    icon: advesior,
    subtitle: 'Our Community',
  },
  // {
  //   id: 7,
  //   name: 'React',
  //   icon: reactIcon,
  //   subtitle: 'Our Community',
  // },
  {
    id: 8,
    name: 'Marketwatch',
    icon: verseNewLogo,
  },
  // {
  //   id: 4,
  //   name: 'Affiliate',
  //   icon: affiliateIcon,
  // },
  // {
  //   id: 5,
  //   name: 'Coaches',
  //   icon: coachesIcon,
  // },
  // {
  //   id: 6,
  //   name: 'Stream',
  //   icon: streamIcon,
  // },
];

// export const SIDEBAR_CONTENTS = [
//   {
//     id: 1,
//     name: 'BPlanner',
//     icon: retiredIcon,
//   },
//   {
//     id: 2,
//     name: 'Portfolio.ai',
//     icon: portfolioIcon,
//   },
//   {
//     id: 3,
//     name: 'Vaults',
//     icon: vaultsIcon,
//   },
//   {
//     id: 4,
//     name: 'Blocks',
//     icon: blocksIcon,
//   },
//   {
//     id: 5,
//     name: 'Affiliate',
//     icon: affiliateIcon,
//   },
//   {
//     id: 6,
//     name: 'Taxes',
//     icon: taxesIcon,
//   },
//   {
//     id: 7,
//     name: 'Support',
//     icon: supportIcon,
//   },
//   {
//     id: 8,
//     name: 'ShareToken',
//     icon: sharesToken,
//   },
// ];

export const SETTINGS_MENU = (
  onLogoutClick,
  onBankingClick,
  // onTaxesClick,
  onSupportClick,
  onShareTokenClick,
  onHolidaysClick,
  onMarketwatchclick,
  onvaultClick,
  onadvisor,
  onreact,
  // onKycClick,
  onTellersClick,
  onSettingsClick,
  onHoldClick,
  onStakingClick,
  onBlocksClick
) => [
    // {
    //   id: 1,
    //   icon: displayIcon,
    //   text: 'Display Currency',
    //   subText: 'Currently Indian Rupees',
    // },
    // {
    //   id: 2,
    //   icon: themeIcon,
    //   text: 'Theme',
    //   subText: 'Currently Light Mode',
    // },
    {
      id: 3,
      icon: settingsIcon,
      text: 'Settings',
      subText: 'Enhance Your Security',
      onClick: onSettingsClick,
    },
    // {
    //   id: 3,
    //   icon: securityIcon,
    //   text: 'Security',
    //   subText: 'Enhance Your Security',
    // },
    // {
    //   id: 4,
    //   icon: kycIcon,
    //   text: 'KYC',
    //   subText: 'Currently Not Verified',
    //   onClick: onKycClick,
    // },
    {
      id: 5,
      icon: bankingIcon,
      text: 'Banking',
      subText: 'Attach Your Bank Accounts',
      onClick: onBankingClick,
    },


    {
      id: 29,
      text: 'Holidays',
      icon: hoildays,
      onClick: onHolidaysClick,
    },
    {
      id: 23,
      text: 'Marketwatch',
      icon: marketverse,
      onClick: onMarketwatchclick,
    },
    {
      id: 88,
      text: 'Vault',
      icon: valuts,
      onClick: onvaultClick,
    },
    {
      id: 66,
      text: 'WealthBox',
      icon: advisor,
      subText: 'For advisors only',
      onClick: onadvisor,
    },

    {
      id: 77,
      text: 'react',
      icon: reactimg,
      onClick: onreact,
    },

    // {
    //   id: 7,
    //   text: 'Support',
    //   icon: supportNewIcon,
    //   subText: 'Need Help? Click Here',
    //   onClick: onSupportClick,
    // },
    // {
    //   id: 8,
    //   text: 'ShareToken',
    //   icon: sharesToken,
    //   subText: 'Want To Own Retired? Click Here',
    //   onClick: onShareTokenClick,
    // },
    // {
    //   id: 9,
    //   icon: tellersIcon,
    //   text: 'Tellers',
    //   subText: 'A Revolution In Remittance',
    //   onClick: onTellersClick,
    // },
    {
      id: 10,
      text: 'Staking',
      icon: stakingIcon,
      onClick: onStakingClick,
    },
    {
      id: 11,
      text: 'Blocks',
      icon: blocksIcon,
      subtitle: 'Your Tools',
      onClick: onBlocksClick,
    },
    {
      id: 12,
      icon: supportIcon,
      text: 'OnHold',
      subText: 'Help Your customers',
      onClick: onHoldClick,
    },
    {
      id: 13,
      icon: logoutIcon,
      text: 'Logout',
      subText: 'Hope To See You Again Soon',
      onClick: onLogoutClick,
    },
  ];

export const COIN_ACTIONS = (coinName, setCoinAction, isMarketsPage) => {
  const showBuyAndSellOnly = [
    {
      id: 5,
      icon: arrowUp,
      text: 'Buy',
      subText: `Buy ${coinName} With Another Currency`,
      action: () => setCoinAction(['Buy']),
    },
    {
      id: 6,
      icon: arrowDown,
      text: 'Sell',
      subText: `Buy ${coinName} For Another Currency`,
      action: () => setCoinAction(['Sell']),
    },
  ];

  const enableActionsByCondition = isMarketsPage
    ? showBuyAndSellOnly
    : [
      {
        id: 1,
        icon: qrAddress,
        text: 'Add',
        subText: `Add ${coinName} From External Wallet`,
        action: () => setCoinAction(['Add']),
      },
      {
        id: 2,
        icon: vaultsIcon,
        text: 'Transfer',
        subText: `Transfer ${coinName} Between Your Vaults`,
        action: () => setCoinAction(['Transfer']),
      },
      {
        id: 3,
        icon: connectIcon,
        text: 'Connect',
        subText: `Send ${coinName} To Your Friends`,
      },
      {
        id: 4,
        icon: withdraw,
        text: 'Withdraw',
        subText: `Send ${coinName} To An External Wallet`,
        action: () => setCoinAction(['Withdraw']),
      },
      // ...showBuyAndSellOnly,
    ];
  return enableActionsByCondition;
};

export const AirMenu = [
  {
    id: 1,
    icon: apwIcon,
    text: 'AiProWallet',
    subText: 'Wallet For AI Enabled Investments',
    onClick: 'https://aiprowallet.com/',
  },
  {
    id: 2,
    icon: viralverseIcon,
    text: 'ViralVerse',
    subText: 'Build Your Affiliate Marketing Business',
    onClick: 'https://verse.viral.group/',
  },
  {
    id: 3,
    icon: retiredIcon,
    text: 'Retired',
    subText: 'AI Enabled Retirement Planner',
    onClick: 'https://retired.app/',
  },
  {
    id: 5,
    icon: single,
    text: 'Signals',
    subText: 'Learn From The Best Traders',
    onClick: 'https://signals.app/',
  },

  {
    id: 6,
    icon: accountantsIcon,
    text: 'Accountants',
    subText: 'Streamline Your Accounting',
    onClick: 'https://accountants.io/',
  },
  {
    id: 4,
    icon: tradeStreamIcon,
    text: 'TradeStream',
    subText: 'Launch Your Own Tokenized Fund',
    onClick: 'https://trade.stream/',
  },

  {
    id: 7,
    icon: web3todayIcon,
    text: 'Web3Today',
    subText: 'Crypto, AI, Blockchain News Platform',
    onClick: 'https://web3today.io/',
  },
  {
    id: 8,
    icon: x3money,
    text: 'X3 Money',
    subText: 'The easiest way acquire and spend crypto',
    onClick: 'https://x3.money/',
  },
  {
    id: 8,
    icon: assets,
    text: 'Assets',
    subText: 'Fractionalizing asset backed loans',
    onClick: 'https://assets.io/',
  },
  {
    id: 8,
    icon: mall,
    text: 'Malls',
    subText: 'Token-gated commerce',
    onClick: '',
  },

  {
    id: 8,
    icon: investmentbotsIcon,
    text: 'InvestmentBots',
    subText: 'Create Your Own Trading Bot',
    onClick: 'https://investmentbots.com/',
  },
  {
    id: 8,
    icon: hoilday,
    text: 'Holidays',
    subText: 'Go on a holiday every month',
    onClick: 'https://holidays.app/',
  },
];

export const AirToken = [
  {
    id: 1,
    icon: air,
    text: 'AIR-E',
    subText: 'Become an owner of the #AiRevolution',
    onClick: 'https://aiprowallet.com/',
  },
  {
    id: 2,
    icon: influencer,
    text: 'InfluenceCoin',
    subText: 'Monetize Your Influence',
    onClick: 'influencecoin.com',
  },
  {
    id: 2,
    icon: rio,
    text: 'ROICoin',
    subText: 'Supercharge Your Investments With AI',
    onClick: 'https://roicoin.com/',
  },
];
