import React from 'react';
import useWindowDimensions from '../utils/WindowSize';
import NewMobileLayout from './MobilePages/NewMobileLayout';
import Layout from './Layout';

function MainLayout({
  children,
  active,
  className,
  setMenuSelected,
  menuSelected,
  hideFooter,
  footerMain,
  mobileFooter,
}) {
  const { width, height } = useWindowDimensions();
  return (
    <>
      <Layout
        children={children}
        active={active}
        className={className}
        hideFooter={hideFooter}
      />
    </>
  );
}

export default MainLayout;
