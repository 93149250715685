import React from 'react';
import classNames from './reactcontent.module.scss';
import blocksFullLogo from '../../static/images/fullLogos/blocks.svg';
import QuestionsDiv from './Functions/QuestionsDiv';
import { QuestionData } from './Static/data';
import { useState } from 'react';

const ReactContent = () => {
  const [toggleSelected, settoggleSelected] = useState(null);

  const toggleSelectedFunc = (i) => {
    if (toggleSelected == i) {
      return settoggleSelected(null);
    }

    settoggleSelected(i);
  };

  return (
    <div className={classNames.reactContentContainer}>
      <div className={classNames.logoContainer}>
        <img src={blocksFullLogo} alt="blocksFullLogo" />
      </div>
      <div className={classNames.contentContainer}>
        <div>
          A retirement block is a unit that makes up a Retirement SIP. When
          creating a new Retirement SIP, you can purchase as many Retirement
          Blocks as you want. Each Retirement Block is valued at 600 USD and
          earns 2% monthly interest. You will be making payments of 100 USD each
          month for six months to pay off the Block. You will receive 12 USD per
          month, and at the end of your Block term, you will have saved 600 USDT
          and earned 72 USDT resulting in a final Block value of 672 USDT.
        </div>
        {QuestionData.map((eachdata, i) => {
          return (
            <QuestionsDiv
              {...eachdata}
              index={i}
              toggleSelected={toggleSelected}
              toggleSelectedFunc={toggleSelectedFunc}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ReactContent;
