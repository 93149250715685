import React, { useContext, useEffect } from 'react';
import classNames from './Rsip.module.scss';
import { useState } from 'react';
import moment from 'moment';
import { useCoinContextData } from '../../context/CoinContext';

//imports
import bitcoinIcon from '../../static/images/icons/bitcoin.svg';
import tetherIcon from '../../static/images/icons/tether.svg';
import { BankContext } from '../../context/Context';
import { useAppContextDetails } from '../../context/AppContext';
import VaultsContentLoading from '../Vaults/components/VaultsContentLoading';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { VaultPageContext } from '../../context/VaultPageContext';

const Rsip = ({ searchedValue }) => {
  const { email } = useContext(BankContext);
  const { coinType } = useCoinContextData();
  const { balanceToggle } = useAppContextDetails();
  const [vaultsContent, setVaultsContent] = useState([]);
  const [vaultsLoading, setVaultsLoading] = useState(false);

  useEffect(() => {
    setVaultsContent([]);
    axios
      .get(
        `https://comms.globalxchange.io/retired/block/user/get/all/contract?email=${email}`
      )
      .then((response) => {
        let res = response?.data?.data;
        if (res?.length > 0) {
          console.log(res, 'getStakingEarniRSIPVaults ');
          setVaultsContent(res);
          setVaultsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error?.message, 'getStakingEarningVaults error');
        setVaultsLoading(false);
      });
    // getRSIPData(email, setVaultsContent, setVaultsLoading);
  }, [coinType, balanceToggle]);

  const filteredData = (item) =>
    item
      ? Object?.values(item)
          ?.join(' ')
          ?.toLowerCase()
          ?.includes(searchedValue.toLowerCase())
      : [];

  return (
    <div className={classNames.vaultsMainRSIP}>
      <div className={classNames.headTab}>
        <div>Asset</div>
        <div>Duration</div>
        <div>Value Of RSIP</div>
        <div>Total Saved</div>
        <div>Earnings Till Date</div>
      </div>
      <div className={classNames.bodyContainer}>
        {vaultsLoading || vaultsContent?.length < 1
          ? Array.from({ length: 4 }).map((_, index) => {
              return <VaultsContentLoading key={'vaults loading ' + index} />;
            })
          : vaultsContent
              //   ?.filter((coinItem) => filteredData(coinItem))
              ?.map((eachItem, index) => {
                return (
                  <TrackerDetailsWithdraw
                    key={'vaults content ' + index}
                    // {...eachItem}
                    {...eachItem}
                  />
                );
              })}
      </div>
    </div>
  );
};

export default Rsip;

export const TrackerDetailsWithdraw = ({
  coin,
  createdAt,
  period,
  total_block_value,
  next_premium_date,
  interestEarned,
  current_retirement_block_value,
  identifier,
}) => {
  const history = useHistory();
  const [openedWithdrawDiv, setOpenedWithdrawDiv] = useState(false);
  const { coinType, setCoinType, setSelectedCoin } = useCoinContextData();
  const { appCode } = useAppContextDetails();
  const { setBlockContractSelected } = useContext(VaultPageContext);

  console.log(
    coin,
    createdAt,
    period,
    total_block_value,
    next_premium_date,
    interestEarned,
    current_retirement_block_value,
    'each value'
  );

  return (
    <div
      className={`${classNames.trackerDetailsWithdraw} ${
        openedWithdrawDiv ? classNames.trackerDetailsWithdrawSelected : ''
      }`}
      onClick={() => {
        setOpenedWithdrawDiv((prev) => !prev);
      }}
    >
      <div>
        <div className={classNames.eachSubDiv}>
          <div>
            <div className={classNames.heading}>
              {/* <img src={tetherIcon} alt="tetherIcon" /> */}
              {coin ? coin : ''}
            </div>
            <div className={classNames.date}>
              Initiated on &nbsp;
              {moment(createdAt).format('MMM Do YYYY, h:mm:ss A')} IST
            </div>
          </div>
        </div>
        <div className={classNames.eachSubDiv}>
          <div>
            <div className={classNames.heading}>
              {period ? period : ''}&nbsp;Months
            </div>
            <div className={classNames.date}>
              Next Payment Due On&nbsp;
              {moment(next_premium_date).format('MMM Do YYYY, h:mm:ss A')} IST
            </div>
          </div>
        </div>
        <div className={classNames.eachSubDiv}>
          <div>
            <div className={classNames.heading}>
              {total_block_value ? total_block_value?.toFixed(2) : ''}
            </div>
          </div>
        </div>
        <div className={classNames.eachSubDiv}>
          <div>
            <div className={classNames.heading}>
              {interestEarned ? interestEarned?.toFixed(2) : ''}
            </div>
          </div>
        </div>
        <div className={classNames.eachSubDiv}>
          <div>
            <div className={classNames.heading}>
              {current_retirement_block_value
                ? current_retirement_block_value?.toFixed(2)
                : ''}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames.initialisedBtns}
        style={{ display: openedWithdrawDiv ? '' : 'none' }}
      >
        <div
          className={classNames.cancelBtn}
          onClick={() => {
            setSelectedCoin(coin);
            setBlockContractSelected(identifier);
            history.push(`vault/${coinType}/${appCode}/${coin}`);
          }}
        >
          Earnings Transactions
        </div>
        <div
          className={classNames.cancelBtn}
          style={{ pointerEvents: 'none', opacity: '0.5' }}
        >
          Contribution Transactions
        </div>
        <div
          className={classNames.cancelBtn}
          style={{ pointerEvents: 'none', opacity: '0.5' }}
        >
          Actions
        </div>
      </div>
    </div>
  );
};

export function getRSIPData(email, setfunc, setloading) {
  setloading(true);

  axios
    .get(
      `https://comms.globalxchange.io/retired/block/user/get/all/contract?email=${email}`
    )
    .then((response) => {
      let res = response?.data?.data;
      if (res?.length > 0) {
        console.log(res, 'getStakingEarniRSIPVaults ');
        setfunc(res);
        setloading(false);
      }
    })
    .catch((error) => {
      console.log(error?.message, 'getStakingEarningVaults error');
      setloading(false);
    });
}
