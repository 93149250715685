import React, { useContext, useState } from 'react';
import classNames from './tradeStreamPage.module.scss';
import Layout from '../../Layout/Layout';
import retired from '../../static/images/sidebarFullLogos/tradeStream.svg';
import goLeftCircleWhite from '../../static/images/goLeftCircleWhite.svg';
import { Link } from 'react-router-dom';

function TradeStreamPage() {
  return (
    <Layout
      active={`tradestream`}
      className={classNames.tradeStreamPage}
      hideFooter
    >
      <div className={classNames.content}>
        <img src={retired} alt="" className={classNames.logo} />
      </div>
      <div className={classNames.footer}>
        Trade.Stream Is Coming To Retired.com
        <Link to="classrooms">
          <img src={goLeftCircleWhite} alt="" className={classNames.btnGo} />
        </Link>
      </div>
    </Layout>
  );
}

export default TradeStreamPage;
