import React, { useContext, useEffect } from 'react';
import classNames from './react.module.scss';
import Layout from '../../Layout/Layout';
import planBIcon from '../../static/images/sidebar-icons/planB.svg';
import useWindowDimensions from '../../utils/WindowSize';
import NewMobileLayout from '../../Layout/MobilePages/NewMobileLayout';
import { BankContext } from '../../context/Context';
import { Redirect } from 'react-router-dom';
import ProgressPage from '../../components/ProgressPage';
import { useMarketContext } from '../../context/MarketContext';
import { PlanB } from '../../components/CollapsedSidebar';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAppContextDetails } from '../../context/AppContext';
import { getAppByCode } from '../../queryHooks';
import { useState } from 'react';
import { LoadingAnimation } from '../../components/LoadingAnimation';
// import EditSidebar from './Components/EditSidebar';

function ReactPage() {
  const { width, height } = useWindowDimensions();
  const { email, iceSidebarOpen, sidebarCollapse, selectedTopItem } =
    useContext(BankContext);
  const {
    planBOpened,
    setPlanBOpened,
    buyBlocks,
    setBuyBlocksprofileUpdateSidebar,
    profileUpdateSidebar,
    setProfileUpdateSidebar,
    globalappIcon,
    iconLoading,
    setIconLoading,
  } = useMarketContext();

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {width > 768 || width > height ? (
        <Layout active="react" className={classNames.bplannerPage} hideFooter>
          {/* <ProgressPage /> */}
          {/* {planBOpened ? <PlanB /> : <ProgressPage />} */}
          {/* <EditSidebar /> */}

          {/* {iconLoading ? (
            <div className="loadingIcon">
              <LoadingAnimation icon={globalappIcon} width={200} />
            </div>
          ) : (
            ''
          )} */}
        </Layout>
      ) : (
        <NewMobileLayout />
      )}
    </>
  );
}

export default ReactPage;
