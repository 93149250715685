import React, { useState, useEffect } from 'react';
import classNames from './commonComponents.module.scss';
import { scrapeMetadata } from './scrapMetaData';

function MetaDataComponent() {
  const [metaData, setMetaData] = useState({});

  useEffect(() => {
    async function metaDataGet() {
      const metadata = await scrapeMetadata('https://www.example.com');
      console.log(metadata);
    }
    metaDataGet();
  }, []);

  return (
    <>
      {metaData.title && <h1>{metaData?.title}</h1>}
      {metaData.description && <p>{metaData?.description}</p>}
    </>
  );
}

export default MetaDataComponent;
