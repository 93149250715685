import React, { createContext, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ASSET_CLASSES } from '../config/constants';
import {
  useShareTokensVaultsList,
  useUserBondsList,
  useUserBondsTxns,
  useUserMoneMarketsList,
  useUserMoneyMarketsTxns,
  useUserVaults,
  useVaultTxns,
} from '../queryHooks';
import { useAppContextDetails } from './AppContext';
import { useCoinContextData } from './CoinContext';
import { BankContext } from './Context';
import axios from 'axios';

export const VaultPageContext = createContext();
function VaultPageContextProvider({ children }) {
  const { email } = useContext(BankContext);
  const { appCode, appCurrencyName } = useAppContextDetails();
  const [appSelected, setAppSelected] = useState();
  const [vaultSelected, setVaultSelected] = useState();
  const [assetClass, setAssetClass] = useState(ASSET_CLASSES[0]);
  const [cpanelOpen, setCpanelOpen] = useState(false);
  const { assetClassParam } = useParams();
  const { updateVaultData, trackerType } = useCoinContextData();
  const [blockContractSelected, setBlockContractSelected] = useState('');

  //stacking rewards (bonds)
  const [bondsPagination, setBondsPagination] = useState(0);
  const [bondsTxnsPerPage, setBondsTxnsPerPage] = useState(25);

  //tracker data
  const [trackerData, settrackerData] = useState('');
  const [trackerDataLoading, settrackerDataLoading] = useState(false);
  const [trackerDataDeposit, settrackerDataDeposit] = useState('');
  const [assetDropdown, setassetDropdown] = useState(false);
  const [statusDropdown, setstatusDropdown] = useState(false);
  const [assetDropdownItems, setassetDropdownItems] = useState('');
  const [assetDropdownSelected, setassetDropdownSelected] = useState('');
  const [statusDropdownSelected, setstatusDropdownSelected] = useState('');
  const [trackerImageOpen, settrackerImageOpen] = useState('');
  const [trackingImage, setTrackingImage] = useState('');
  const [trackingImageResponse, setTrackingImageResponse] = useState('');
  const [uploadedImageTracker, setuploadedImageTracker] = useState('');
  const [uploadedDescTracker, setuploadedDescTracker] = useState('');
  const [transactionDetailSelected, setTransactionDetailSelected] =
    useState('');
  const [transactionId, settransactionId] = useState('');
  const [transactionDetail, setTransactionDetail] = useState('');
  const [transactionDetailSteps, setTransactionDetailSteps] = useState('');
  const [transactionDetailStepsLoading, setTransactionDetailStepsLoading] =
    useState(false);
  const [imagesBtnLoading, setimagesBtnLoading] = useState(false);

  const [allDirection, setAllDirection] = useState('All Directions');
  const [allTypes, setAllTypes] = useState('All Types');

  //reset pagination vaults
  useEffect(() => {
    setBondsPagination(0);
    setBondsTxnsPerPage(25);
  }, [assetClass]);

  useEffect(() => {
    settrackerData('');
    setstatusDropdown(false);
    setassetDropdown(false);
    // console.log(trackerType, 'trackerType vault context useffect');
    if (trackerType == 'withdraw') {
      getTracketData(
        `https://comms.globalxchange.io/coin/vault/service/path/withdraw/txn/get?app_code=${appCode}&email=${email}`
      );
    } else if (assetDropdownSelected && statusDropdownSelected) {
      getTracketData(
        `https://comms.globalxchange.io/coin/vault/service/path/deposit/txn/get?email=${email}&status=${statusDropdownSelected}&app_code=${appCode}&coin=${assetDropdownSelected}`
      );
    } else if (assetDropdownSelected) {
      getTracketData(
        `https://comms.globalxchange.io/coin/vault/service/path/deposit/txn/get?email=${email}&app_code=${appCode}&coin=${assetDropdownSelected}`
      );
    } else if (statusDropdownSelected) {
      getTracketData(
        `https://comms.globalxchange.io/coin/vault/service/path/deposit/txn/get?email=${email}&status=${statusDropdownSelected}&app_code=${appCode}`
      );
    } else {
      getTracketData(
        `https://comms.globalxchange.io/coin/vault/service/path/deposit/txn/get?email=${email}&app_code=${appCode}`
      );
    }

    assetDropdownDeposit();
  }, [statusDropdownSelected, assetDropdownSelected, trackerType]);

  function getTracketData(API) {
    settrackerDataLoading(true);
    // console.log(trackerType, 'trackerType vault context');
    axios
      .get(API)
      .then((response) => {
        console.log(response, 'tracker data response');
        settrackerData(response?.data);
        if (response?.data?.perAppCode?.length > 0) {
          settrackerDataDeposit(response?.data?.perAppCode[0]?.count);
        }
        settrackerDataLoading(false);
      })
      .catch((error) => {
        console.log('error on tracker data', error?.message);
        settrackerDataLoading(false);
      });
  }

  function assetDropdownDeposit() {
    // console.log('assetDropdownDeposit');
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/path/deposit/txn/get?email=${email}&app_code=${appCode}`
      )
      .then((response) => {
        // console.log('assetDropdownDeposit', response?.data);
        setassetDropdownItems(response?.data);
      })
      .catch((error) => {
        console.log('error on assetDropdownDeposit', error?.message);
      });
  }

  useEffect(() => {
    const assetCls = ASSET_CLASSES.filter(
      (assetClass) => assetClass.name === assetClassParam
    )[0];
    if (assetCls) setAssetClass(assetCls);
  }, [assetClassParam]);

  const {
    data: vaultsListFxCrypto = [],
    isLoading: vaultsListFxCryptoLoading,
    refetch: refetchVaultsListFxCrypto,
  } = useUserVaults(
    email,
    assetClass?.name,
    appSelected?.app_code,
    undefined,
    undefined,
    appCurrencyName
  );

  const {
    data: txnListFxCrypto,
    isLoading: txnListLoadingFxCrypto = true,
    refetch: refetchTxnListFxCrypto,
  } = useVaultTxns(
    email,
    appSelected?.app_code,
    vaultSelected?.coinSymbol,
    undefined,
    updateVaultData,
    appCurrencyName
  );

  const {
    data: vaultsListBonds,
    isLoading: vaultsListBondsLoading,
    refetch: refetchVaultsListBonds,
  } = useUserBondsList(email, appCurrencyName);

  const {
    data: txnListBonds,
    isLoading: txnListLoadingBonds = true,
    refetch: refetchTxnListBonds,
  } = useUserBondsTxns(email, vaultSelected?.coinSymbol);

  const {
    data: vaultsListMM,
    isLoading: vaultsListLoadingMM,
    refetch: refetchVaultsListMM,
  } = useUserMoneMarketsList(email, appSelected?.app_code, appCurrencyName);

  const {
    data: txnListMM,
    isLoading: txnListLoadingMM = true,
    refetch: refetchtxnListMM,
  } = useUserMoneyMarketsTxns(
    email,
    vaultSelected?.coinSymbol,
    appSelected?.app_code
  );

  const {
    data: vaultsListShares,
    isLoading: vaultsListLoadingShares,
    refetch: refetchVaultsListShares,
  } = useShareTokensVaultsList(email, appSelected?.app_code);

  const {
    data: txnListShares,
    isLoading: txnListLoadingShares = true,
    refetch: refetchtxnListShares,
  } = useVaultTxns(
    email,
    vaultSelected?.app_code,
    vaultSelected?.tokens &&
      vaultSelected?.tokens[0]?.token_profile_data?.coinSymbol,
    vaultSelected?.profile_id,
    updateVaultData,
    appCurrencyName
  );

  function refetchData() {
    refetchVaultsListFxCrypto();
    refetchTxnListFxCrypto();
    refetchVaultsListBonds();
    refetchTxnListBonds();
    refetchVaultsListMM();
    refetchtxnListMM();
    refetchVaultsListShares();
    refetchtxnListShares();
  }
  return (
    <VaultPageContext.Provider
      value={{
        assetClass,
        setAssetClass,
        appSelected,
        setAppSelected,
        vaultSelected,
        setVaultSelected,
        cpanelOpen,
        setCpanelOpen,
        vaultsListFxCrypto,
        vaultsListFxCryptoLoading,
        vaultsListBonds,
        vaultsListBondsLoading,
        vaultsListMM,
        vaultsListLoadingMM,
        txnListBonds,
        txnListLoadingBonds,
        txnListMM,
        txnListLoadingMM,
        txnListFxCrypto,
        txnListLoadingFxCrypto,
        vaultsListShares: vaultsListShares?.vaultsData,
        vaultsListLoadingShares,
        txnListShares,
        txnListLoadingShares,
        refetchData,
        blockContractSelected,
        setBlockContractSelected,
        trackerData,
        trackerDataLoading,
        trackerDataDeposit,
        statusDropdown,
        setstatusDropdown,
        assetDropdown,
        setassetDropdown,
        assetDropdownItems,
        setassetDropdownItems,
        assetDropdownSelected,
        setassetDropdownSelected,
        statusDropdownSelected,
        setstatusDropdownSelected,
        trackerImageOpen,
        settrackerImageOpen,
        trackingImage,
        setTrackingImage,
        trackingImageResponse,
        setTrackingImageResponse,
        uploadedImageTracker,
        setuploadedImageTracker,
        uploadedDescTracker,
        setuploadedDescTracker,
        transactionDetailSelected,
        setTransactionDetailSelected,
        transactionId,
        settransactionId,
        transactionDetail,
        setTransactionDetail,
        transactionDetailSteps,
        setTransactionDetailSteps,
        transactionDetailStepsLoading,
        setTransactionDetailStepsLoading,
        imagesBtnLoading,
        setimagesBtnLoading,

        // bankAccountIdValue,
        // setBankAccountIdValue,
        // bankInfoValues,
        // setbankInfoValues,

        //staking rewards (bonds)
        bondsPagination,
        setBondsPagination,
        bondsTxnsPerPage,
        setBondsTxnsPerPage,
        allDirection,
        setAllDirection,
        allTypes,
        setAllTypes,
      }}
    >
      {children}
    </VaultPageContext.Provider>
  );
}

export default VaultPageContextProvider;
