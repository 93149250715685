import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { addActionForex } from './api';
import './addForex.scss';
import { ForexAddSkeletons } from './ForexAddSkeletons';
import { EachCardForList } from '../../EachCardForList';

const AddForexAction = ({ ques }) => {
  const {
    selectedCoin,
    setSelectedCoin,
    addApiValue,
    setAddApiValue,
    countryApiValue,
    setCountryApiValue,
    eachCardShowValue,
    setEachCardShowValue,
    toCurrencyApiValue,
    setToCurrencyApiValue,
    payMethodApiValue,
    setPayMethodApiValue,
    fundingCurrency,
    payMethod,
    otcDesk,
    currentStep,
    newWholeValue,
    setNewWholeValue,
    fromCurrencyData,
    setFromCurrencyData,
    setFundingCurrency,
    setCurrentStep,
    setPayMethod,
    setOtcDesk,
    setAddApiValueCoin,
    setBankerId,
    setSelectedTab,
    setOtcApiValue,
    setEnterAmount,
  } = useCoinContextData();

  const [isLoading, setisLoading] = useState(false);

  const debounceFunc = (value) => {
    if (eachCardShowValue == 'step1' && fromCurrencyData.step1) {
      let searchValues = fromCurrencyData.step1.filter((data) => {
        if (data.coin_metadata) {
          let searchdata = data.coin_metadata.coinName.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step1: searchValues,
      });
    } else if (eachCardShowValue == 'step2' && fromCurrencyData.step2) {
      let searchValues = fromCurrencyData.step2.filter((data) => {
        if (data.metadata) {
          let searchdata = data.metadata.name.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step2: searchValues,
      });
    } else if (eachCardShowValue == 'step3' && fromCurrencyData.step3) {
      let searchValues = fromCurrencyData.step3.filter((data) => {
        if (data.metadata) {
          let searchdata = data.metadata.name.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step3: searchValues,
      });
    } else if (eachCardShowValue == 'step4' && fromCurrencyData.step4) {
      let searchValues = fromCurrencyData.step4.filter((data) => {
        if (data) {
          let searchdata = data.displayName.toLowerCase();
          return searchdata.includes(value.toLowerCase());
        }
      });
      setNewWholeValue({
        ...newWholeValue,
        step4: searchValues,
      });
    }
  };

  useEffect(() => {
    setisLoading(true);
    let obj = {
      select_type: 'fund',
      to_currency: selectedCoin?.coinSymbol,
    };

    addActionForex(obj).then((response) => {
      let result = response.data.pathData.from_currency;
      setNewWholeValue({ ...newWholeValue, step1: result });
      setFromCurrencyData({ ...newWholeValue, step1: result });
      setisLoading(false);
    });
  }, []);

  useEffect(() => {
    setisLoading(true);

    if (payMethodApiValue) {
      obj = {
        select_type: 'fund',
        to_currency: selectedCoin.coinSymbol,
        from_currency: addApiValue.value,
        country: countryApiValue.value,
        paymentMethod: payMethodApiValue.value,
      };
      getResponseFunc(obj);
    } else if (countryApiValue) {
      obj = {
        select_type: 'fund',
        to_currency: selectedCoin.coinSymbol,
        from_currency: addApiValue.value,
        country: countryApiValue.value,
      };
      getResponseFunc(obj);
    } else if (addApiValue) {
      obj = {
        select_type: 'fund',
        to_currency: selectedCoin.coinSymbol,
        from_currency: addApiValue.value,
      };
      getResponseFunc(obj);
    } else if (selectedCoin) {
      var obj = {
        select_type: 'fund',
        to_currency: selectedCoin.coinSymbol,
      };
      getResponseFunc(obj);
    }

    function getResponseFunc() {
      addActionForex(obj).then((response) => {
        if (!fundingCurrency) {
          var result = response.data.pathData.from_currency;
          setEachCardShowValue('step1');
        } else if (!payMethod) {
          var result = response.data.pathData.country;
          setEachCardShowValue('step2');
        } else if (!otcDesk) {
          var result = response.data.pathData.paymentMethod;
          setEachCardShowValue('step3');
        } else {
          var result = response.data.pathData.banker;
          setEachCardShowValue('step4');
        }
        if (currentStep == 'step1') {
          setNewWholeValue({ ...newWholeValue, step1: result });
          setFromCurrencyData({ ...newWholeValue, step1: result });
        } else if (currentStep == 'step2') {
          setNewWholeValue({ ...newWholeValue, step2: result });
          setFromCurrencyData({ ...newWholeValue, step2: result });
        } else if (currentStep == 'step3') {
          setNewWholeValue({ ...newWholeValue, step3: result });
          setFromCurrencyData({ ...newWholeValue, step3: result });
        } else {
          setNewWholeValue({ ...newWholeValue, step4: result });
          setFromCurrencyData({ ...newWholeValue, step4: result });
        }
        setisLoading(false);
      });
    }
  }, [
    addApiValue,
    setAddApiValue,
    toCurrencyApiValue,
    setToCurrencyApiValue,
    countryApiValue,
    setCountryApiValue,
    payMethodApiValue,
    setPayMethodApiValue,
    selectedCoin,
    setSelectedCoin,
  ]);

  const nextPage = (value) => {
    if (!fundingCurrency) {
      setAddApiValue(value);
      setFundingCurrency(true);
      setCurrentStep('step2');
      setSelectedTab('fundingCurrency');
    } else if (!payMethod) {
      setCountryApiValue(value);
      setCurrentStep('step3');
      setPayMethod(true);
      setSelectedTab('payMethod');
    } else if (!otcDesk) {
      setPayMethodApiValue(value);
      setCurrentStep('step4');
      setOtcDesk(true);
      setSelectedTab('otcDesk');
    } else {
      setOtcApiValue(value);
      setCurrentStep('step5');
      setEnterAmount(true);
      setSelectedTab('enterAmount');
    }
  };

  const nextPageFunc = (value) => {
    nextPage(value);
  };

  return (
    <div className="menu2" style={{ overflowY: 'hidden' }}>
      <p className="sending-txt">
        {ques ? ques : 'Which Currency Are You Sending?'}
      </p>
      <div className="userAppscard" onMouseDown={(e) => e.stopPropagation()}>
        <input
          className="appsearch"
          key="appsearch"
          type="search"
          placeholder={
            currentStep === 'step1'
              ? 'Search Assets...'
              : currentStep === 'step2'
              ? 'Search Countries...'
              : currentStep === 'step3'
              ? 'Search Methods...'
              : 'Search OTCDesks...'
          }
          onChange={(e) => debounceFunc(e.target.value)}
        />
        <>
          <div className="apps-card">
            {!isLoading ? (
              currentStep == 'step1' && fromCurrencyData.step1.length > 0 ? (
                newWholeValue.step1.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        setAddApiValueCoin(
                          singledata?.coin_metadata?.coinImage
                        );
                        nextPageFunc({
                          value: singledata.coin_metadata.coinSymbol,
                        });
                      }}
                      icon={singledata.coin_metadata.coinImage}
                      label={singledata?.coin_metadata?.coinName}
                    />
                  );
                })
              ) : currentStep == 'step2' && newWholeValue.step2.length > 0 ? (
                newWholeValue.step2.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        nextPageFunc({
                          value: singledata?.metadata?.name,
                        });
                      }}
                      icon={singledata?.metadata?.image}
                      label={singledata?.metadata?.name}
                    />
                  );
                })
              ) : currentStep == 'step3' && newWholeValue.step3.length > 0 ? (
                newWholeValue.step3.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        nextPageFunc({
                          value: singledata?.metadata?.code,
                        });
                      }}
                      icon={singledata?.metadata?.icon}
                      label={singledata?.metadata?.name}
                    />
                  );
                })
              ) : newWholeValue.step4.length > 0 ? (
                newWholeValue.step4.map((singledata, i) => {
                  return (
                    <EachCardForList
                      key={i}
                      onClick={() => {
                        setBankerId(singledata?._id);
                        nextPageFunc({
                          value: singledata?.displayName,
                        });
                      }}
                      icon={singledata?.icons?.image1}
                      label={singledata?.displayName}
                    />
                  );
                })
              ) : (
                <div></div>
              )
            ) : (
              <ForexAddSkeletons />
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default AddForexAction;
