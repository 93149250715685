export async function scrapeMetadata(url) {
  const response = await fetch(url);
  const html = await response.text();

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const head = doc.head;

  const metadata = {};
  const metaElements = head.getElementsByTagName('meta');
  for (let i = 0; i < metaElements.length; i++) {
    const element = metaElements[i];
    const name = element.getAttribute('name');
    const content = element.getAttribute('content');
    if (name && content) {
      metadata[name] = content;
    }
  }

  return metadata;
}
