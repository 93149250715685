import React from 'react';
import classNames from './education.module.scss';
import searchIcon from '../../static/images/search.svg';
import testProfile from './shoruptest.svg';
import { useContext } from 'react';
import { PortfolioContext } from '../../context/PortfolioContext';
import { videosInPlaylist } from '../../APIS/educationapi';
import { useEffect } from 'react';
import axios from 'axios';

const EducationContainer = () => {
  const {
    educationLeftSelected,
    seteducationLeftSelected,
    selectedPlaylistData,
    setSelectedPlaylistData,
    allPlaylistAvailable,
    setallPlaylistAvailable,
  } = useContext(PortfolioContext);

  return (
    <div className={classNames.educationContainer}>
      <div className={classNames.leftContainer}>
        <div>
          <div>Courses</div>
          <img src={searchIcon} alt="searchIcon" />
        </div>
        <div className={classNames.selectionBtns}>
          <div
            className={` ${
              educationLeftSelected == 'Youtube'
                ? classNames.selectedBtn
                : classNames.notselectedBtn
            }`}
            onClick={(event) =>
              seteducationLeftSelected(event.target.innerText)
            }
          >
            Youtube
          </div>
          <div
            className={` ${
              educationLeftSelected == 'Exclusive'
                ? classNames.selectedBtn
                : classNames.notselectedBtn
            }`}
            onClick={(event) =>
              seteducationLeftSelected(event.target.innerText)
            }
          >
            Exclusive
          </div>
        </div>
        <div>
          {allPlaylistAvailable &&
            allPlaylistAvailable.map((eachPlaylist) => {
              return <VideoContainer eachPlaylist={eachPlaylist} />;
            })}
        </div>
      </div>
      <div className={classNames.rightContainer}>
        <ExclusiveDiv selectedPlaylistData={selectedPlaylistData} />
      </div>
    </div>
  );
};

export default EducationContainer;

const VideoContainer = ({ eachPlaylist }) => {
  const {
    setSelectedPlaylistData,
    educationSelectedPlaylist,
    seteducationSelectedPlaylist,
    youtubeApiPlaylistData,
    setYoutubeApiPlaylistData,
  } = useContext(PortfolioContext);

  const videosInPlaylistChanging = async (playListId) => {
    let Youtube_API = 'AIzaSyBUKr_jOjgyi8yQ6ioy6-wFuJBxC3EbEvU';
    try {
      const data = await axios.get(
        `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&playlistId=${playListId}&key=${Youtube_API}`
      );
      setSelectedPlaylistData(data.data);
    } catch (error) {
      console.error(error, 'videosInPlaylist');
    }
  };

  return (
    <div
      className={classNames.eachVideoContainer}
      onClick={() => videosInPlaylistChanging(eachPlaylist?.id)}
    >
      <div>
        <img
          src={eachPlaylist?.snippet?.thumbnails?.default?.url}
          alt="TempImage"
        />
      </div>
      <div className={classNames.contentDiv}>
        <div>{eachPlaylist?.snippet?.localized?.title}</div>
        <div>{eachPlaylist?.snippet?.localized?.description}</div>
      </div>
    </div>
  );
};

const ExclusiveDiv = ({ selectedPlaylistData }) => {
  return (
    <div className={classNames.exclusiveDiv}>
      {selectedPlaylistData?.items?.length > 0 &&
        selectedPlaylistData.items.map((eachdata, i) => {
          return <GeneralCard eachdata={eachdata} i={i} />;
        })}
      <div className={classNames.ceoCard}>
        <div>
          <img src={testProfile} alt="testProfile" />
        </div>
        <div className={classNames.detailsCard}>
          <div>Shorupan Pirakaspathy</div>
          <div>CEO of Retired App</div>
        </div>
      </div>
    </div>
  );
};

const GeneralCard = ({ eachdata, i }) => {
  return (
    <div className={classNames.generalCard}>
      <iframe
        src={`https://www.youtube.com/embed/${eachdata?.snippet?.resourceId?.videoId}`}
        title={eachdata?.snippet?.title}
        width="100%"
        height="30%"
        key={'iframes' + i}
      ></iframe>
      <div className={classNames.contentDiv}>
        <div>{eachdata?.snippet?.title}</div>
        <div>
          {eachdata?.snippet?.description?.split('»')?.length > 0
            ? eachdata?.snippet?.description?.split('»')[0]
            : eachdata?.snippet?.description}
        </div>
      </div>
    </div>
  );
};
