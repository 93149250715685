import axios from 'axios';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BankContext } from '../../context/Context';
import { useMarketContext } from '../../context/MarketContext';
// import { EachBlockContent } from '../ListComponent/ListData';
import classNames from './rsip.module.scss';
import './rsip.scss';

const RSIPComponent = () => {
  let { retiredid } = useParams();
  const { connectSipSidebar, setConnectSipSibebar } = useMarketContext();
  const [tempRsipData, setTempRsipData] = useState([]);
  const [connectedRetirementWhole, setconnectedRetirementWhole] = useState('');

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/retired/contract/get/all/blockcontracts?retirement_plan_id=${retiredid}`
      )
      .then((response) => {
        // console.log(response?.data, 'responseeee sip');
        setTempRsipData(response?.data?.data);
        setconnectedRetirementWhole(response?.data);
      });
  }, [connectSipSidebar]);

  return (
    <div className={classNames.rsipContainer}>
      <div className={classNames.heading}>
        <div>Connected Retirement SIP’s</div>
        <div
          className={classNames.connectSipBtn}
          onClick={() => setConnectSipSibebar(true)}
        >
          Connect SIP
        </div>
      </div>
      <div className={classNames.overviewDashboard}>
        <div>
          <div className={classNames.dashBoardValues}>
            {connectedRetirementWhole?.overallPremiumAmount
              ? connectedRetirementWhole?.overallPremiumAmount
              : '0'}
          </div>
          <div className={classNames.dashBoardHeading}>Monthly Commitment</div>
        </div>
        <div>
          <div className={classNames.dashBoardValues}>
            {connectedRetirementWhole?.overallRetirementBlockValue
              ? connectedRetirementWhole?.overallRetirementBlockValue
              : '0'}
          </div>
          <div className={classNames.dashBoardHeading}>
            Total Value Of RSIP’s
          </div>
        </div>
        <div>
          <div className={classNames.dashBoardValues}>
            {connectedRetirementWhole?.overallCurrentRetirementBlockValue
              ? connectedRetirementWhole?.overallCurrentRetirementBlockValue
              : '0'}
          </div>
          <div className={classNames.dashBoardHeading}>
            Current Value Of RSIP’s
          </div>
        </div>
        <div>
          <div className={classNames.dashBoardValues}>
            {connectedRetirementWhole?.overallInterestEarned
              ? connectedRetirementWhole?.overallInterestEarned?.toFixed(2)
              : '0'}
          </div>
          <div className={classNames.dashBoardHeading}>
            Total Interest Earned
          </div>
        </div>
      </div>
      <div className={classNames.contentContainer}>
        {tempRsipData?.length > 0
          ? tempRsipData?.map((eachData, i) => {
              return (
                <EachBlockValut eachData={eachData} key={'temprsipdata' + i} />
              );
            })
          : ''}
      </div>
    </div>
  );
};

export default RSIPComponent;

const EachBlockValut = ({ eachData }) => {
  // console.log(eachData, 'eachData');
  let mappableData = eachData?.block_contract_details;
  if (mappableData?.length > 0) {
    mappableData = { ...eachData, ...mappableData[0] };
    // if (mappableData?.ContractData?.length > 0) {
    //   mappableData = mappableData?.ContractData[0];
    // }
  }
  // console.log('mappableData', mappableData);
  return (
    <div className="blocksVaultEach">
      <EachBlockContent eachData={mappableData} />
      <div className="blocksVaultbtns">
        <div className="blocksVaultbtn">Transactions</div>
        <div className="blocksVaultbtn">Actions</div>
      </div>
    </div>
  );
};

export const EachBlockContent = ({ eachData }) => {
  return (
    <>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Asset</div>
        <div className="blocksVaultValue">{eachData?.coin}</div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Number Of Blocks</div>
        <div className="blocksVaultValue">{eachData?.no_of_blocks}</div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Monthly Interest Rate</div>
        <div className="blocksVaultValue">
          {eachData?.month_interest_rate?.toFixed(2) + '%'}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Monthly Earnigns</div>
        <div className="blocksVaultValue">
          {eachData?.monthly_Earning?.toFixed(2) + ' ' + eachData?.coin}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Length</div>
        <div className="blocksVaultValue">{eachData?.period + ' Months'}</div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Monthly Savings</div>
        <div className="blocksVaultValue">
          {eachData?.total_premium_amount?.toFixed(2) + ' ' + eachData?.coin}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Term Interest Rate</div>
        <div className="blocksVaultValue">
          {eachData?.term_interest_rate?.toFixed(2) + '%'}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Term Earnings</div>
        <div className="blocksVaultValue">
          {eachData?.term_Earnings?.toFixed(2) + ' ' + eachData?.coin}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Months Remaining</div>
        <div className="blocksVaultValue">
          {eachData?.no_remaining_premium + ' Months'}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Principle Block Value</div>
        <div className="blocksVaultValue">
          {eachData?.retirement_block_value?.toFixed(2) + ' ' + eachData?.coin}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Total Block Value</div>
        <div className="blocksVaultValue">
          {eachData?.total_block_value?.toFixed(2) + ' ' + eachData?.coin}
        </div>
      </div>
      <div className="blocksVaultEachContent">
        <div className="blocksVaultTitle">Live Block Value</div>
        <div className="blocksVaultValue">
          {eachData?.current_retirement_block_value?.toFixed(2) +
            ' ' +
            eachData?.coin}
        </div>
      </div>
    </>
  );
};
