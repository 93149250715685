import React from 'react';
import './pageHeader.scss';
import { useAppContextDetails } from '../../../context/AppContext';

import { useAppDetail, useShareTokensDetail } from '../../../queryHooks';

import menuIcon from '../../../static/images/mobile/menu.svg';

const PageHeader = ({ setOpenSidebar }) => {
  const { appFullLogo, appName } = useAppContextDetails();

  return (
    <div className="pageHeader">
      <img
        src={menuIcon}
        alt=""
        style={{ height: '100%', width: '25px' }}
        onClick={(e) => setOpenSidebar(true)}
      />
      <div style={{ width: '70%' }}>
        <img
          src={appFullLogo}
          alt=""
          style={{ width: '100%', height: '100%' }}
        />
      </div>

      <div>&nbsp;</div>
    </div>
  );
};

export default PageHeader;
