import React, { useEffect } from 'react';
import { useState } from 'react';
const SwitcherSearch = ({ path, listData, setSelectedData, setOpenSearch }) => {
  const [allData, setAllData] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    // if (path === 'MarketCrypto' || path === 'MarketMoneyMarket') {
    //   setAllData(listData);
    // } else if (path === 'MarketMoneyMarket') {
    //   setAllData(listData);
    // } else if (path === 'MarketMarketsVerse') {
    //   setAllData(listData);
    // } else if (path === 'VaultCrypto') {
    //   setAllData(listData);
    // }
    setAllData(listData);
  }, []);

  const handleSelection = (item) => {
    if (path === 'MarketCrypto') {
      setSelectedData(item.name);
      setOpenSearch(false);
    } else if (path === 'MarketMoneyMarket') {
      setSelectedData(item.name.toLowerCase());
      setOpenSearch(false);
    } else if (
      path === 'MarketMarketsVerse' ||
      path === 'VaultCrypto' ||
      path === 'VaultForex' ||
      path === 'VaultMoneyMarket' ||
      path === 'VaultBonds'
    ) {
      setSelectedData(item);
      setOpenSearch(false);
    } else {
      setSelectedData(item);
      setOpenSearch(false);
    }
  };

  return (
    <>
      <div
        style={{
          borderBottom: '1px solid #e5e5e5',
          borderTop: '1px solid #e5e5e5',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '70%' }}>
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="search"
            style={{
              height: '70px',
              width: '100%',
              border: 'none',
              paddingLeft: '22px',
            }}
          />
        </div>
        <div
          onClick={(e) => setOpenSearch(false)}
          style={{
            background: 'black',
            color: 'white',
            fontWeight: 'bold',
            width: '30%',
            height: '70px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Go Back
        </div>
      </div>
      <div style={{ height: '80vh', overflowY: 'scroll' }}>
        {allData
          ?.filter(
            (o) =>
              o?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
              o?.coinName?.toLowerCase()?.includes(query?.toLowerCase())
          )
          ?.map((item) => {
            return (
              <div
                onClick={(e) => handleSelection(item)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px 22px',
                  height: '90px',
                  borderBottom: '1px solid #e7e7e7',
                }}
              >
                <img
                  src={item?.icon || item?.coinImage}
                  alt=""
                  style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                />
                <div
                  style={{
                    paddingLeft: '10px',
                    fontSize: '20px',
                    fontWeight: '600',
                  }}
                >
                  {item?.name || item?.coinName}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SwitcherSearch;
