import React from 'react';
import './enterAmountForTransfer.scss';
import { useCoinContextData } from '../../../context/CoinContext';

const EnterAmountForTransfer = () => {
  const {
    selectedCoin,
    addApiValue,
    addForexCurrencyValue,
    setAddForexCurrencyValue,
  } = useCoinContextData();

  return (
    <div className="addForexAmountWrapper" style={{ marginTop: '4rem' }}>
      <p className="header">How Much {addApiValue.value} Are You Sending?</p>
      <div className="add-amount-box">
        <div className="div-amount-input">
          <input
            className="add-input"
            placeholder="0.00"
            key="add"
            id="add"
            type="number"
            value={addForexCurrencyValue}
            onChange={(e) => {
              const { value } = e.target;
              setAddForexCurrencyValue(value);
            }}
          />
          <div className="add-from-coin" key="add">
            <img className="coinimgsmall" src={selectedCoin.coinImage} alt="" />
            <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterAmountForTransfer;
