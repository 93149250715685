import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../../config';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useGetPathId, useLoadQuote } from './api';

export const ConfirmQuote = () => {
  const {
    selectedCoin,
    addApiValue,
    addForexCurrencyValue,
    payMethodApiValue,
    bankerId,
    confirmQuote,
    setConfirmQuote,
    addApiValueCoin,
    setIsAddingForexLoading,
  } = useCoinContextData();
  const { appCode, appLogo } = useAppContextDetails();
  const { email } = useContext(BankContext);
  const [pathIdParams, setPathIdParams] = useState({});

  const userToken = Cookies.get(APP_USER_TOKEN);

  const { data: pathIdResponse } = useGetPathId(pathIdParams, setConfirmQuote);
  const pathId = pathIdResponse?.data?.paths?.[0]?.path_id;

  const {
    mutate: getQuote,
    data: quoteResponse,
    isLoading: isQuoteLoading,
  } = useLoadQuote();
  const { data: userApps } = useUserApps(email);
  const selectedUserApp = userApps?.filter((e) => e.app_code === appCode);
  const userAppsProfileId = selectedUserApp?.[0]?.profile_id;
  const finalAmount = quoteResponse?.data?.finalToAmount;

  useEffect(() => {
    setPathIdParams({
      select_type: 'fund',
      to_currency: selectedCoin.coinSymbol,
      from_currency: addApiValue.value,
      paymentMethod: payMethodApiValue.value,
      banker: bankerId,
    });
  }, []);

  useEffect(() => {
    if (confirmQuote && pathId && userAppsProfileId) {
      getQuote({
        token: userToken,
        email,
        app_code: appCode,
        profile_id: userAppsProfileId,
        coin_purchased: selectedCoin.coinSymbol,
        purchased_from: addApiValue.value,
        from_amount: addForexCurrencyValue,
        stats: true,
        identifier: `Add ${addForexCurrencyValue} ${addApiValue.value} Via ${payMethodApiValue.value}`,
        path_id: pathId,
      });
    }
  }, [confirmQuote, pathId, userAppsProfileId]);

  const isLoading = isQuoteLoading && !finalAmount && finalAmount !== 0;

  useEffect(() => {
    setIsAddingForexLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <div
        className="addForexAmountWrapper"
        style={{ opacity: isLoading ? 0.25 : 1, marginTop: '4rem' }}
      >
        <div>
          <p className="header">Sending Via {payMethodApiValue.value}</p>
          <div className="add-amount-box">
            <div className="div-amount-input">
              <input
                className="add-input"
                placeholder="0.00"
                key="add"
                id="add"
                type="number"
                value={addForexCurrencyValue}
                disabled
              />
              <div className="add-from-coin" key="add">
                <img
                  className="coinimgsmall"
                  src={addApiValueCoin}
                  alt={addApiValue.value}
                />
                <p className="coinsymbolsmall">{addApiValue.value}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <p className="header">
            Credited To {selectedCoin.coinSymbol} Liquid Vault
          </p>
          <div className="add-amount-box">
            <div className="div-amount-input">
              <input
                className="add-input"
                placeholder="0.00"
                key="add"
                id="add"
                type="number"
                value={finalAmount}
                disabled
              />
              <div className="add-from-coin" key="add">
                <img
                  className="coinimgsmall"
                  src={selectedCoin.coinImage}
                  alt=""
                />
                <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
