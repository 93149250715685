import React, { Fragment, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../../../config';
import { useAppContextDetails } from '../../../context/AppContext';
import { useCoinContextData } from '../../../context/CoinContext';
import { BankContext } from '../../../context/Context';
import { useUserApps } from '../../../queryHooks';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useCoinData } from '../api';
import { useGetPathId, useInitiateAdd, useLoadPathDataForId } from './api';
import downArrow from '../../../static/images/clipIcons/downArrow.svg';
import upArrow from '../../../static/images/clipIcons/upArrow.svg';

export const AddForexSuccess = () => {
  const {
    selectedCoin,
    addApiValue,
    addForexCurrencyValue,
    payMethodApiValue,
    bankerId,
    setUpdateVaultData,
    setSelectedTab,
    setBankAccountId,
    bankAccountId,
    setIsAddingForexLoading,
  } = useCoinContextData();

  const { appCode, appLogo, appCurrencySymbol, appColorCode } =
    useAppContextDetails();
  const { email } = useContext(BankContext);
  const [pathIdParams, setPathIdParams] = useState({});
  const [pathIdLoaded, setPathIdLoaded] = useState(false);
  const [updateCoinValue, setUpdateCoinBalance] = useState(false);
  const [loadPathData, setLoadPathData] = useState(false);
  const [selectedPathData, setSelectedPathData] = useState();
  const [openDropDown, setOpenDropDown] = useState(false);

  const handleDropDown = (index) => {
    setSelectedPathData(index);
    setOpenDropDown(!openDropDown);
  };

  const { mutate: initiateAdd, isLoading: isAdding } = useInitiateAdd(
    setUpdateVaultData,
    setUpdateCoinBalance,
    setLoadPathData,
    bankAccountId !== ''
  );

  const userToken = Cookies.get(APP_USER_TOKEN);

  const { data: pathIdResponse } = useGetPathId(pathIdParams, setPathIdLoaded);
  const pathId = pathIdResponse?.data?.paths?.[0]?.path_id;
  const { data: userApps } = useUserApps(email);
  const selectedUserApp = userApps?.filter((e) => e.app_code === appCode);
  const userAppsProfileId = selectedUserApp?.[0]?.profile_id;

  const {
    mutate: getCoinData,
    data: coinResponse,
    isLoading: isCoinDataLoading,
  } = useCoinData();
  const coinBalance = coinResponse?.data?.coins_data?.[0]?.coinValue;

  useEffect(() => {
    setPathIdParams({
      select_type: 'fund',
      to_currency: selectedCoin.coinSymbol,
      from_currency: addApiValue.value,
      paymentMethod: payMethodApiValue.value,
      banker: bankerId,
    });
  }, []);

  useEffect(() => {
    if (pathIdLoaded && pathId && userAppsProfileId) {
      initiateAdd({
        token: userToken,
        email,
        app_code: appCode,
        profile_id: userAppsProfileId,
        coin_purchased: selectedCoin.coinSymbol,
        purchased_from: addApiValue.value,
        from_amount: addForexCurrencyValue,
        stats: false,
        identifier: `Add ${addForexCurrencyValue} ${addApiValue.value} Via ${payMethodApiValue.value}`,
        path_id: pathId,
      });
    }
  }, [pathIdLoaded, pathId, userAppsProfileId]);

  useEffect(() => {
    if (updateCoinValue) {
      getCoinData({
        app_code: appCode,
        email,
        displayCurrency: appCurrencySymbol,
        include_coins: [selectedCoin.coinSymbol],
      });
      setUpdateCoinBalance(false);
    }
  }, [updateCoinValue]);

  const { data: pathDataResponse, isLoading: loadingPathData } =
    useLoadPathDataForId(pathId, loadPathData);

  const totalStepsObject = pathDataResponse?.data?.paths?.[0]?.total_steps;
  const pathData =
    totalStepsObject && Object?.entries?.(totalStepsObject)?.map((e) => e[1]);

  const isLoading = isCoinDataLoading || isAdding || loadingPathData;

  useEffect(() => {
    setIsAddingForexLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <div
        className="addForexAmountWrapper"
        style={{ opacity: isLoading ? 0.25 : 1 }}
      >
        <p className="header congrats">
          Congratulations. You Have Successully Added {addForexCurrencyValue}{' '}
          {addApiValue.value} To Your Liquid {selectedCoin.coinSymbol}
          Vault.
        </p>

        <div className="mt-3">
          <p className="header">New {selectedCoin.coinSymbol} Balance</p>
        </div>
        <div className="add-amount-box">
          <div className="div-amount-input">
            <input
              className="add-input"
              placeholder="0.00"
              key="add"
              id="add"
              type="text"
              value={coinBalance}
            />
            <div className="add-from-coin" key="add">
              <img
                className="coinimgsmall"
                src={selectedCoin.coinImage}
                alt=""
              />
              <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
            </div>
          </div>
        </div>

        <>
          {pathData?.some((item) => item?.publicname) && (
            <div className="pathDataInfo">
              <div className="stepsInfo">
                Please Complete The Following Steps Within 24 Hours To Actualize
                The Deposit.
              </div>
              <div className="forex-sidebar-spacer mt-5 mb-5" />

              {pathData.map((data, i) => {
                return (
                  <Fragment key={i}>
                    <div className="stepDetail">
                      <div className="publicName">{data?.publicname}</div>
                      <div className="arrowIconWrapper">
                        <img
                          src={
                            selectedPathData === i && openDropDown
                              ? upArrow
                              : downArrow
                          }
                          alt="arrow"
                          id="rotate"
                          onClick={() => handleDropDown(i)}
                        />
                      </div>
                    </div>
                    {openDropDown && selectedPathData === i && (
                      <>
                        <div className="description">{data?.description}</div>
                        {data?.bankaccountid && (
                          <div
                            className="pathDataButton"
                            style={{ background: appColorCode }}
                            onClick={() => {
                              setBankAccountId(data?.bankaccountid);
                              setSelectedTab('accountDetails');
                            }}
                          >
                            <div className="clickable-div">
                              <b>See Payment Details</b>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <div className="forex-sidebar-spacer mt-5 mb-5" />
                  </Fragment>
                );
              })}
            </div>
          )}
        </>
      </div>

      {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
