import axios from 'axios';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { planBContext } from '../../../context/PlanBContext';
import { PlanTypeSelection } from '../../BuyBlocks/components/AllPlans';
import {
  DateSelectionDiv,
  ImageUploadDiv,
  InputDivs,
} from '../../BuyBlocks/components/CreatePlanB';
import classNames from './soloeditables.module.scss';

const EditSidebar = () => {
  const {
    editingConfigurationField,
    editingConfigurationInput,
    seteditingConfigurationInput,
  } = useContext(planBContext);


  return (
    <>
      {editingConfigurationField &&
        (editingConfigurationField?.type == 'input' ? (
          <InputDivs
            heading={editingConfigurationField?.title}
            placeholderText={editingConfigurationField?.placeholder}
            setFunc={seteditingConfigurationInput}
            funcValue={editingConfigurationInput}
            addCurrency={editingConfigurationField?.currency}
          />
        ) : editingConfigurationField?.type == 'search' ? (
          <SearchDiv
            heading={editingConfigurationField?.title}
            placeholderText={editingConfigurationField?.placeholder}
          />
        ) : editingConfigurationField?.type == 'date' ? (
          <DateSelectionDiv heading={editingConfigurationField?.title} />
        ) : editingConfigurationField?.type == 'image' ? (
          <ImageUploadDiv heading={editingConfigurationField?.title} />
        ) : (
          ''
        ))}
    </>
  );
};

export default EditSidebar;

const SearchDiv = ({ url, heading, placeholderText }) => {
  let [apiData, setApiData] = useState([]);
  let [filteredData, setfilteredData] = useState([]);

  const {
    creationLoading,
    editingConfigurationSearch,
    seteditingConfigurationSearch,
    planTypeCreate,
    setplanTypeCreate,
  } = useContext(planBContext);

  useEffect(() => {
    let url;
    if (heading.includes('Currency')) {
      url = 'https://comms.globalxchange.io/coin/vault/get/all/coins';
    } else {
      url = 'https://comms.globalxchange.io/retired/block/getallgoals';
    }
    axios.get(url).then((response) => {
      let res = response?.data;
      if (heading.includes('Currency')) {
        res = res?.coins;
      } else {
        res = res?.data;
      }
      setfilteredData(res);
      setApiData(res);
    });
  }, [creationLoading]);

  function filterItem(text) {
    let filterItem;
    if (heading.includes('Currency')) {
      filterItem = apiData?.filter((eachitem) => {
        return eachitem?.coinSymbol
          ?.toLowerCase()
          ?.includes(text?.toLowerCase());
      });
    } else {
      filterItem = apiData?.filter((eachitem) => {
        return eachitem?.name?.toLowerCase()?.includes(text?.toLowerCase());
      });
    }

    setfilteredData(filterItem);
  }

  return (
    <div className={classNames.searchEditContainer}>
      <div className={classNames.title}>{heading}</div>
      <div className={classNames.search}>
        <input
          type="text"
          placeholder={placeholderText}
          onChange={(event) => filterItem(event.target.value)}
        />
        <div
          className={classNames.addPlanType}
          style={{ display: heading.includes('Currency') ? '' : 'flex' }}
          onClick={() => setplanTypeCreate(true)}
        >
          +
        </div>
      </div>
      <div className={classNames.contentDiv}>
        {filteredData?.length > 0
          ? filteredData?.map((eachitem, i) => {
              return (
                <div
                  className={classNames.eachItemDiv}
                  onClick={() => {
                    if (heading.includes('Currency')) {
                      seteditingConfigurationSearch(eachitem?.coinSymbol);
                    } else {
                      seteditingConfigurationSearch(eachitem?.goal_id);
                    }
                  }}
                  key={'eachsearchitem' + i}
                >
                  <img
                    src={
                      eachitem?.coinImage
                        ? eachitem?.coinImage
                        : eachitem?.icon
                        ? eachitem?.icon
                        : ''
                    }
                    alt="mapimage"
                  />
                  <div>
                    {eachitem?.coinSymbol
                      ? eachitem?.coinSymbol
                      : eachitem?.name
                      ? eachitem?.name
                      : ''}
                  </div>
                  <div
                    className={classNames.selectedItem}
                    style={{
                      display:
                        editingConfigurationSearch == eachitem?.coinSymbol ||
                        editingConfigurationSearch == eachitem?.goal_id
                          ? 'flex'
                          : '',
                    }}
                  >
                    <svg
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.0684 1.88086L4.65164 7.29742C4.48914 7.45993 4.27582 7.54172 4.0625 7.54172C3.84918 7.54172 3.63586 7.45993 3.47336 7.29742L0.765076 4.58914C0.439148 4.26337 0.439148 3.73663 0.765076 3.41086C1.09085 3.08493 1.61743 3.08493 1.94336 3.41086L4.0625 5.53L8.89008 0.702576C9.21585 0.376648 9.74243 0.376648 10.0684 0.702576C10.3941 1.02835 10.3941 1.55493 10.0684 1.88086V1.88086Z" />
                    </svg>
                  </div>
                </div>
              );
            })
          : ''}
      </div>
    </div>
  );
};
