import React, { Fragment, useEffect, useState } from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import { useLoadBankAccountDetails } from './api';
import copy from '../../../static/images/sidebar-icons/copy.svg';
import { useAppContextDetails } from '../../../context/AppContext';
import { LoadingAnimation } from '../../LoadingAnimation';
import ToastMessage from '../../ToastMessage/ToastMessage';

export const BankDetails = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [toast, setToast] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const { bankAccountId } = useCoinContextData();
  const { appLogo } = useAppContextDetails();

  useEffect(() => {
    if (toast) {
      setTimeout(() => setToast(false), 1000);
    }
  }, [toast]);

  const { data: bankAccountResponse, isLoading } =
    useLoadBankAccountDetails(bankAccountId);
  const bankerData = bankAccountResponse?.data?.bankAccounts_info?.[0];
  const bankAccountInfo = bankerData?.bank_information;
  const arrayOfFields =
    bankAccountInfo &&
    Object?.entries?.(bankAccountInfo)?.map((info) => info[1]);

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value);
    setToast(true);
  };

  return (
    <>
      <div
        className="addForexAmountWrapper pb-3"
        style={{ opacity: isLoading ? 0.25 : 1 }}
      >
        {bankAccountInfo && (
          <Fragment>
            <div className="d-flex flex-column w-100 mb-1">
              <label className="header mb-0" htmlFor="accountName">
                Account Name
              </label>
              <div className="add-amount-box mt-0">
                <div className="div-amount-input">
                  <input
                    className="add-input"
                    id="accountName"
                    type="text"
                    value={bankerData?.account_name}
                  />
                  <img
                    className="clickable-div"
                    src={copy}
                    alt="copy"
                    width={20}
                    onClick={() => handleCopyClick(bankerData?.account_name)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column w-100 mb-1">
              <label className="header mb-0" htmlFor="paymentMethod">
                Payment Method
              </label>
              <div className="add-amount-box mt-0">
                <div className="div-amount-input">
                  <img
                    src={bankerData?.paymentMethod_data?.icon}
                    alt="icon"
                    width={30}
                    height={30}
                    style={{ marginTop: '5px' }}
                  />
                  <input
                    className="add-input"
                    id="paymentMethod"
                    type="text"
                    value={bankerData?.paymentMethod_data?.name}
                  />
                  <img
                    className="clickable-div"
                    src={copy}
                    alt="copy"
                    width={20}
                    onClick={() =>
                      handleCopyClick(bankerData?.paymentMethod_data?.name)
                    }
                  />
                </div>
              </div>
            </div>
            {arrayOfFields?.map((field, i) => {
              if (field?.valuetype === 'text') {
                return (
                  <div className="d-flex flex-column w-100 mb-1">
                    <label className="header mb-0" htmlFor={`field${i}`}>
                      {field?.name}
                    </label>
                    <div className="add-amount-box mt-0">
                      <div className="div-amount-input">
                        <input
                          className="add-input"
                          id={`field${i}`}
                          type="text"
                          value={field?.value}
                        />
                        <img
                          className="clickable-div"
                          src={copy}
                          alt="copy"
                          width={20}
                          onClick={() => handleCopyClick(field?.value)}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="d-flex flex-column w-100 mb-1">
                  <div
                    className="d-flex flex-row justify-content-between"
                    style={{ width: '90%' }}
                  >
                    <label className="header mb-0" htmlFor={`field${i}`}>
                      {field?.name}
                    </label>
                    <div
                      className="clickable-div expand"
                      onClick={() => {
                        setSelectedImage(i);
                        setShowImage(!showImage);
                      }}
                    >
                      {showImage ? 'Collapse' : 'Expand'}
                    </div>
                  </div>
                  <img
                    className={`pictureData ${
                      selectedImage === i && showImage
                    } mt-0`}
                    id={`field${i}`}
                    src={field?.value}
                    style={{ width: '90%' }}
                  />
                </div>
              );
            })}
          </Fragment>
        )}
        {toast && (
          <ToastMessage
            textOne="Copied the Value"
            onClose={() => setToast(false)}
          />
        )}
      </div>

      {isLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
