import axios from 'axios';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { BankContext } from '../../../context/Context';
import { useMarketContext } from '../../../context/MarketContext';
import {
  childCount,
  country_list,
  dobData,
} from '../../../static/data/constData';
import { getAllDaysInMonth } from '../../../utils/HelperFunc';
import classNames from './commonComponents.module.scss';
import * as jose from 'jose';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import selectDownArrow from '../../../static/images/selectDownArrow.svg';
import uploading from '../../../static/images/uploading.svg';
import { focusInput } from '../func/miniFunctions';

const EditSidebar = () => {
  // const [dateTemp, setDateTemp] = useState();
  // const [monthTemp, setMonthTemp] = useState();
  // const [yearTemp, setYearTemp] = useState();
  // const [firstName, setfirstName] = useState('');
  // const [lastName, setlastName] = useState('');
  // const [country, setcountry] = useState('');
  // const [address, setaddress] = useState('');
  // const [phone, setphone] = useState('');
  // const [childrens, setchildrens] = useState();
  // const [martialStatus, setmartialStatus] = useState('Single');
  const [countryOpen, setcountryOpen] = useState(false);
  // const [icon, setIcon] = useState('');
  const [customSelectedDrop, setCustomSelectedDrop] = useState('');

  const [imageUploading, setImageUploading] = useState(false);

  const [mapDates, setmapDates] = useState(dobData);
  const [mapChildCount, setmapChildCount] = useState(childCount);
  const [mapCountryCode, setmapCountryCode] = useState(country_list);
  const [selectedType, setSelectedType] = useState("")
  const [brokerageName, setBrokerageName] = useState("")
  const [arnNumber, setArnNumber] = useState("")

  const {
    genderSelected,
    setGenderSelected,
    profileUpdateSidebar,
    setProfileUpdateSidebar,
    planBProfile,
    setPlanBProfile,
    icon,
    setIcon,
    //temp
    dateTemp,
    setDateTemp,
    monthTemp,
    setMonthTemp,
    monthTempNum,
    setMonthTempNum,
    yearTemp,
    setYearTemp,
    firstName,
    setfirstName,
    lastName,
    setlastName,
    country,
    setcountry,
    address,
    setaddress,
    state,
    setstate,
    pincode,
    setpincode,
    occupation,
    setoccupation,
    annualIncome,
    setannualIncome,
    phone,
    setphone,
    childrens,
    setchildrens,
    martialStatus,
    setmartialStatus,
    countryCode,
    setCountryCode,
    iconLoading,
    setIconLoading,
  } = useMarketContext();

  const { email, token, planBAccountDetails, setPlanBAccountDetails } =
    useContext(BankContext);


  const purposeData = [
    { theText: "Manage my personal finances", type: "user" },
    { theText: "Manage my personal finances & earn money by helping others", type: "consultant" },
    { theText: "I am a registered financial advisor and I want to empower my clients", type: "advisor" }
  ]

  function createUser() {
    setIconLoading(true);
    if (!planBAccountDetails?.status && !localStorage.getItem('plaBAccount')) {
      let userProfile = {
        email: email,
        token: token,
        firstName: firstName,
        lastName: lastName,
        birthday:
          (monthTempNum ? monthTempNum : '01') +
          '/' +
          (dateTemp ? dateTemp : '01') +
          '/' +
          (yearTemp ? yearTemp : '2000'),
        profilePic: icon ? icon : 'test',
        countryName: country,
        countryFlag: '',
        state: state,
        address: address,
        pincode: pincode,
        gender: genderSelected,
        martialStatus: martialStatus,
        children: childrens ? childrens : '0',
        occupation: occupation,
        annualIncome: annualIncome,
        phoneNumber: '+' + countryCode + phone,
        userType: selectedType,
        brokerAgeName: selectedType == "advisor" ? brokerageName : "",
        PANnumber: selectedType == "advisor" ? arnNumber : ""
      };
      axios
        .post(
          'https://comms.globalxchange.io/retired/user/add/userprofile',
          userProfile
        )
        .then((response) => {
          // console.log(userProfile, 'creating');
          // console.log(response, 'created');
          setProfileUpdateSidebar(!profileUpdateSidebar);
          if (response.data.status) {
            localStorage.setItem('plaBAccount', response.data.status);
            setIconLoading(false);
            userCreatedNotify();
          } else {
            setIconLoading(false);
            userNotValid();
          }
        })
        .catch((error) => {
          console.log(error, 'error');
          setProfileUpdateSidebar(!profileUpdateSidebar);
          setIconLoading(false);
          networkErrorToast();
        });
      // setIconLoading(false);
      // console.log('create user');
    } else {
      let userProfile = {
        email: email,
        token: token,
        firstName: firstName,
        lastName: lastName,
        birthday:
          (monthTempNum ? monthTempNum : '01') +
          '/' +
          (dateTemp ? dateTemp : '01') +
          '/' +
          (yearTemp ? yearTemp : '2000'),
        profilePic: icon ? icon : 'test',
        countryName: country,
        countryFlag: '',
        state: state,
        address: address,
        pincode: pincode,
        gender: genderSelected,
        martialStatus: martialStatus,
        children: childrens ? childrens : '0',
        occupation: occupation,
        annualIncome: annualIncome,
        phoneNumber: '+' + countryCode + phone,
        userType: selectedType,
        brokerAgeName: brokerageName,
        PANnumber: arnNumber
      };

      // console.log(userProfile, 'updating');
      axios
        .put(
          `https://comms.globalxchange.io/retired/user/update/userprofile?email=${email}`,
          userProfile
        )
        .then((response) => {
          // console.log(response, 'updated', userProfile);
          setProfileUpdateSidebar(!profileUpdateSidebar);
          if (response.data.status) {
            setIconLoading(false);
            userUpdatedNotify();
            // console.log('updated cuser');
          } else {
            setIconLoading(false);
            userNotValid();
          }
        })
        .catch((error) => {
          console.log(error, 'error');
          setProfileUpdateSidebar(!profileUpdateSidebar);
          setIconLoading(false);
          networkErrorToast();
        });
      // setIconLoading(false);
    }
    setIconLoading(false);
    // window.location.reload(true);
  }

  const selectedGender = {
    backgroundColor: '#44C2F4',
    borderRadius: '10px',
    color: 'white',
  };

  const notSelectedGender = {
    color: '#7d7d7d80',
  };

  const applyStyle = (condition) => {
    if (condition) {
      return selectedGender;
    } else {
      return notSelectedGender;
    }
  };

  const secret = 'uyrw7826^&(896GYUFWE&*#GBjkbuaf'; // secret not to be disclosed anywhere.
  const emailDev = 'pavithran@inr.group'; // email of the developer.

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: 'HS512' })
        .setIssuer('gxjwtenchs512')
        .setExpirationTime('10m')
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, 'kjbedkjwebdw');
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf('.')
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append('files', file);
    const path_inside_brain = 'root/';

    const jwts = await signJwt(fileName, emailDev, secret);
    // console.log(jwts, 'lkjkswedcf');
    let { data } = await axios.post(
      // `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`
      'https://publications.apimachine.com/article/upload',
      formData,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    if (data?.length > 0) {
      console.log(data, 'data.payload.url');
      setImage(data[0]?.urlName);
    }

    setLoading(false);
  };

  function userCreatedNotify() {
    toast('Account created successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
  function userNotValid() {
    toast('Failed, enter all the required fields..', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
  function networkErrorToast() {
    toast('Network error, try after sometime...', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
  function userUpdatedNotify() {
    toast('Profile Updated...', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }

  function searchDates(text, searchArray) {
    let res;
    if (searchArray?.length == 12) {
      res = searchArray?.filter((month) => {
        let ress = month.month.toLowerCase();
        return ress.includes(text.toLowerCase());
      });
      setmapDates({ ...mapDates, month: res });
    } else if (searchArray?.length == 31) {
      res = searchArray?.filter((value) => {
        let ress = value.toLowerCase();
        return ress.includes(text.toLowerCase());
      });
      setmapDates({ ...mapDates, dates: res });
    } else {
      res = searchArray?.filter((year) => {
        let ress = year.toString();
        return ress.includes(text.toString());
      });
      setmapDates({ ...mapDates, years: res });
    }
  }

  function filterData(text, searchArray, setState) {
    let res = searchArray?.filter((year) => {
      let ress = year.toString();
      return ress.includes(text.toString());
    });
    setState(res);
  }

  function filterInsideData(text, searchArray, setState) {
    let searchText = text.toString().replace('+', '');

    let res = searchArray?.filter((numberr) => {
      let ress = numberr.code.toString();
      return ress.includes(searchText);
    });
    setState(res);
  }

  // useEffect(() => {
  //   if (customSelectedDrop == 'month') {
  //     let select = document.getElementById('inputMonth');
  //     focusInput();
  //   } else if (customSelectedDrop === 'date') {
  //     let select = document.getElementById('inputDate');
  //     focusInput();
  //   } else if (customSelectedDrop === 'year') {
  //     let select = document.getElementById('inputYear');
  //     focusInput();
  //   }
  // }, [customSelectedDrop]);

  useEffect(() => {
    setmapCountryCode(country_list);
    setmapChildCount(childCount);
    setmapDates(dobData);
  }, [customSelectedDrop]);

  useEffect(() => {
    let selectedmonth;
    let selectedMonth = dobData?.month?.filter((eachMonth) => {
      selectedmonth = monthTemp?.toLowerCase();
      return eachMonth?.month?.toLowerCase()?.includes(selectedmonth);
    });

    setMonthTempNum(selectedMonth[0]?.num);
  }, [monthTemp]);

  return (
    <div
      className={classNames.editComponent}
      style={{
        right: profileUpdateSidebar ? 0 : '',
        opacity: iconLoading ? '0.5' : '',
        pointerEvents: iconLoading ? 'none' : '',
      }}
    >
      {countryOpen ? (
        <div
          className={classNames.closeBtn}
          onClick={() => {
            setCustomSelectedDrop('');
            setcountryOpen(!countryOpen);
          }}
        >
          <svg
            width="18"
            height="15"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.129187 6.34062L5.07279 11.8403C5.16426 11.9423 5.29254 12 5.4269 12L7.55123 12C7.97366 12 8.19304 11.4793 7.90534 11.1595L5.25914 8.21753H12.2409C12.6599 8.21753 13 7.86593 13 7.4327L13 4.56691C13 4.13368 12.6599 3.78208 12.2409 3.78208L5.25952 3.78208L7.90572 0.84055C8.19342 0.520733 7.97404 0 7.55161 0L5.42728 0C5.29292 0 5.16464 0.0576849 5.07316 0.159713L0.129567 5.65978C-0.0431261 5.85167 -0.043128 6.14873 0.129187 6.34062Z"
              fill="white"
            />
          </svg>
        </div>
      ) : (
        <div
          className={classNames.closeBtn}
          onClick={() => {
            setCustomSelectedDrop('');
            setProfileUpdateSidebar(!profileUpdateSidebar);
          }}
        >
          x
        </div>
      )}
      <div className={classNames.editProfile}>
        {countryOpen ? (
          <CountryDiv
            setcountry={setcountry}
            setcountryOpen={setcountryOpen}
            countryOpen={countryOpen}
            onClick={() => setCustomSelectedDrop('')}
          />
        ) : (
          <>
            <div
              className={classNames.changeProfileDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <div>
                <img
                  src={
                    icon
                      ? icon
                      : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/87aa85500485decb550e820f4ffdba63'
                  }
                  alt="profilepic"
                  className={classNames.profileImg}
                />
                {imageUploading ? (
                  <div className={classNames.overlayDiv}>
                    <img
                      src={uploading}
                      alt="uploading"
                      className={classNames.uploadingimg}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
              <label htmlFor="profileUpdateImg">
                <input
                  className={classNames.uploadNewPic}
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon, setImageUploading);
                  }}
                  accept="image/*"
                  id="profileUpdateImg"
                />
                <div>
                  {imageUploading ? 'Uploading...' : 'Upload Profile Pic'}
                </div>
              </label>
            </div>
            <div
              className={classNames.nameDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label>Enter Name</label>
              <input
                type="text"
                placeholder="First Name"
                onChange={(event) => {
                  setfirstName(event.target.value);
                }}
                value={firstName}
              />
              <input
                type="text"
                placeholder="Last Name"
                onChange={(event) => {
                  setlastName(event.target.value);
                }}
                value={lastName}
              />
            </div>
            <div
              className={classNames.genderDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label>Select Gender</label>
              <div>
                <div
                  onClick={(event) => setGenderSelected(event.target.innerText)}
                  style={applyStyle(genderSelected == 'Male')}
                >
                  <label htmlFor="male">Male</label>
                </div>
                <div
                  onClick={(event) => setGenderSelected(event.target.innerText)}
                  style={applyStyle(genderSelected == 'Female')}
                >
                  <label htmlFor="female">Female</label>
                </div>
              </div>
            </div>
            <div className={classNames.dobDiv}>
              <label onClick={() => setCustomSelectedDrop('')}>
                Enter Birthday
              </label>
              <div>
                <div
                // onClick={() => {
                //   if (customSelectedDrop === 'month') {
                //     return setCustomSelectedDrop('');
                //   }
                //   setCustomSelectedDrop('month');
                // }}
                >
                  <div>
                    <div
                      style={{
                        color: monthTemp ? '#7d7d7d' : '#7d7d7d80',
                        borderRadius:
                          customSelectedDrop === 'month' ? '10px 10px 0 0' : '',
                      }}
                    >
                      <div
                        onClick={(event) => {
                          if (customSelectedDrop === 'month') {
                            return setCustomSelectedDrop('');
                          }
                          setCustomSelectedDrop('month');
                        }}
                      >
                        {monthTemp ? monthTemp : 'Month'}
                      </div>
                      <input
                        type="text"
                        className="dropDownInput"
                        style={{
                          display:
                            customSelectedDrop === 'month' ? 'block' : '',
                        }}
                        id="inputMonth"
                        placeholder="Search..."
                        onChange={(event) => {
                          searchDates(event.target.value, dobData.month);
                        }}
                      />
                    </div>
                    <div
                      className={classNames.dropDownList}
                      style={{
                        maxHeight:
                          customSelectedDrop === 'month' ? '650px' : '',
                        border: customSelectedDrop === 'month' ? '' : '0',
                        overflowY:
                          customSelectedDrop === 'month' ? 'scroll' : '',
                      }}
                    >
                      {mapDates &&
                        mapDates.month.map((eachMonth, i) => {
                          return (
                            <div
                              // value={eachMonth.num}
                              style={{ padding: '1rem 1.5rem' }}
                              onClick={(event) => {
                                setMonthTemp(eachMonth.month);
                                setCustomSelectedDrop('');
                              }}
                              key={'eachMonth' + i}
                            >
                              {eachMonth.month}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <img
                    src={selectDownArrow}
                    alt="selectDownArrow"
                    style={{
                      rotate: customSelectedDrop === 'month' ? '180deg' : '',
                      top: customSelectedDrop === 'month' ? '30%' : '',
                    }}
                    onClick={() => setCustomSelectedDrop('')}
                  />
                </div>

                <div
                // onClick={() => {
                //   if (customSelectedDrop === 'date') {
                //     return setCustomSelectedDrop('');
                //   }
                //   setCustomSelectedDrop('date');
                // }}
                >
                  <div>
                    <div
                      style={{
                        color: dateTemp ? '#7d7d7d' : '#7d7d7d80',
                        borderRadius:
                          customSelectedDrop === 'date' ? '10px 10px 0 0' : '',
                      }}
                    >
                      <div
                        onClick={(event) => {
                          if (customSelectedDrop === 'date') {
                            return setCustomSelectedDrop('');
                          }
                          setCustomSelectedDrop('date');
                        }}
                      >
                        {dateTemp ? dateTemp : 'Date'}
                      </div>
                      <input
                        type="text"
                        className="dropDownInput"
                        placeholder="Search..."
                        style={{
                          display: customSelectedDrop === 'date' ? 'block' : '',
                        }}
                        id="inputDate"
                        onChange={(event) => {
                          searchDates(event.target.value, dobData.dates);
                        }}
                      />
                    </div>
                    <div
                      className={classNames.dropDownList}
                      style={{
                        maxHeight: customSelectedDrop === 'date' ? '650px' : '',
                        border: customSelectedDrop === 'date' ? '' : '0',
                        overflowY:
                          customSelectedDrop === 'date' ? 'scroll' : '',
                      }}
                    >
                      {mapDates &&
                        mapDates.dates.map((eachDate, i) => {
                          return (
                            <div
                              style={{ padding: '1rem 1.5rem' }}
                              onClick={(event) => {
                                if (eachDate === 'Date') {
                                  setDateTemp('01');
                                } else {
                                  setDateTemp(event.target.innerText);
                                }
                                setCustomSelectedDrop('');
                              }}
                              key={'eachDate' + i}
                            >
                              {eachDate}
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <img
                    src={selectDownArrow}
                    alt="selectDownArrow"
                    style={{
                      rotate: customSelectedDrop === 'date' ? '180deg' : '',
                      top: customSelectedDrop === 'date' ? '30%' : '',
                    }}
                    onClick={() => setCustomSelectedDrop('')}
                  />
                </div>

                <div
                // onClick={() => {
                //   if (customSelectedDrop === 'year') {
                //     return setCustomSelectedDrop('');
                //   }
                //   setCustomSelectedDrop('year');
                // }}
                >
                  <div>
                    <div
                      style={{
                        color: yearTemp ? '#7d7d7d' : '#7d7d7d80',
                        borderRadius:
                          customSelectedDrop === 'year' ? '10px 10px 0 0' : '',
                      }}
                    >
                      <div
                        onClick={(event) => {
                          if (customSelectedDrop === 'year') {
                            return setCustomSelectedDrop('');
                          }
                          setCustomSelectedDrop('year');
                        }}
                      >
                        {yearTemp ? yearTemp : 'Year'}
                      </div>
                      <input
                        type="text"
                        className="dropDownInput"
                        placeholder="Search..."
                        style={{
                          display: customSelectedDrop === 'year' ? 'block' : '',
                        }}
                        id="inputYear"
                        onChange={(event) => {
                          searchDates(event.target.value, dobData.years);
                        }}
                      />
                    </div>
                    <div
                      className={classNames.dropDownList}
                      style={{
                        maxHeight: customSelectedDrop === 'year' ? '650px' : '',
                        border: customSelectedDrop === 'year' ? '' : '0',
                        overflowY:
                          customSelectedDrop === 'year' ? 'scroll' : '',
                      }}
                    >
                      {mapDates &&
                        mapDates.years.map((eachYear, i) => {
                          return (
                            <div
                              style={{ padding: '1rem 1.5rem' }}
                              onClick={(event) => {
                                if (eachYear === 'Year') {
                                  setYearTemp('2000');
                                } else {
                                  setYearTemp(event.target.innerText);
                                }

                                setCustomSelectedDrop('');
                              }}
                              key={'eachYear' + i}
                            >
                              {eachYear}
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <img
                    src={selectDownArrow}
                    alt="selectDownArrow"
                    style={{
                      rotate: customSelectedDrop === 'year' ? '180deg' : '',
                      top: customSelectedDrop === 'year' ? '30%' : '',
                    }}
                    onClick={() => setCustomSelectedDrop('')}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames.countryDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="country">Select Country</label>
              <div
                onClick={() => setcountryOpen(!countryOpen)}
                style={{
                  color: country ? '#7d7d7d' : '#7d7d7d8',
                  // borderRadius: !country ? '10px 10px 0 0' : '',
                }}
              >
                <span>{country ? country : 'Country'}</span>
                <img
                  src={selectDownArrow}
                  alt="selectDownArrow"
                  onClick={() => setCustomSelectedDrop('')}
                />
              </div>
            </div>
            <div
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="address">Select State</label>
              <input
                type="text"
                placeholder="State"
                onChange={(event) => {
                  setstate(event.target.value);
                }}
                value={state}
              />
            </div>
            <div
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="address">Enter Address</label>
              <input
                type="text"
                placeholder="Address.."
                onChange={(event) => {
                  setaddress(event.target.value);
                }}
                value={address}
              />
            </div>
            <div
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="address">Enter Pincode</label>
              <input
                type="text"
                placeholder="Pincode.."
                onChange={(event) => {
                  setpincode(event.target.value);
                }}
                value={pincode}
              />
            </div>
            <div className={classNames.mobileNumberDiv}>
              <label htmlFor="address">Enter Phone Number</label>

              <div>
                <div
                // onClick={() => {
                //   if (customSelectedDrop === 'mobilecode') {
                //     return setCustomSelectedDrop('');
                //   }
                //   setCustomSelectedDrop('mobilecode');
                // }}
                >
                  <div>
                    <div
                      style={{
                        color: countryCode ? '#7d7d7d' : '#7d7d7d80',
                        borderRadius:
                          customSelectedDrop === 'mobilecode'
                            ? '10px 10px 0 0'
                            : '',
                      }}
                    >
                      <div
                        onClick={() => {
                          if (customSelectedDrop === 'mobilecode') {
                            return setCustomSelectedDrop('');
                          }
                          setCustomSelectedDrop('mobilecode');
                        }}
                      >
                        {countryCode ? countryCode : '+91'}
                      </div>
                      <input
                        type="text"
                        className="dropDownInput"
                        style={{
                          display:
                            customSelectedDrop === 'mobilecode' ? 'block' : '',
                        }}
                        id="inputmobilecode"
                        placeholder="Country code..."
                        onChange={(event) => {
                          filterInsideData(
                            event.target.value,
                            country_list,
                            setmapCountryCode
                          );
                        }}
                      />
                    </div>
                    <div
                      className={classNames.dropDownList}
                      style={{
                        maxHeight:
                          customSelectedDrop === 'mobilecode' ? '350px' : '',
                        border: customSelectedDrop === 'mobilecode' ? '' : '0',
                        overflowY:
                          customSelectedDrop === 'mobilecode' ? 'scroll' : '',
                      }}
                    >
                      {mapCountryCode.map((country, i) => {
                        return (
                          <div
                            style={{ padding: '1rem 1.5rem' }}
                            onClick={(event) => {
                              setCountryCode(event.target.innerText);
                              setCustomSelectedDrop('');
                            }}
                            key={'country' + i}
                          >
                            {'+' + country.code}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <img
                    src={selectDownArrow}
                    alt="selectDownArrow"
                    style={{
                      rotate:
                        customSelectedDrop === 'mobilecode' ? '180deg' : '',
                      top: customSelectedDrop === 'mobilecode' ? '30%' : '',
                    }}
                    onClick={() => setCustomSelectedDrop('')}
                  />
                </div>

                <input
                  type="number"
                  placeholder="000-000-0000"
                  max="10"
                  onChange={(event) => {
                    setphone(event.target.value);
                  }}
                  value={phone}
                  onClick={() => setCustomSelectedDrop('')}
                />
              </div>
            </div>
            <div
              className={classNames.genderDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label>Select Martial Status</label>
              <div>
                <div
                  onClick={(event) => setmartialStatus(event.target.innerText)}
                  style={applyStyle(martialStatus == 'Married')}
                >
                  <label htmlFor="Married">Married</label>
                </div>
                <div
                  onClick={(event) => setmartialStatus(event.target.innerText)}
                  style={applyStyle(martialStatus == 'Single')}
                >
                  <label htmlFor="Single">Single</label>
                </div>
              </div>
            </div>
            <div className={classNames.childDiv}>
              <label htmlFor="children">How Many Children Do You Have?</label>
              <div>
                <div
                // onClick={() => {
                //   if (customSelectedDrop === 'child') {
                //     return setCustomSelectedDrop('');
                //   }
                //   setCustomSelectedDrop('child');
                // }}
                >
                  <div>
                    <div
                      style={{
                        color: childrens ? '#7d7d7d' : '#7d7d7d80',
                        borderRadius:
                          customSelectedDrop === 'child' ? '10px 10px 0 0' : '',
                      }}
                      className={classNames.childInputDiv}
                    >
                      <div
                        onClick={() => {
                          if (customSelectedDrop === 'child') {
                            return setCustomSelectedDrop('');
                          }
                          setCustomSelectedDrop('child');
                        }}
                      >
                        {childrens ? childrens : 'Select Number'}
                      </div>
                      <input
                        type="text"
                        className="dropDownInput"
                        style={{
                          display:
                            customSelectedDrop === 'child' ? 'block' : '',
                        }}
                        id="inputChild"
                        placeholder="Enter no of childrens..."
                        onChange={(event) => {
                          filterData(
                            event.target.value,
                            childCount,
                            setmapChildCount
                          );
                        }}
                      />
                    </div>
                    <div
                      className={classNames.dropDownList}
                      style={{
                        maxHeight:
                          customSelectedDrop === 'child' ? '350px' : '',
                        border: customSelectedDrop === 'child' ? '' : '0',
                        overflowY:
                          customSelectedDrop === 'child' ? 'scroll' : '',
                      }}
                    >
                      {mapChildCount.map((child, i) => {
                        return (
                          <div
                            style={{ padding: '1rem 1.5rem' }}
                            onClick={(event) => {
                              if (child != 'Select Number') {
                                setchildrens(event.target.innerText);
                              } else {
                                setchildrens('');
                              }

                              setCustomSelectedDrop('');
                            }}
                            key={'child' + i}
                          >
                            {child}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <img
                    src={selectDownArrow}
                    alt="selectDownArrow"
                    style={{
                      rotate: customSelectedDrop === 'child' ? '180deg' : '',
                      top: customSelectedDrop === 'child' ? '30%' : '',
                    }}
                    onClick={() => setCustomSelectedDrop('')}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="Enter occupation">What is your current occupation?</label>
              <input
                type="text"
                placeholder="Enter occupation"
                onChange={(event) => {
                  setoccupation(event.target.value);
                }}
                value={occupation}
              />
            </div>
            <div
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="Annual Income">What is your annual income</label>
              <input
                type="text"
                placeholder="0.00"
                onChange={(event) => {
                  setannualIncome(event.target.value);
                }}
                value={annualIncome}
              />
            </div>
            <div
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="Annual Income">What are you looking to accomplish with Retired.App?</label>
              {purposeData.map((item, index) => {
                return <div style={{ background: item?.type === selectedType ? "#44C2F4" : "", borderRadius: "10px" }}>
                  <div
                    style={{ color: item?.type === selectedType ? "white" : "" }}
                    className={classNames.incomeBox}
                    onClick={() => setSelectedType(item?.type)}>
                    {item?.theText}
                  </div>
                </div>
              })}
            </div>
            <div
              style={{ display: selectedType === "advisor" ? "" : "none" }}
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="Annual Income">Which brokerage do you work for?</label>
              <input
                type="text"
                placeholder="Enter Brokerage"
                onChange={(event) => {
                  setBrokerageName(event.target.value);
                }}
                value={brokerageName}
              />
            </div>
            <div
              style={{ display: selectedType === "advisor" ? "" : "none" }}
              className={classNames.addressDiv}
              onClick={() => setCustomSelectedDrop('')}
            >
              <label htmlFor="Annual Income">Enter your ARN number</label>
              <input
                type="text"
                placeholder="Enter ARN"
                onChange={(event) => {
                  setArnNumber(event.target.value);
                }}
                value={arnNumber}
              />
            </div>
            <div
              className={classNames.updateBtn}
              onClick={() => {
                setCustomSelectedDrop('');
                createUser();
              }}
            >
              {planBAccountDetails || localStorage.getItem('plaBAccount')
                ? 'Update Profile'
                : 'Create Account'}
            </div>
          </>
        )}
      </div>
      <ToastContainer
        toastStyle={{
          backgroundColor: '#44c2f4',
          color: 'white',
          fontWeight: '600',
        }}
      />
    </div>
  );
};

export default EditSidebar;

export const CountryDiv = ({ setcountry, setcountryOpen, countryOpen }) => {
  const [countryListCopy, setCountryListCopy] = useState(country_list);

  function searchCountry(text) {
    let res = country_list.filter((country) => {
      let res = country.country.toLowerCase();
      return res.includes(text.toLowerCase());
    });
    setCountryListCopy(res);
  }

  return (
    <div className={classNames.countryIndividual}>
      <div>Select Country</div>
      <input
        type="text"
        placeholder="Search..."
        onChange={(event) => searchCountry(event.target.value)}
      />
      <div>
        {countryListCopy &&
          countryListCopy.map((country, i) => {
            return (
              <div
                onClick={(event) => {
                  setcountry(event.target.innerText);
                  setcountryOpen(!countryOpen);
                }}
                key={'country' + i}
              >
                <span>{country.country}</span>
                <img
                  src={`https://flagcdn.com/24x18/${country.iso.toLowerCase()}.png`}
                  alt={country ? country.country : 'country'}
                  // onClick={() => {
                  //   setcountry(country.country);
                  // }}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
