import React from 'react';
import classNames from './blockspage.module.scss';
import Layout from '../../Layout/Layout';
import ReactContent from '../../components/ReactContent';

function BlocksPage() {
  return (
    <Layout active="blocks" className={classNames.reactPage} hideFooter>
      <ReactContent />
    </Layout>
  );
}

export default BlocksPage;
