import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../../Layout/Layout';
import './PropsalPage.scss'
import axios from 'axios'
import Review from './Review'
export default function PropsalPage() {
    let { retiredid } = useParams();
    const [planbvalue, setplanbvalue] = useState([])
    const [underRewkey, setunderRewkey] = useState(0)
    const [underRew, setunderRew] = useState(false)
    
    useEffect(() => {
        planb()
    }, [retiredid])
    function toCardinal(num) {
        var ones = num % 10;
        var tens = num % 100;

        if (tens < 11 || tens > 13) {
            switch (ones) {
                case 1:
                    return num + "st";
                case 2:
                    return num + "nd";
                case 3:
                    return num + "rd";
            }
        }

        return num + "th";
    }
    var monthNames = ["", "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    const planb = () => {
        // alert(k)
        setplanbvalue([])
        // setaction(false)
        // setcreateForm(false)
        // setpropsal(propsalkey == k && propsal ? false : true)

        axios.get(
            `https://comms.globalxchange.io/retired/proposal/get?PlanbID=${retiredid}`
        ).then(({ data }) => {
            if (data.status) {
                setplanbvalue(data?.data)
                console.log("4534534534534534534", data?.data)
                //setdatalist(data?.data);

            }
            else {
                setplanbvalue([])
            }
        });
    }
    return (



        <>
            <div className='proposalPage'>
                {
                    planbvalue?.map((such, p) => {
                        let dateeee = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(such?.createdAt)
                        let date = dateeee.split(' ')
                        let final = date?.[0]?.split(',')
                        let result = final?.[0]?.split('/')

                        return (
                            <>
                                <div className='Rewiewroposal'>
                                    <div className='underReiveProposal'
                                        onClick={() => {
                                            setunderRewkey(p)

                                            setunderRew(underRewkey == p && underRew ? false : true)
                                        }}
                                    >
                                        <div className='titleactionReviw'
                                            style={{
                                                fontWeight: "600",
                                                width: "34rem"
                                            }}
                                        >
                                            Prepared by {such?.advisorEmail} on {monthNames[result[0]?.replace(/^0+/, '')]} {toCardinal(result[1])}  {result[2]}
                                        </div>
                                        <div className='titleactionReviw'>
                                            {
                                                such?._id
                                            }
                                        </div>

                                        <div className='underReview'

                                            style={{
                                                background: such?.proposalStatus == "submitted" ? "#FF9100" :
                                                    such?.proposalStatus == "approved" ? "#1BA27A" :
                                                        such?.proposalStatus == "rejected" ? "#EA0F0F" :
                                                            such?.proposalStatus == "request for resubmission" ? "#44C2F4" : "#FF9100"
                                            }}
                                        >
                                            {
                                                such?.proposalStatus == "submitted" ? "Submitted" :
                                                    such?.proposalStatus == "approved" ? "Approved" :
                                                        such?.proposalStatus == "rejected" ? "Rejected" :
                                                            such?.proposalStatus == "request for resubmission" ? "Request For Resubmission" : ""


                                            }
                                            {/* */}
                                        </div>

                                    </div>

                                    {
                                        underRew && underRewkey == p &&
                                        <Review such={such} 
                                        planb={planb}
                                        />
                                    }
                                </div>


                            </>

                        )
                    })
                }
            </div>
        </>

    )
}
