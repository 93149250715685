export const QuestionData = [
  {
    title: 'What Is A Retirement SIP? (RSIP)',
    content:
      "A Retirement SIP is a contract created when you purchase at least one Retirement Block. Each SIP comes with a SIPHash where you can monitor all the transactions, earnings, and underlying Retirement Blocks. The creation of a new Retirement SIP also triggers the result of a Retirement Savings Pool. As a user, you can have multiple Retirement SIP's going simultaneously ",
  },
  {
    title: 'What Is A Retirement Savings Pool? (RSP)',
    content:
      'A Retirement Savings Pool (RSP) is created with each Retirement SIP you start. The final RBlock value, 672 USDT, is put into your RSP for six months earning 2% monthly. The RSP only becomes used if you roll over the concluding value of the first set of RBlocks at the end of the first six-month term. At the end of each six months, the next 672 USDT is added to the RSP, and this process continues until you achieve your ultimate financial goal.',
  },
];
