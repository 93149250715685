import React, { useContext, useEffect, useState } from 'react';
// import classNames from './transactions.module.scss';
import classNames from './components/vaultsPage.module.scss';
import Pagination from '../../Pagination/Pagination';
import VaultTransactionsShares from './components/VaultTransactionsShare';
import StackingRewardsControls from './components/ShareReward';
import VaultHeaderShares from './components/VaultHeaderShares';
import { BankContext } from '../../../context/Context';
import { VaultPageContext } from '../../../context/VaultPageContext';
import axios from 'axios';

const RSIPTransactions = ({
  shareTokenTranscation,
  SLoading,
  passvaulterFiler,
}) => {
  const { email } = useContext(BankContext);
  const {
    assetClass,
    appSelected,
    setAppSelected,
    vaultSelected,
    setVaultSelected,
    vaultsListFxCrypto: vaultsList,
    vaultsListFxCryptoLoading: loading,
    bondsPagination,
    setBondsPagination,
    bondsTxnsPerPage,
    setBondsTxnsPerPage,
    blockContractSelected,
    setBlockContractSelected,
  } = useContext(VaultPageContext);

  const [transactionsData, setTransactionsData] = useState('');
  const [transactionsDataLoading, setTransactionsDataLoading] = useState(false);

  useEffect(() => {
    setTransactionsDataLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/retired/block/interest/log?block_contract_identifier=${blockContractSelected}`
      )
      .then((response) => {
        console.log(response?.data?.data, 'block contract response');
        setTransactionsData(response?.data?.data);
        setTransactionsDataLoading(false);
      })
      .catch((error) => {
        console.log(error?.message);
        setTransactionsDataLoading(false);
      });
  }, [blockContractSelected]);

  return (
    <>
      <div className={classNames.contentArea}>
        <VaultHeaderShares
          email={email}
          vaultSelected={vaultSelected}
          setVaultSelected={setVaultSelected}
          loading={loading}
        />
        <StackingRewardsControls passvaulterFiler={passvaulterFiler} />
        <VaultTransactionsShares
          email={email}
          loading={loading}
          transactionsData={transactionsData}
          transactionsDataLoading={transactionsDataLoading}
        />
        <Pagination
          pagination={0}
          setPagination={setBondsPagination}
          txns={bondsTxnsPerPage}
          setTxns={setBondsTxnsPerPage}
          disabledd="true"
        />
      </div>
    </>
  );
};

export default RSIPTransactions;
