import React, { useContext } from 'react';
import { planBContext } from '../../../context/PlanBContext';
import classNames from './components.module.scss';
import { InputDivs } from './CreatePlanB';
import { uploadImageFunc } from '../../../utils/imageUpload';

//assets
import uploading from '../../../static/images/uploading.svg';
import axios from 'axios';
import { BankContext } from '../../../context/Context';
import { predefinedToast } from '../../../utils/toast';

const CreatePlanType = () => {
  const {
    planBCreateName,
    setplanBCreateName,
    planBCreateDescription,
    setplanBCreateDescription,
    planBPlanPic,
    setallPlansList,
    creationLoading,
    setCreationLoading,
    planBPlanPicUploading,
    setinsideplanBCreate,
    setplanTypeCreate,
  } = useContext(planBContext);
  const { email, token } = useContext(BankContext);

  function createPlanTypeFunc() {
    // console.log(planBCreateName, 'planBCreateName');
    // console.log(planBCreateDescription, 'planBCreateDescription');
    // console.log(planBPlanPic, 'planBPlanPic');
    setCreationLoading(true);
    let obj = {
      email: email,
      token: token,
      name: planBCreateName,
      icon: planBPlanPic ? planBPlanPic : 'test',
      description: planBCreateDescription,
    };
    axios
      .post('https://comms.globalxchange.io/retired/block/creategoals', obj)
      .then((response) => {
        if (response?.data?.status) {
          predefinedToast('New plan type created..');
        } else {
          predefinedToast(response?.data?.message);
        }
        // console.log(response, 'plan type created');
        setCreationLoading(false);
        setinsideplanBCreate('plantype');
        setplanTypeCreate(false);
      })
      .catch((error) => {
        console.log(error, 'error in creating plan type');
        setCreationLoading(false);
        setinsideplanBCreate('plantype');
        setplanTypeCreate(false);
        predefinedToast('Network error please try again..');
      });
  }

  return (
    <div className={classNames.createPlanBTypeContainer}>
      <div className={classNames.title}>Create PlanB Type</div>
      <div className={classNames.contentDiv}>
        <InputDivs
          heading="What Is The Name Of The PlanB Type?"
          placeholderText="Name..."
          setFunc={setplanBCreateName}
          funcValue={planBCreateName}
        />
        <InputDivs
          heading="Describe The PlanB Type?"
          placeholderText="Description..."
          setFunc={setplanBCreateDescription}
          funcValue={planBCreateDescription}
        />
        <ImageUploadDiv heading="Upload An Icon For This PlanB Type" />
        {/* setFunc */}
        <div
          className={classNames.planCreationBtn}
          onClick={() => {
            createPlanTypeFunc();
          }}
          style={{ pointerEvents: planBPlanPicUploading ? 'none' : '' }}
        >
          Create PlanB Type
        </div>
      </div>
    </div>
  );
};

export default CreatePlanType;

export const ImageUploadDiv = ({ heading }) => {
  const {
    planBPlanPic,
    setplanBPlanPic,
    planBPlanPicUploading,
    setplanBPlanPicUploading,
  } = useContext(planBContext);

  return (
    <div className={classNames.imageUploadDiv}>
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.imageDiv}>
        <img
          src={
            planBPlanPic
              ? planBPlanPic
              : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/87aa85500485decb550e820f4ffdba63'
          }
          alt="planBPlanPic"
          className={classNames.profileImg}
        />
        {planBPlanPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <label
        for="profileUpdateImgPlanBPlan"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setplanBPlanPic, setplanBPlanPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanBPlan"
        />
        <div>{planBPlanPicUploading ? 'Uploading...' : 'Upload Picture'}</div>
      </label>
    </div>
  );
};
