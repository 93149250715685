import React, { createContext, useContext, useState } from 'react';

export const MarketContext = createContext({});

export const MarketContextProvider = ({ children }) => {
  const [streamOpen, setStreamOpen] = useState(false);
  const [defiTab, setDefiTab] = useState('Offerings');
  const [coinSelected, setCoinSelected] = useState('');
  const [createbtn, setCreateBtn] = useState(false);
  const [planBOpened, setPlanBOpened] = useState(true);
  const [buyBlocks, setBuyBlocks] = useState(false);
  const [globalappColor, setGlobalAppColor] = useState('');
  const [globalappIcon, setGlobalAppIcon] = useState('');
  const [pin, setPin] = useState('');
  const [genderSelected, setGenderSelected] = useState('Male');
  const [profileUpdateSidebar, setProfileUpdateSidebar] = useState(false);
  const [planBProfile, setPlanBProfile] = useState({
    email: '',
    token: '',
    firstName: '',
    lastName: '',
    birthday: '',
    profilePic: '',
    countryName: '',
    countryFlag: '',
    address: '',
    gender: '',
    martialStatus: '',
    children: '',
    phoneNumber: '',
  });
  const [icon, setIcon] = useState('');
  const [planBPin, setPlanBPin] = useState(false);

  ///temp

  const [dateTemp, setDateTemp] = useState();
  const [monthTemp, setMonthTemp] = useState();
  const [monthTempNum, setMonthTempNum] = useState('01');
  const [yearTemp, setYearTemp] = useState();
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [country, setcountry] = useState('');
  const [state, setstate] = useState('');
  const [pincode, setpincode] = useState('');
  const [address, setaddress] = useState('');
  const [phone, setphone] = useState('');
  const [childrens, setchildrens] = useState('');
  const [occupation, setoccupation] = useState('');
  const [annualIncome, setannualIncome] = useState('');
  const [martialStatus, setmartialStatus] = useState('Single');
  const [countryCode, setCountryCode] = useState('+91');
  const [iconLoading, setIconLoading] = useState(false);
  const [countryOpen, setcountryOpen] = useState(true);

  // opening plan
  const [openingPlan, setOpeningPlan] = useState('');

  //spis
  const [connectSipSidebar, setConnectSipSibebar] = useState(false);
  const [usersAllSip, setusersAllSip] = useState([]);
  const [blockContractId, setblockContractId] = useState([]);

  return (
    <MarketContext.Provider
      value={{
        streamOpen,
        setStreamOpen,
        defiTab,
        setDefiTab,
        coinSelected,
        setCoinSelected,
        createbtn,
        setCreateBtn,
        planBOpened,
        setPlanBOpened,
        buyBlocks,
        setBuyBlocks,
        globalappIcon,
        setGlobalAppIcon,
        globalappColor,
        setGlobalAppColor,
        pin,
        setPin,
        genderSelected,
        setGenderSelected,
        planBProfile,
        setPlanBProfile,
        profileUpdateSidebar,
        setProfileUpdateSidebar,
        icon,
        setIcon,
        //temp
        dateTemp,
        setDateTemp,
        monthTemp,
        setMonthTemp,
        monthTempNum,
        setMonthTempNum,
        yearTemp,
        setYearTemp,
        firstName,
        setfirstName,
        lastName,
        setlastName,
        country,
        setcountry,
        address,
        setaddress,
        state, 
        setstate,
        pincode, 
        setpincode,
        occupation, 
        setoccupation,
        annualIncome, 
        setannualIncome,
        phone,
        setphone,
        childrens,
        setchildrens,
        martialStatus,
        setmartialStatus,
        countryCode,
        setCountryCode,
        iconLoading,
        setIconLoading,
        // countryOpen,
        // setcountryOpen,
        planBPin,
        setPlanBPin,
        openingPlan,
        setOpeningPlan,
        connectSipSidebar,
        setConnectSipSibebar,
        usersAllSip,
        setusersAllSip,
        blockContractId,
        setblockContractId,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export const useMarketContext = () => useContext(MarketContext);
