import axios from 'axios';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { BankContext } from '../../../context/Context';
import { useMarketContext } from '../../../context/MarketContext';
import classNames from './connectspi.module.scss';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { LoadingAnimation } from '../../LoadingAnimation';
import { ToastContainer } from 'react-toastify';
import { predefinedToast } from '../../../utils/toast';

const ConnectSipSidebar = () => {
  let { retiredid } = useParams();
  const {
    globalappIcon,
    connectSipSidebar,
    setConnectSipSibebar,
    usersAllSip,
    setusersAllSip,
    blockContractId,
    setblockContractId,
  } = useMarketContext();

  const { email, token } = useContext(BankContext);

  const [usersAllSipTemp, setusersAllSipTemp] = useState([]);
  const [connectionLoading, setconnectionLoading] = useState(false);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/retired/contract/get/blocks/notInPlan?email=${email}`
      )
      .then((response) => {
        // console.log(response?.data?.data, 'chcgcggcgcgcgcg');
        setusersAllSip(response?.data?.data);
        setusersAllSipTemp(response?.data?.data);
      });
  }, []);

  useEffect(() => {
    setblockContractId([]);
  }, [connectSipSidebar]);

  function connectSPItoRetirement() {
    setconnectionLoading(true);
    let obj = {
      email: email,
      token: token,
      block_contract_id: blockContractId,
      retirement_plan_id: retiredid,
    };

    axios
      .post(
        'https://comms.globalxchange.io/retired/contract/add/blockcontract',
        obj
      )
      .then((response) => {
        // console.log(response?.data, 'response after connecting');

        if (response?.data?.status) {
          setconnectionLoading(false);
          setConnectSipSibebar(false);
          predefinedToast("SIP's Successfully...");
        } else {
          setconnectionLoading(false);
          setConnectSipSibebar(false);
          predefinedToast("Failed to update SIP's...");
        }
      })
      .catch((error) => {
        console.log(error, 'connectSPItoRetirement');
        setconnectionLoading(false);
        setConnectSipSibebar(false);
        predefinedToast('Network error...please try again');
      });

    // setconnectionLoading(false);
  }

  function selectAndDeselectBlockIds(blockid) {
    let filterBlockId = blockContractId.filter((eachblockid) => {
      return eachblockid !== blockid;
    });

    if (filterBlockId?.length == blockContractId?.length) {
      setblockContractId([...blockContractId, blockid]);
    } else {
      setblockContractId(filterBlockId);
    }
  }

  function searchBox(enteredtext) {
    let filteredUser = usersAllSip.filter((eachsip) => {
      let searching = eachsip?.no_of_blocks + ' Block RSIP';
      return searching?.toLowerCase().includes(enteredtext?.toLowerCase());
    });
    setusersAllSipTemp(filteredUser);
  }

  return (
    <div
      className={classNames.connectSipSidebar}
      style={{ right: connectSipSidebar ? '0' : '' }}
    >
      <div
        className={classNames.closeBtn}
        onClick={() => setConnectSipSibebar(false)}
      >
        x
      </div>
      <div className={classNames.heading}>Connect SIP To This PlanB</div>
      <div className={classNames.contentmainContainer}>
        <input
          type="text"
          placeholder="Search..."
          onChange={(event) => searchBox(event.target.value)}
        />
        <div className={classNames.contentDiv}>
          {usersAllSipTemp?.length > 0
            ? usersAllSipTemp?.map((eachspi, i) => {
                return (
                  <EachContentDiv
                    eachspi={eachspi}
                    func={selectAndDeselectBlockIds}
                    key={'eachspi' + i}
                  />
                );
              })
            : ''}
        </div>
        <div className={classNames.addSipBtn} onClick={connectSPItoRetirement}>
          Add {blockContractId?.length} SIP’s
        </div>
      </div>
      {connectionLoading ? (
        <div className="loadingIcon">
          <LoadingAnimation icon={globalappIcon} width={200} />
        </div>
      ) : null}
      <ToastContainer
        toastStyle={{
          backgroundColor: '#44c2f4',
          color: 'white',
          fontWeight: '600',
        }}
      />
    </div>
  );
};

export default ConnectSipSidebar;

const EachContentDiv = ({ eachspi, func }) => {
  const { connectSipSidebar } = useMarketContext();
  var t = new Date(eachspi?.createdAt);
  let finalFormat = moment(t).format('MMM Do [20]YY');

  const [sipSelected, setSipSelected] = useState(false);

  useEffect(() => {
    setSipSelected(false);
  }, [connectSipSidebar]);

  // console.log(eachspi?.identifier, 'eachspi');
  return (
    <div
      className={classNames.eachSipContainer}
      onClick={() => {
        func(eachspi?.identifier);
        setSipSelected(!sipSelected);
      }}
    >
      <div className={classNames.title}>
        {eachspi?.no_of_blocks + ' Block RSIP'}
      </div>
      <div>Started On {finalFormat ? finalFormat : ''}</div>
      <div
        className={classNames.selectedSip}
        style={{ visibility: sipSelected ? 'visible' : '' }}
      >
        <svg viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.0684 1.88086L4.65164 7.29742C4.48914 7.45993 4.27582 7.54172 4.0625 7.54172C3.84918 7.54172 3.63586 7.45993 3.47336 7.29742L0.765076 4.58914C0.439148 4.26337 0.439148 3.73663 0.765076 3.41086C1.09085 3.08493 1.61743 3.08493 1.94336 3.41086L4.0625 5.53L8.89008 0.702576C9.21585 0.376648 9.74243 0.376648 10.0684 0.702576C10.3941 1.02835 10.3941 1.55493 10.0684 1.88086V1.88086Z" />
        </svg>
      </div>
    </div>
  );
};
