import React, { createContext, useEffect, useState } from 'react';
import Axios from 'axios';

import allPlatforms from '../static/images/allPlatforms.svg';
import ModalConfirm from '../components/ModalConfirm/ModalConfirm';
import SelectCountry from '../components/SelectCountry/SelectCountry';
import ModalSessionExpired from '../components/ModalSessionExpired/ModalSessionExpired';
import ToastMessage from '../components/ToastMessage/ToastMessage';
import EnterPinUnlock from '../components/EnterPinUnlock/EnterPinUnlock';
import { APP_CODE } from '../config/appConfig';
import Cookies from 'js-cookie';
import { APP_USER_TOKEN } from '../config';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';

export const BankContext = createContext();

function BankContextProvider({ children }) {
  const [admin, setAdmin] = useState(false);
  const [selectedTopItem, setSelectedTopItem] = useState('Vision Board');
  const [selectedTopItemReact, setSelectedTopItemReact] = useState('Retire');
  const [refreshPage, setRefreshPage] = useState('');
  const [refreshGoals, setRefreshGoals] = useState(false)
  const [globalseacrhadviser, setglobalseacrhadviser] = useState('')
  const [email, setEmail] = useState(
    localStorage.getItem('nvestBankLoginAccount') || ''
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('nvestBankAccessToken') || ''
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem('nvestBankIdToken') || ''
  );

  useEffect(() => {
    !admin && localStorage.setItem('nvestBankLoginAccount', email);
  }, [email, admin]);
  useEffect(() => {
    localStorage.setItem('nvestBankAccessToken', accessToken);
  }, [accessToken]);
  useEffect(() => {
    localStorage.setItem('nvestBankIdToken', idToken);
  }, [idToken]);

  const appUserToken = Cookies.get(APP_USER_TOKEN);

  const userLoginHandler = (paramEmail, paramAccessToken, paramIdToken) => {
    localStorage.removeItem('plaBAccount');
    setEmail(paramEmail);
    setAccessToken(paramAccessToken);
    setIdToken(paramIdToken);
    if (!paramEmail || !paramAccessToken || !paramIdToken) {
      Cookies.remove(APP_USER_TOKEN);
    }
  };

  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const [profileId, setProfileId] = useState('');
  const [iceProfileId, setIceProfileId] = useState('');

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  const [videoPlaying, setVideoPlaying] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVideoPlaying(false);
    }, 6000);
  }, []);

  useEffect(() => {
    function getUserData() {
      Axios.post('https://comms.globalxchange.io/get_affiliate_data_no_logs', {
        email: email,
      }).then((res) => {
        const data = res.data[0];
        if (data) {
          setUsername(data.username);
          setName(data.name);
          setProfileImg(data.profile_img);
        }
      });
      Axios.get(
        `https://comms.globalxchange.io/user/details/get?email=${email}`
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          setProfileId(data.user[`${APP_CODE}_profile_id`]);
          setIceProfileId(data.user.ice_profile_id);
        }
      });
    }
    if (email && idToken) {
      Axios.post('https://comms.globalxchange.io/coin/verifyToken', {
        email,
        token: idToken,
      }).then((res) => {
        setPlanBAccountDetails('');
        res.data.status || admin ? getUserData() : userLoginHandler('', '', '');
      });
    }
  }, [email, idToken]);

  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastTitle, setToastTitle] = useState('');
  const tostShowOn = (message, title) => {
    setToastShow(true);
    setToastMessage(message);
    setToastTitle(title);
    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  const [ratesRes, setRatesRes] = useState([]);
  const [liquidRates, setLiquidRates] = useState([]);
  useEffect(() => {
    Axios.get(
      'https://comms.globalxchange.io/coin/vault/earnings/getinterestrates'
    ).then((res) => {
      const { data } = res;
      if (data.status) {
        setRatesRes(data.rates);
      }
    });
    Axios.get(
      'https://comms.globalxchange.io/coin/iced/get/liquid/interest'
    ).then((res) => {
      const { data } = res;
      if (data.status) {
        const { interest_rates } = data;
        setLiquidRates(interest_rates);
      }
    });
  }, []);

  const [coinList, setCoinList] = useState([]);
  const updateBalance = () => {
    Axios.post('https://comms.globalxchange.io/coin/vault/service/coins/get', {
      app_code: APP_CODE,
      profile_id: profileId,
    }).then((res) => {
      const { data } = res;
      if (data.status) {
        const { coins_data } = data;
        coins_data.sort(GetSortOrder('type'));
        setCoinList(coins_data);
      }
    });
  };
  useEffect(() => {
    updateBalance();
  }, [profileId, refreshPage.balanceUpdate]);

  const [icedContracts, setIcedContracts] = useState([]);
  function getIcedContracts() {
    email &&
      Axios.get(
        `https://comms.globalxchange.io/coin/iced/contract/get?email=${email}`
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          const { icedContracts } = data;
          setIcedContracts(icedContracts);
        }
      });
  }
  useEffect(() => {
    getIcedContracts();
    // eslint-disable-next-line
  }, [email, refreshPage.balanceUpdate]);

  const [coinListObject, setCoinListObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    coinList.forEach((coin) => {
      coinObj[coin.coinSymbol] = coin;
    });
    setCoinListObject(coinObj);
  }, [coinList]);

  const [coinNameObject, setCoinNameObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    coinList.forEach((coin) => {
      coinObj[coin.coinName] = coin;
    });
    setCoinNameObject(coinObj);
  }, [coinList]);

  const [liquidRatesObject, setLiquidRatesObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    liquidRates.forEach((coin) => {
      coinObj[coin.coin] = coin;
    });
    setLiquidRatesObject(coinObj);
  }, [liquidRates]);

  const [openDefaultCoinSidebar, setOpenDefaultCoinSidebar] = useState(false);
  const [defaultCoin, setDefaultCoin] = useState({
    coin: null,
    name: 'Default Coin',
    img: allPlatforms,
  });
  const convertCoin = (amount, coin) => {
    if (defaultCoin.coin && defaultCoin.coin !== null) {
      return (
        (amount * coinListObject[coin].price.USD) /
        coinListObject[defaultCoin.coin].price.USD
      );
    } else {
      return amount;
    }
  };

  const [footerShow, setFooterShow] = useState(true);

  const [updateInterval, setUpdateInterval] = useState(5);

  // Modal Variables
  const [openModal, setOpenModal] = useState(false);
  const [onClose, setOnClose] = useState(() => { });
  const [onConfirm, setOnConfirm] = useState(() => { });
  const [modalText, setModalText] = useState('');

  const populateModal = (text, onCloseParam, onConfirmParam) => {
    setOpenModal(true);
    setOnClose(() => onCloseParam);
    setOnConfirm(() => onConfirmParam);
    setModalText(text);
  };

  // To Populate List In Sidebar
  const [contentSideBar, setContentSideBar] = useState({});

  // Coin Data For Coin Detail
  const [coinData, setCoinData] = useState({});

  useEffect(() => {
    Axios.get('https://comms.globalxchange.io/coin/vault/get/all/coins').then(
      (res) => {
        const { data } = res;
        if (data.status) {
          let coinObj = {};
          data.coins.forEach((coin) => {
            coinObj[coin.coinSymbol] = coin;
          });
          setCoinData(coinObj);
        }
      }
    );
    return () => { };
  }, []);

  const [conractsObj, setConractsObj] = useState({});
  useEffect(() => {
    Axios.get('https://comms.globalxchange.io/coin/iced/admin/get/data').then(
      (res) => {
        const { data } = res;
        if (data.status) {
          const obj = {};
          data.config_data.forEach((config) => {
            obj[config.coin] = { ...obj[config.coin], ...config };
          });
          setConractsObj(obj);
        }
      }
    );
  }, []);

  const [iceSidebarOpen, setIceSidebarOpen] = useState(false);

  const [modalSessionExpOpen, setModalSessionExpOpen] = useState(false);
  const validateToken = async (paramEmail, paramToken) => {
    if (admin) return true;
    const res = await Axios.post(
      'https://comms.globalxchange.io/coin/verifyToken',
      {
        email: paramEmail,
        token: paramToken,
      }
    );
    if (res.data && res.data.status) {
      return true;
    } else {
      setModalSessionExpOpen(true);
      return false;
    }
  };

  const [bondDetail, setBondDetail] = useState();
  const [chatOn, setChatOn] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    if (videoShow) {
      setChatOn(false);
      setIceSidebarOpen(false);
      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [videoShow]);
  useEffect(() => {
    if (chatOn) {
      setVideoShow(false);
      setIceSidebarOpen(false);
      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [chatOn]);
  useEffect(() => {
    if (iceSidebarOpen) {
      setVideoShow(false);
      setChatOn(false);
      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [iceSidebarOpen]);

  useEffect(() => {
    if (inboxOpen) {
      setVideoShow(false);
      setChatOn(false);
      setSidebarCollapse(true);
      setIceSidebarOpen(false);
    }
  }, [inboxOpen]);

  useEffect(() => {
    if (!sidebarCollapse) {
      setVideoShow(false);
      setChatOn(false);
      setIceSidebarOpen(false);
      setInboxOpen(false);
    }
  }, [sidebarCollapse]);

  useEffect(() => {
    if (settingsOpen) {
      setChatOn(true);
    }
  }, [settingsOpen]);

  function closeSidebars() {
    setVideoShow(false);
    setChatOn(false);
    setIceSidebarOpen(false);
    setInboxOpen(false);
  }
  const [defaultPrecission, setDefaultPrecission] = useState(0);
  const [tempMail, setTempMail] = useState('');
  const [tempPass, setTempPass] = useState('');

  function copyToClipboard(value, title) {
    navigator.clipboard.writeText(value).then(() => {
      tostShowOn(value, title);
    });
  }

  useEffect(() => {
    setGoalsDetails('');
    setcreateGoals('');
  }, [selectedTopItem]);

  const [enterPin, setEnterPin] = useState(false);
  const [selectedbtn, setSelectedbtn] = useState('asset');
  const [bondcount, setBondcount] = useState('');
  const [bplannerSelectedTemp, setBplanerSelectedTemp] = useState(true);

  const [profileID, setProfileID] = useState();
  const [buyBlocksLeft, setBuyBlocksLeft] = useState();
  const [blocksContainerBlock, setBlocksContainerBlock] = useState(false);
  const [blockPerc, setBlockPerc] = useState();
  const [blockAmount, setBlockAmount] = useState();
  const [roundedBlockAmount, setRoundedBlockAmount] = useState();
  const [purchasedBlockResponse, setPurchasedBlockResponse] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);

  const [allUserPlans, setAllUsersPlans] = useState([]);
  const [selectedPlanProgress, setSelectedPlanProgress] = useState([]);
  const [planBAccountDetails, setPlanBAccountDetails] = useState();
  const [planBAccountDetailSearch, setPlanBAccountDetailSearch] = useState('');
  const [progressPageRetirementAcc, setprogressPageRetirementAcc] = useState(
    []
  );
  const [goalsDetails, setGoalsDetails] = useState(false);
  const [createGoals, setcreateGoals] = useState(false);
  const [goalsList, setGoalsList] = useState([]);
  const [sidebarShowProgress, setsidebarShowProgress] = useState([]);

  const [startedDate, setStartedDate] = useState('');
  const [planBAccountName, setplanBAccountName] = useState('');
  const [planBAccountPic, setplanBAccountPic] = useState('');

  //creategoal

  const [goalName, setgoalName] = useState('');
  const [goalDesc, setgoalDesc] = useState('');
  const [goalLink, setgoalLink] = useState('');
  const [goalImage, setGoalImage] = useState('');
  const [goalImageUploading, setGoalImageUploading] = useState(false);
  const [goalCreating, setgoalCreating] = useState(false);

  //onhold
  const [userSelectedOnHold, setUserSelectedOnHold] = useState('');
  const [datalist, setdatalist] = useState([])


  const [opentoggleclass, setopentoggleclass] = useState(false)
  return (
    <BankContext.Provider
      value={{
        userLoginHandler,
        setopentoggleclass,
        opentoggleclass,
        datalist, setdatalist,
        email,
        token: idToken,
        accessToken,
        tostShowOn,
        username,
        name,
        profileImg,
        ratesRes,
        coinList,
        profileId,
        liquidRates,
        liquidRatesObject,
        coinListObject,
        openDefaultCoinSidebar,
        setOpenDefaultCoinSidebar,
        defaultCoin,
        setDefaultCoin,
        convertCoin,
        icedContracts,
        footerShow,
        setFooterShow,
        updateInterval,
        setUpdateInterval,
        populateModal,
        contentSideBar,
        setContentSideBar,
        coinData,
        conractsObj,
        coinNameObject,
        getIcedContracts,
        iceSidebarOpen,
        globalseacrhadviser, setglobalseacrhadviser,
        setIceSidebarOpen,
        validateToken,
        updateBalance,
        chatOn,
        setChatOn,
        admin,
        setAdmin,
        setEmail,
        setIdToken,
        setUsername,
        setName,
        setProfileImg,
        setProfileId,
        refreshPage,
        setRefreshPage,
        videoShow,
        setVideoShow,
        sidebarCollapse,
        setSidebarCollapse,
        inboxOpen,
        setInboxOpen,
        defaultPrecission,
        setDefaultPrecission,
        settingsOpen,
        setSettingsOpen,
        closeSidebars,
        bondDetail,
        setBondDetail,
        tempMail,
        setTempMail,
        tempPass,
        setTempPass,
        iceProfileId,
        copyToClipboard,
        setEnterPin,
        appUserToken,
        bplannerSelectedTemp,
        setBplanerSelectedTemp,
        selectedbtn,
        setSelectedbtn,
        bondcount,
        setBondcount,
        selectedTopItem,
        setSelectedTopItem,
        buyBlocksLeft,
        setBuyBlocksLeft,
        blocksContainerBlock,
        setBlocksContainerBlock,
        blockPerc,
        setBlockPerc,
        blockAmount,
        setBlockAmount,
        roundedBlockAmount,
        setRoundedBlockAmount,
        profileID,
        setProfileID,
        purchasedBlockResponse,
        setPurchasedBlockResponse,
        blockLoading,
        setBlockLoading,
        planBAccountDetails,
        setPlanBAccountDetails,
        selectedTopItemReact,
        setSelectedTopItemReact,
        planBAccountDetailSearch,
        setPlanBAccountDetailSearch,
        allUserPlans,
        setAllUsersPlans,
        progressPageRetirementAcc,
        setprogressPageRetirementAcc,
        goalsDetails,
        setGoalsDetails,
        createGoals,
        setcreateGoals,
        goalsList,
        setGoalsList,
        sidebarShowProgress,
        setsidebarShowProgress,
        //goal creation
        goalName,
        setgoalName,
        goalDesc,
        setgoalDesc,
        goalLink,
        setgoalLink,
        goalImage,
        setGoalImage,
        goalImageUploading,
        setGoalImageUploading,
        goalCreating,
        setgoalCreating,
        startedDate,
        setStartedDate,
        planBAccountName,
        setplanBAccountName,
        planBAccountPic,
        setplanBAccountPic,
        selectedPlanProgress,
        setSelectedPlanProgress,
        refreshGoals, setRefreshGoals,


        //onhold
        userSelectedOnHold,
        setUserSelectedOnHold,
      }}
    >
      {children}
      {/* {videoPlaying ? (
        <div className="firstVideo">
          <img src={loadImg} alt="" />
        </div>
      ) : (
        ''
      )} */}
      {openModal ? (
        <ModalConfirm
          onClose={onClose}
          onConfirm={onConfirm}
          text={modalText}
          setOpenModal={setOpenModal}
        />
      ) : (
        ''
      )}
      <SelectCountry />
      {modalSessionExpOpen ? (
        <ModalSessionExpired
          onClose={() => {
            setModalSessionExpOpen(false);
          }}
        />
      ) : (
        ''
      )}
      {toastShow && (
        <ToastMessage textTwo={toastMessage} textOne={toastTitle} />
      )}
      {enterPin && (
        <EnterPinUnlock
          onSucces={() => {
            setAdmin(true);
            setEnterPin(false);
          }}
          onClose={() => setEnterPin(false)}
        />
      )}
    </BankContext.Provider>
  );
}

export default BankContextProvider;
