import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import BankContextProvider from './context/Context';
import * as serviceWorker from './serviceWorker';
import './static/scss/master.scss';

// import {  ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BankContextProvider>
    <React.StrictMode>
      <App />
      {/* <ToastContainer /> */}
    </React.StrictMode>
  </BankContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
