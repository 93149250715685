import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import loading from '../../static/images/loadingGif.gif';
import './eachCardForList.scss';

export const EachCardForList = ({ onClick, icon, label }) => (
  <div className="each-card" onClick={onClick}>
    <div className="eachapp">
      <div>
        <img className="app-icon" src={icon ?? loading} alt="appIcon" />
      </div>
      <h4 style={{ marginLeft: '10px' }}>
        {label ?? (
          <Skeleton width={150} height={30} style={{ marginLeft: '15px' }} />
        )}
      </h4>
    </div>
  </div>
);
