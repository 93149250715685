import React from 'react';
import { useContext, useState } from 'react';
import { planBContext } from '../../../context/PlanBContext';
import classNames from './components.module.scss';

//assets
import selectDownArrow from '../../../static/images/selectDownArrow.svg';
import { selectoneOptions } from '../data/data';
import { uploadImageFunc } from '../../../utils/imageUpload';
import uploading from '../../../static/images/uploading.svg';
import { futureDates } from '../../../static/data/constData';
import { useEffect } from 'react';
import axios from 'axios';
import { BankContext } from '../../../context/Context';
import tempCanadaFlag from '../../../static/images/tempCanadaFlag.svg';
import retiredIcon from '../../../static/images/sidebar-icons/retired.svg';
import { predefinedToast } from '../../../utils/toast';

const CreatePlanB = () => {
  const { email, token } = useContext(BankContext);
  const [goalType, setGoalType] = useState("")
  const [investmentType, setInvestmentType] = useState("")
  const [duration, setDuration] = useState("")
  const {
    planBCreate,
    setplanBCreate,
    setinsideplanBCreate,
    planBAccountName,
    setplanBAccountName,
    planBAccountDesc,
    setplanBAccountDesc,
    setSelectedDropDown,
    planBAccountTargetDate,
    planBAccountContributor,
    planBAccountType,
    planBAccountPic,
    setCreationLoading,
    planBAccountGoalId,
    planBAccountTypeIcon,
    setplanBAccountTypeIcon,
    planBAccountPicUploading,
  } = useContext(planBContext);

  function PlanCreationFunc() {
    setCreationLoading(true);

    let date = planBAccountTargetDate.date ? planBAccountTargetDate.date : '01';
    let month = planBAccountTargetDate.month
      ? planBAccountTargetDate.month
      : '01';
    let year = planBAccountTargetDate.year
      ? planBAccountTargetDate.year
      : '2022';

    let obj = {
      email: email,
      token: token,
      retirement_plan_name: planBAccountName,
      goal_id: planBAccountGoalId,
      icon: planBAccountPic ? planBAccountPic : 'test',
      description: planBAccountDesc ? planBAccountDesc : 'test',
      contributors:
        planBAccountContributor == 'Me'
          ? 'single'
          : planBAccountContributor == 'Couple'
            ? 'couple'
            : planBAccountContributor == 'Friends'
              ? 'multiple'
              : 'single',
      block_contract_id: [],
      date_of_accomplishment: date + '/' + month + '/' + year,
      priority: goalType,
      plangeneratedby: investmentType,
      immediatecontributionestimation:duration
    };



    axios
      .post(
        'https://comms.globalxchange.io/retired/block/add/retirementplan',
        obj
      )
      .then((response) => {
        if (response?.data?.status) {
          predefinedToast(response?.data?.message?.toUpperCase());
        } else {
          predefinedToast(response?.data?.message?.toUpperCase());
        }
        setCreationLoading(false);
        setplanBCreate(false);
        setinsideplanBCreate('createplanb');
      })
      .catch((error) => {
        console.log(error?.message, 'plan creation failed');
        predefinedToast(error?.message?.toUpperCase());
        setCreationLoading(false);
        setplanBCreate(false);
        setinsideplanBCreate('createplanb');
      });
  }

  let goalData = [
    { theText: "Getting to my goal on time", type: "speed" },
    { theText: "Taking on as little risk as possible. I have lots of time", type: "risk" }
  ]

  const investmentData = [
    { theText: "Ai generated", type: "ai" },
    { theText: "By a new certified Financial Advisor", type: "newadvisor" },
    { theText: "By my existing Financial Advisor", type: "existingadvisor" }
  ]

  return (
    <div className={classNames.createPlanB}>
      {/* <div
        className={classNames.closeBtn}
        onClick={() => {
          setplanBCreate(false);
          setinsideplanBCreate('createplanb');
        }}
      >
        {insideplanBCreate == 'createplanb' ? (
          'x'
        ) : (
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.129187 6.34062L5.07279 11.8403C5.16426 11.9423 5.29254 12 5.4269 12L7.55123 12C7.97366 12 8.19304 11.4793 7.90534 11.1595L5.25914 8.21753H12.2409C12.6599 8.21753 13 7.86593 13 7.4327L13 4.56691C13 4.13368 12.6599 3.78208 12.2409 3.78208L5.25952 3.78208L7.90572 0.84055C8.19342 0.520733 7.97404 0 7.55161 0L5.42728 0C5.29292 0 5.16464 0.0576849 5.07316 0.159713L0.129567 5.65978C-0.0431261 5.85167 -0.043128 6.14873 0.129187 6.34062Z"
              fill="white"
            />
          </svg>
        )}
      </div> */}
      <div className={classNames.title}>Create PlanB</div>
      <div className={classNames.contentDiv}>
        <InputDivs
          heading="What Is The Name Of Your Plan?"
          placeholderText="Name..."
          setFunc={setplanBAccountName}
          funcValue={planBAccountName}
        />
        <div
          className={classNames.planType}
          onClick={() => setSelectedDropDown('')}
        >
          <div className={classNames.heading}>Select Plan Type</div>
          <div
            className={classNames.planTypeSelection}
            onClick={() => setinsideplanBCreate('plantype')}
          >
            <span>
              <img
                src={
                  planBAccountTypeIcon.includes('http') ||
                    planBAccountTypeIcon?.length > 10
                    ? planBAccountTypeIcon
                    : retiredIcon
                }
                alt="tempIcon"
              />
              <span>{planBAccountType ? planBAccountType : 'Select'}</span>
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
            //   style={{
            //     rotate: customSelectedDrop === 'month' ? '180deg' : '',
            //     top: customSelectedDrop === 'month' ? '30%' : '',
            //   }}
            />
          </div>
        </div>
        <InputDivs
          heading="Describe Your Plan?"
          placeholderText="Description..."
          setFunc={setplanBAccountDesc}
          funcValue={planBAccountDesc}
        />
        <DateSelectionDiv heading="When Do You Want To Accomplish Your Plan?" />
        <SelectOneDiv
          heading="Who Will Be Contributing To This Plan?"
          options={selectoneOptions}
        />
        <ImageUploadDiv heading="Upload An Icon For This Plan" />
        <div
          className={classNames.dateSelectionDiv}
        >
          <label htmlFor="goal">What are you looking to accomplish with Retired.App?</label>
          {goalData.map((item, index) => {
            return <div style={{ background: item?.type === goalType ? "#44C2F4" : "", borderRadius: "10px" }}>
              <div
                style={{ color: item?.type === goalType ? "white" : "" }}
                className={classNames.incomeBox}
                onClick={() => setGoalType(item?.type)}>
                {item?.theText}
              </div>
            </div>
          })}
        </div>
        <div
          className={classNames.dateSelectionDiv}
        >
          <label htmlFor="investment">How do you want to generate the investment strategy to achieve this PlanB?</label>
          {investmentData.map((item, index) => {
            return <div style={{ background: item?.type === investmentType ? "#44C2F4" : "", borderRadius: "10px" }}>
              <div
                style={{ color: item?.type === investmentType ? "white" : "" }}
                className={classNames.incomeBox}
                onClick={() => setInvestmentType(item?.type)}>
                {item?.theText}
              </div>
            </div>
          })}
        </div>
        <div className={classNames.inputDivs}>
          <div>Prior to implementing austerity measures, how much money can you contribute toward achieving this PlanB for the next six months </div>
          <div className={classNames.inputHolder}>
            <input className={classNames.inputFields} placeholder="0.00" value={duration} onChange={(e)=>setDuration(e.target.value)}/>
          </div>
        </div>
        <div
          className={classNames.createPlanBtn}
          onClick={() => {
            setSelectedDropDown('');
            PlanCreationFunc();
          }}
          style={{
            pointerEvents: planBAccountPicUploading ? 'none' : '',
            opacity: planBAccountPicUploading ? '0.5' : '',
          }}
        >
          Create PlanB
        </div>
      </div>
    </div>
  );
};

export default CreatePlanB;

export const InputDivs = ({
  heading,
  placeholderText,
  setFunc,
  funcValue,
  addCurrency,
}) => {
  const { setSelectedDropDown } = useContext(planBContext);
  return (
    <div
      className={classNames.inputDivs}
      onClick={() => setSelectedDropDown('')}
    >
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.inputHolder}>
        <input
          className={classNames.inputFields}
          placeholder={placeholderText}
          onChange={(event) => {
            setFunc(event.target.value);
          }}
          value={funcValue ? funcValue : ''}
        />
        {addCurrency ? (
          <div className={classNames.currencyDiv}>{addCurrency}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export const DateSelectionDiv = ({ heading }) => {
  const [date, setdate] = useState(futureDates.dates);
  const [month, setMonth] = useState(futureDates.month);
  const [years, setYears] = useState(futureDates.years);

  const [selectedMonth, setselectedMonth] = useState('');
  const {
    selectedDropDown,
    setSelectedDropDown,
    planBAccountTargetDate,
    setplanBAccountTargetDate,
  } = useContext(planBContext);

  function searchDates(text, searchArray) {
    let res;
    if (searchArray?.length == 12) {
      res = searchArray.filter((month) => {
        let ress = month.month.toLowerCase();
        return ress.includes(text.toLowerCase());
      });
      setMonth(res);
    } else if (searchArray?.length == 31) {
      res = searchArray.filter((value) => {
        let ress = value.toLowerCase();
        return ress.includes(text.toLowerCase());
      });
      setdate(res);
    } else {
      res = searchArray.filter((year) => {
        let ress = year.toString();
        return ress.includes(text.toString());
      });
      setYears(res);
    }
  }

  useEffect(() => {
    let selectdomth = futureDates.month.filter((eachmonth) => {
      let ress = eachmonth.month.toLowerCase();
      return ress.includes(selectedMonth.toLowerCase());
    });
    setplanBAccountTargetDate({
      ...planBAccountTargetDate,
      month: selectdomth[0]?.num,
    });
  }, [selectedMonth]);

  return (
    <div className={classNames.dateSelectionDiv}>
      <div
        className={classNames.heading}
        onClick={() => setSelectedDropDown('')}
      >
        {heading}
      </div>
      <div className={classNames.dateSelectorDiv}>
        <div style={{ background: selectedDropDown == 'month' ? 'white' : '' }}>
          <div>
            <span onClick={() => setSelectedDropDown('month')}>
              {selectedDropDown == 'month' ? (
                <input
                  placeholder="Search month..."
                  onChange={(event) =>
                    searchDates(event.target.value, futureDates.month)
                  }
                />
              ) : (
                <span>{selectedMonth ? selectedMonth : 'Month'}</span>
              )}
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{
                rotate: selectedDropDown == 'month' ? '180deg' : '',
                top: selectedDropDown == 'month' ? '30%' : '',
              }}
              onClick={() => {
                if (selectedDropDown == 'month') {
                  setSelectedDropDown('');
                } else {
                  setSelectedDropDown('month');
                }
              }}
            />
          </div>
          <div
            className={classNames.dropDownDiv}
            style={{
              height: selectedDropDown == 'month' ? '' : '0',
              border: selectedDropDown == 'month' ? '' : '0',
              overflowY: selectedDropDown == 'month' ? 'scroll' : 'hidden',
            }}
          >
            {month &&
              month?.map((eachmonth, i) => {
                return (
                  <div
                    onClick={(event) => {
                      setselectedMonth(event.target.innerText);
                      setSelectedDropDown('');
                    }}
                    key={'DateSelectionDiv' + i}
                  >
                    {eachmonth.month}
                  </div>
                );
              })}
          </div>
        </div>
        <div style={{ background: selectedDropDown == 'date' ? 'white' : '' }}>
          <div>
            <span onClick={() => setSelectedDropDown('date')}>
              {selectedDropDown == 'date' ? (
                <input
                  placeholder="Search date..."
                  onChange={(event) =>
                    searchDates(event.target.value, futureDates.dates)
                  }
                />
              ) : (
                <span>
                  {planBAccountTargetDate?.date
                    ? planBAccountTargetDate.date
                    : 'Date'}
                </span>
              )}
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{
                rotate: selectedDropDown == 'date' ? '180deg' : '',
                top: selectedDropDown == 'date' ? '30%' : '',
              }}
              onClick={() => {
                if (selectedDropDown == 'date') {
                  setSelectedDropDown('');
                } else {
                  setSelectedDropDown('date');
                }
              }}
            />
          </div>
          <div
            className={classNames.dropDownDiv}
            style={{
              height: selectedDropDown == 'date' ? '' : '0',
              border: selectedDropDown == 'date' ? '' : '0',
              overflowY: selectedDropDown == 'date' ? 'scroll' : 'hidden',
            }}
          >
            {date &&
              date?.map((eachdate, i) => {
                return (
                  <div
                    onClick={(event) => {
                      setplanBAccountTargetDate({
                        ...planBAccountTargetDate,
                        date: event.target.innerText,
                      });
                      setSelectedDropDown('');
                    }}
                    key={'eachdate' + i}
                  >
                    {eachdate}
                  </div>
                );
              })}
          </div>
        </div>
        <div style={{ background: selectedDropDown == 'year' ? 'white' : '' }}>
          <div>
            <span onClick={() => setSelectedDropDown('year')}>
              {selectedDropDown == 'year' ? (
                <input
                  placeholder="Search year..."
                  onChange={(event) =>
                    searchDates(event.target.value, futureDates.years)
                  }
                />
              ) : (
                <span>
                  {planBAccountTargetDate?.year
                    ? planBAccountTargetDate.year
                    : 'Year'}
                </span>
              )}
            </span>
            <img
              src={selectDownArrow}
              alt="selectDownArrow"
              style={{
                rotate: selectedDropDown == 'year' ? '180deg' : '',
                top: selectedDropDown == 'year' ? '30%' : '',
              }}
              onClick={() => {
                if (selectedDropDown == 'year') {
                  setSelectedDropDown('');
                } else {
                  setSelectedDropDown('year');
                }
              }}
            />
          </div>
          <div
            className={classNames.dropDownDiv}
            style={{
              height: selectedDropDown == 'year' ? '' : '0',
              border: selectedDropDown == 'year' ? '' : '0',
              overflowY: selectedDropDown == 'year' ? 'scroll' : 'hidden',
            }}
          >
            {years &&
              years?.map((eachyear, i) => {
                return (
                  <div
                    onClick={(event) => {
                      setplanBAccountTargetDate({
                        ...planBAccountTargetDate,
                        year: event.target.innerText,
                      });
                      setSelectedDropDown('');
                    }}
                    key={'eachyear' + i}
                  >
                    {eachyear}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SelectOneDiv = ({ heading, options }) => {
  const {
    planBAccountContributor,
    setplanBAccountContributor,
    setSelectedDropDown,
  } = useContext(planBContext);

  return (
    <div
      className={classNames.selectOneDiv}
      onClick={() => setSelectedDropDown('')}
    >
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.selectoptionsDiv}>
        {options &&
          options.map((each, i) => {
            return (
              <div
                onClick={(event) =>
                  setplanBAccountContributor(event.target.innerText)
                }
                style={{
                  background: planBAccountContributor == each ? '#44C2F4' : '',
                  color: planBAccountContributor == each ? 'white' : '',
                }}
                key={'eachoption' + i}
              >
                {each}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const ImageUploadDiv = ({ heading }) => {
  const {
    planBAccountPic,
    setplanBAccountPic,
    planBAccountPicUploading,
    setplanBAccountPicUploading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div
      className={classNames.imageUploadDiv}
      onClick={() => setSelectedDropDown('')}
    >
      <div className={classNames.heading}>{heading}</div>
      <div className={classNames.imageDiv}>
        <img
          src={
            planBAccountPic
              ? planBAccountPic
              : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/87aa85500485decb550e820f4ffdba63'
          }
          alt="planBAccountPic"
          className={classNames.profileImg}
          htmlFor="profileUpdateImgPlanB"
        />
        {planBAccountPicUploading ? (
          <div className={classNames.overlayDiv}>
            <img
              src={uploading}
              alt="uploading"
              className={classNames.uploadingimg}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <label
        htmlFor="profileUpdateImgPlanB"
        className={classNames.uploadFileDiv}
      >
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          onChange={(e) => {
            uploadImageFunc(e, setplanBAccountPic, setplanBAccountPicUploading);
          }}
          accept="image/*"
          id="profileUpdateImgPlanB"
        />
        <div>
          {planBAccountPicUploading ? 'Uploading...' : 'Upload Picture'}
        </div>
      </label>
    </div>
  );
};
