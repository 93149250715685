import React, { createContext, useState, useEffect, useContext } from 'react';
import vaultIcon from '../assets/imported/images/logos/tokenHashIcon.svg';

export const GlobalContext = createContext();

function GlobalContextProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  // todo sidebar
  const [opencard, setopencard] = useState('translateX(-140%)');
  const [therightcard, settherightcard] = useState('translateX(-140%)');
  const [closecard, setclosecard] = useState('');
  const [pagemask, setpagemask] = useState('');
  const [closedisp, setclosedisp] = useState('none');
  const [closerightdisp, setcloserightdisp] = useState('none');
  const [icondisp, seticondisp] = useState('none');

  // ? circle data
  const [selectedbondCurrencies, setSelectedBondCurrencies] =
    useState('index0');
  const [cryptoCircleLoading, setCryptoCircleLoading] = useState(true);
  const [cryptoCircle, setCryptoCircle] = useState();
  const [forexCircle, setForexCircle] = useState();
  const [moneyMarkets4Containers, setmoneyMarkets4Containers] = useState();
  const [bondCurrencies, setbondCurrencies] = useState([]);
  const [bondQueries, setbondQueries] = useState();
  const [bondQueriesLoading, setbondQueriesLoading] = useState();
  const [shareTokensSide, setShareTokensSide] = useState();
  const [fundSide, setFundSide] = useState();

  const [productDropdown, setProductDropdown] = useState(false);

  const [currentPath, setCurrentPath] = useState('/');
  const [selectedBondsNav, setSelectedBondsNav] = useState('Stats');

  //imported things for bond
  const [userType, setUserType] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [email, setEmail] = useState(
    localStorage.getItem('nvestBankLoginAccount') || ''
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('nvestBankAccessToken') || ''
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem('nvestBankIdToken') || ''
  );
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');

  //imported
  const login = (paramEmail, paramAccessToken, paramIdToken) => {
    setEmail(paramEmail);
    setAdminEmail(paramEmail);
    setAccessToken(paramAccessToken);
    setIdToken(paramIdToken);
  };

  //imported toast context
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [subMessage, setSubMessage] = useState('');
  const [icon, setIcon] = useState(vaultIcon);
  function toastShowOn(message, submessage, icon) {
    setShow(true);
    setMessage(message);
    setSubMessage(submessage);
    setIcon(icon || vaultIcon);
    setTimeout(() => {
      setShow(false);
      setMessage('');
      setSubMessage('');
      setIcon('');
    }, 2000);
  }

  return (
    <GlobalContext.Provider
      value={{
        navOpen,
        setNavOpen,

        isVisible,
        setIsVisible,
        // indexfundsref,

        opencard,
        closecard,
        pagemask,
        therightcard,
        closedisp,
        closerightdisp,
        icondisp,
        setopencard,
        setclosecard,
        setpagemask,
        settherightcard,
        setclosedisp,
        setcloserightdisp,
        seticondisp,
        cryptoCircle,
        setCryptoCircle,
        forexCircle,
        setForexCircle,
        moneyMarkets4Containers,
        setmoneyMarkets4Containers,
        bondCurrencies,
        setbondCurrencies,
        cryptoCircleLoading,
        setCryptoCircleLoading,
        bondQueries,
        setbondQueries,
        selectedbondCurrencies,
        setSelectedBondCurrencies,
        bondQueriesLoading,
        setbondQueriesLoading,
        shareTokensSide,
        setShareTokensSide,
        fundSide,
        setFundSide,
        productDropdown,
        setProductDropdown,
        currentPath,
        setCurrentPath,

        //imported for bonds
        userType,
        setUserType,
        adminEmail,
        setAdminEmail,
        email,
        setEmail,
        accessToken,
        setAccessToken,
        idToken,
        setIdToken,
        login,
        username,
        name: name || username,
        toastShowOn,
        selectedBondsNav,
        setSelectedBondsNav,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
