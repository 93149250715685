import React, { useContext, useEffect } from 'react';
import classNames from './bplanner.module.scss';
import Layout from '../../Layout/Layout';
// import retiredIcon from '../../static/images/sidebar-icons/retired.svg';
import streamIcon from '../../static/images/sidebar-icons/stream.svg';
import useWindowDimensions from '../../utils/WindowSize';
import NewMobileLayout from '../../Layout/MobilePages/NewMobileLayout';
import axios from 'axios';
import { useState } from 'react';
import RetireContent from '../../components/Retire/RetireContent';
import RetireVideos from '../../components/Retire/RetireVideos';
import { PortfolioContext } from '../../context/PortfolioContext';
import EducationContainer from '../../components/React_Education';

function EducationPage() {
  const { width, height } = useWindowDimensions();
  const {
    youtubeApiData,
    setYoutubeApiData,
    youtubeApiVideoData,
    setYoutubeApiVideoData,
  } = useContext(PortfolioContext);

  return (
    <>
      {width > 768 || width > height ? (
        <Layout active="react" className={classNames.bplannerPage} hideFooter>
          <EducationContainer />
        </Layout>
      ) : (
        <NewMobileLayout />
      )}
    </>
  );
}

export default EducationPage;
