import React from 'react';
import { useCoinContextData } from '../../../context/CoinContext';
import BreadCrumb from '../../../Layout/LayoutSideBar/BreadCrumb';
import AddForexAction from './AddForexAction';
import { AddForexSuccess } from './AddForexSuccess';
import { BankDetails } from './BankDetails';
import { ConfirmQuote } from './ConfirmQuote';
import EnterAmountForTransfer from './EnterAmountForTransfer';

export const ConditionalForexComponents = () => {
  const {
    selectedTab,
    fundingCurrency,
    setFundingCurrency,
    setCurrentStep,
    setSelectedTab,
    payMethod,
    setPayMethod,
    otcDesk,
    setOtcDesk,
    setEnterAmount,
  } = useCoinContextData();

  const nextPage = () => {
    if (!fundingCurrency) {
      setFundingCurrency(true);
      setCurrentStep('step2');
      setSelectedTab('fundingCurrency');
    } else if (!payMethod) {
      setCurrentStep('step3');
      setPayMethod(true);
      setSelectedTab('payMethod');
    } else if (!otcDesk) {
      setCurrentStep('step4');
      setOtcDesk(true);
      setSelectedTab('otcDesk');
    } else {
      setCurrentStep('step5');
      setEnterAmount(true);
      setSelectedTab('enterAmount');
    }
  };

  switch (selectedTab) {
    case 'otcDesk':
      var ques = 'Select One Of The OTCDesks';
      return <AddForexAction ques={ques} nextPage={nextPage} />;
    case 'payMethod':
      var ques = 'How Are You Sending The Funds?';
      return <AddForexAction ques={ques} nextPage={nextPage} />;
    case 'fundingCurrency':
      var ques = 'Which Country Are The Funds Coming from?';
      return <AddForexAction ques={ques} nextPage={nextPage} />;
    case 'enterAmount':
      return <EnterAmountForTransfer />;
    case 'confirmQuote':
      return <ConfirmQuote />;
    case 'addSuccess':
      return <AddForexSuccess />;
    case 'accountDetails':
      return <BankDetails />;
    default:
      return <AddForexAction nextPage={nextPage} />;
  }
};

export const ForexBreadCrumbs = () => {
  const { currentStep, selectedCoin, setCoinAction } = useCoinContextData();
  const handleBreadCrumb = () => setCoinAction(['Menu']);

  switch (currentStep) {
    case 'step1':
      var data = [
        { value: selectedCoin.coinName + ' Actions >', clickable: 'step1' },
        { value: 'Add > ', clickable: 'step1' },
        { value: 'Sending Currency', clickable: 'step1' },
      ];
      return <BreadCrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step2':
      var data = [
        { value: 'Add > ', clickable: 'step2' },
        { value: 'Sending Currency > ', clickable: 'step2' },
        { value: 'Country', clickable: 'step2' },
      ];
      return <BreadCrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step3':
      var data = [
        { value: 'Funding Currency > ' + ' ', clickable: 'step3' },
        { value: 'Payment Method', clickable: 'step3' },
      ];
      return <BreadCrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step4':
      var data = [
        { value: 'Payment Method > ', clickable: 'step4' },
        { value: 'Select OTCDesk', clickable: 'step4' },
      ];
      return <BreadCrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step5':
      var data = [
        { value: 'Select OTCDesk > ', clickable: 'step5' },
        { value: 'Amount', clickable: 'step5' },
      ];
      return <BreadCrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step6':
      var data = [
        { value: 'Amount > ', clickable: 'step6' },
        { value: 'Confirm Quote', clickable: 'step6' },
      ];
      return <BreadCrumb data={data} handleBreadCrumb={handleBreadCrumb} />;
    case 'step7':
      return null;
    default:
      return;
  }
};
