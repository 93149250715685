import React, { useEffect, useState, useContext } from 'react';
import classNames from './vaultsPage.module.scss';
import Skeleton from 'react-loading-skeleton';
import { FormatCurrency } from '../../../../utils/FunctionTools';
import searchIcon from '../../../../static/images/search.svg';
import { BankContext } from '../../../../context/Context';
import { VaultPageContext } from '../../../../context/VaultPageContext';
import VaultSelectSharesModal from '../../../VaultSelectSharesModal';
import { useAppContextDetails } from '../../../../context/AppContext';

function VaultHeaderShares({
  email,
  vaultSelected,
  setVaultSelected,
  loading,
}) {
  const { coinListObject } = useContext(BankContext);
  const [openVaultModel, setOpenVaultModel] = useState(false);
  const {
    vaultsListShares: vaultsList,
    vaultsListLoadingShares: vaultsListLoading,
    blockContractSelected,
  } = useContext(VaultPageContext);
  useEffect(() => {
    try {
      if (vaultsList?.length > 0) {
        setVaultSelected(vaultsList[0]);
      }
    } catch (error) {}
  }, [vaultsList]);

  const { appCurrencySymbol } = useAppContextDetails();

  return (
    <>
      <div className={classNames.vaultHeader}>
        <div className={classNames.vaultNbalance}>
          <div className={classNames.vault}>
            {loading || vaultsListLoading ? (
              <Skeleton width={150} />
            ) : (
              <>
                USDT Earnings Vault For RSIP -{' '}
                <span style={{ fontWeight: '600' }}>
                  {blockContractSelected}
                </span>
              </>
            )}
          </div>
          <div className={classNames.balance}>
            {loading || vaultsListLoading ? (
              <Skeleton width={250} />
            ) : (
              <>
                {FormatCurrency(
                  vaultSelected?.tokens[0]?.value,
                  vaultSelected?.tokens[0]?.token
                )}{' '}
                <small>
                  {appCurrencySymbol}&nbsp;
                  {FormatCurrency(
                    (vaultSelected?.tokens[0]?.value_in_asset *
                      coinListObject[vaultSelected?.tokens[0]?.asset]
                        ?.coinPriceUSD) /
                      coinListObject?.[appCurrencySymbol]?.coinPriceUSD,
                    vaultSelected?.tokens[0]?.asset
                  )}
                </small>
              </>
            )}
          </div>
        </div>
        {/* <div className={classNames.coinSelect}>
          {loading || vaultsListLoading ? (
            <>
              <Skeleton
                className={`${classNames.coinWrap} ${classNames.false}`}
              />
              <Skeleton
                className={`${classNames.coinWrap} ${classNames.false}`}
              />
              <Skeleton
                className={`${classNames.coinWrap} ${classNames.false}`}
              />
            </>
          ) : (
            <>
              {vaultsList
                ?.slice(0, 3)
                .filter(
                  (vault) =>
                    vault?.tokens[0]?.token_profile_data?.coinSymbol ===
                    vaultSelected?.tokens[0]?.token_profile_data?.coinSymbol
                )[0] ? (
                ''
              ) : (
                <div className={`${classNames.coinWrap} ${classNames.true}`}>
                  <img
                    src={
                      vaultSelected?.tokens[0]?.token_profile_data?.coinImage
                    }
                    alt=""
                  />
                </div>
              )}
              {vaultsList?.slice(0, 3).map((vault) => (
                <div
                  key={vault.coinSymbol}
                  className={`${classNames.coinWrap} ${
                    classNames[
                      vault?.tokens[0]?.token_profile_data?.coinSymbol ===
                        vaultSelected?.tokens[0]?.token_profile_data?.coinSymbol
                    ]
                  }`}
                  onClick={() => {
                    setVaultSelected(vault);
                  }}
                >
                  <img
                    src={vault?.tokens[0]?.token_profile_data?.coinImage}
                    alt=""
                  />
                </div>
              ))}
            </>
          )}
          <div
            className={classNames.coinWrap}
            onClick={() => {
              setOpenVaultModel(true);
            }}
          >
            <img
              src={searchIcon}
              alt=""
              style={{
                width: 26,
                height: 26,
              }}
            />
          </div>
        </div> */}
      </div>
      {openVaultModel && (
        <VaultSelectSharesModal
          email={email}
          onSuccess={() => setOpenVaultModel(false)}
          onClose={() => setOpenVaultModel(false)}
          vaultSelected={vaultSelected}
          setVaultSelected={setVaultSelected}
        />
      )}
    </>
  );
}

export default VaultHeaderShares;
