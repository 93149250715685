import React from 'react';
import { useState } from 'react';
import classNames from './collapsedonly.module.scss';
import doubleDownArrow from '../../static/images/sidebar-icons/doubleDownArrow.svg';
import { BankContext } from '../../context/Context';
import { useContext } from 'react';
import guest from '../../static/images/guest.jpg';
import { Link, useHistory } from 'react-router-dom';
import { useMarketContext } from '../../context/MarketContext';
import searchIcon from '../../static/images/icons/search.svg';
import plusIcon from '../../static/images/icons/plus.svg';
import EnterPinUnlock, { otpRegex } from '../EnterPinUnlock/EnterPinUnlock';
import OtpInput from 'react-otp-input';
import './collapsedNormal.scss';
import { useEffect } from 'react';
import axios from 'axios';
import { planBContext } from '../../context/PlanBContext';
import moment from 'moment';

const CollapsedOnlySidebar = () => {
  const {
    email,
    username,
    name,
    profileImg,
    sidebarCollapse,
    selectedTopItem,
    setSelectedTopItem,
    progressPageRetirementAcc,
    startedDate,
    setStartedDate,
  } = useContext(BankContext);

  const { planBOpened, setPlanBOpened, icon, openingPlan, setOpeningPlan } =
    useMarketContext();

  const history = useHistory();
  console.log("openingPlan", openingPlan)
  return (
    <div className={classNames.collapsedonly}>
      <div
        className={classNames.collapsedheader}
        onClick={() => {
          setPlanBOpened(!planBOpened);
          setOpeningPlan('');
          history.push('/planb');
        }}
      // style={{
      //   display: window.location.pathname.includes('react') ? 'none' : '',
      // }}
      >
        <div>
          <img
            src={
              progressPageRetirementAcc?.length > 0
                ? progressPageRetirementAcc[0]?.icon
                : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/87aa85500485decb550e820f4ffdba63'
            }
            alt="profileImg"
          />
        </div>
        <div>
          <div>
            {progressPageRetirementAcc?.length > 0
              ? progressPageRetirementAcc[0]?.retirement_plan_name
              : ''}
          </div>
          <div>{`Started On  ${progressPageRetirementAcc?.length > 0 && startedDate
            ? startedDate
            : progressPageRetirementAcc?.length > 0 &&
              progressPageRetirementAcc[0]?.startingDate == null
              ? ''
              : ''
            }`}</div>
        </div>
      </div>
      <div className={classNames.collapsedbody}>
        <div className={classNames.collapsedbodytop}>
          <Link
            style={{
              background: selectedTopItem === 'Progress' ? '#44C2F4' : '',
              color: selectedTopItem === 'Progress' ? 'white' : '',
              fontWeight: selectedTopItem === 'Progress' ? '600' : '',
              paddingRight: selectedTopItem === 'Progress' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Progress' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            Progress
          </Link>
          <Link
            style={{
              background: selectedTopItem === 'Calendar' ? '#44C2F4' : '',
              color: selectedTopItem === 'Calendar' ? 'white' : '',
              fontWeight: selectedTopItem === 'Calendar' ? '600' : '',
              paddingRight: selectedTopItem === 'Calendar' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Calendar' ? '9%' : '',
              pointerEvents: 'none',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            Calendar
          </Link>
          <Link
            style={{
              background: selectedTopItem === 'Vision Board' ? '#44C2F4' : '',
              color: selectedTopItem === 'Vision Board' ? 'white' : '',
              fontWeight: selectedTopItem === 'Vision Board' ? '600' : '',
              paddingRight: selectedTopItem === 'Vision Board' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Vision Board' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            Vision Board
          </Link>
          <Link
            style={{
              background: selectedTopItem === 'RSIP’s' ? '#44C2F4' : '',
              color: selectedTopItem === 'RSIP’s' ? 'white' : '',
              fontWeight: selectedTopItem === 'RSIP’s' ? '600' : '',
              paddingRight: selectedTopItem === 'RSIP’s' ? '9%' : '',
              paddingLeft: selectedTopItem === 'RSIP’s' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            RSIP’s
          </Link>
          {/* <Link
            style={{
              background: selectedTopItem === 'RSP’s' ? '#44C2F4' : '',
              color: selectedTopItem === 'RSP’s' ? 'white' : '',
              fontWeight: selectedTopItem === 'RSP’s' ? '600' : '',
              pointerEvents: 'none',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            RSP’s
          </Link> */}
          <Link
            style={{
              background: selectedTopItem === 'Configurations' ? '#44C2F4' : '',
              color: selectedTopItem === 'Configurations' ? 'white' : '',
              fontWeight: selectedTopItem === 'Configurations' ? '600' : '',
              paddingRight: selectedTopItem === 'Configurations' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Configurations' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          // to={`/planb/ ${openingPlan}`}
          >
            Configurations
          </Link>
          <div className={classNames.line}></div>
          <div className={classNames.arecmendation}>Advisor Recommendation</div>
          <Link
            style={{
              background: selectedTopItem === 'Proposals' ? '#44C2F4' : '',
              color: selectedTopItem === 'Proposals' ? 'white' : '',
              fontWeight: selectedTopItem === 'Proposals' ? '600' : '',
              paddingRight: selectedTopItem === 'Proposals' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Proposals' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            //  to={`/planb/ ${openingPlan}/proposals`}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            Proposals
          </Link>
          <Link
            style={{
              background: selectedTopItem === 'Markets' ? '#44C2F4' : '',
              color: selectedTopItem === 'Markets' ? 'white' : '',
              fontWeight: selectedTopItem === 'Markets' ? '600' : '',
              paddingRight: selectedTopItem === 'Markets' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Markets' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            //  to={`/planb/ ${openingPlan}/proposals`}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            Markets
          </Link>
          <Link
            style={{
              background: selectedTopItem === 'Support' ? '#44C2F4' : '',
              color: selectedTopItem === 'Support' ? 'white' : '',
              fontWeight: selectedTopItem === 'Support' ? '600' : '',
              paddingRight: selectedTopItem === 'Support' ? '9%' : '',
              paddingLeft: selectedTopItem === 'Support' ? '9%' : '',
            }}
            onClick={(event) => setSelectedTopItem(event.target.innerText)}
            //  to={`/planb/ ${openingPlan}/Support`}
            to={'/planb/' + openingPlan ? openingPlan : ''}
          >
            Support
          </Link>

        </div>
        <div className={classNames.collapsedbodybottom}></div>


      </div>
    </div>
  );
};

export default CollapsedOnlySidebar;

export const PlanB = () => {
  const {
    token,
    email,
    username,
    name,
    profileImg,
    sidebarCollapse,
    selectedTopItem,
    setSelectedTopItem,
    planBAccountDetails,
    setPlanBAccountDetails,
  } = useContext(BankContext);

  const {
    planBOpened,
    setPlanBOpened,
    buyBlocks,
    setBuyBlocks,
    globalappIcon,
    setGlobalAppIcon,
    globalappColor,
    setGlobalAppColor,
    profileUpdateSidebar,
    setProfileUpdateSidebar,
    icon,
    setIcon,
    iconLoading,
    setIconLoading,
  } = useMarketContext();

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/retired/user/get/userprofiles?email=${email}`
      )
      .then((response) => {
        if (response?.data?.data[0]?.profilePic != 'test') {
          setIcon(response?.data?.data[0]?.profilePic);
        }

        localStorage.setItem('plaBAccount', response.data.status);
        // console.log(response.data, 'checking user exist');
        if (!response.data.status) {
          setProfileUpdateSidebar(!profileUpdateSidebar);
        }

        setPlanBAccountDetails(response.data);
      });
  }, []);

  return (
    <div
      className={classNames.planBContainer}
      style={{
        opacity: iconLoading ? '0.5' : '',
        pointerEvents: iconLoading ? 'none' : '',
      }}
    >
      {planBAccountDetails?.status || localStorage.getItem('plaBAccount') ? (
        <>
          <div className={classNames.header}>
            <div className={classNames.profile}>
              <div>
                <img
                  src={
                    icon
                      ? icon
                      : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/87aa85500485decb550e820f4ffdba63'
                  }
                  alt="profileImg"
                />
              </div>
              <div>
                <div>
                  {planBAccountDetails && planBAccountDetails.data[0]
                    ? planBAccountDetails.data[0]?.firstName
                    : username}
                </div>
                <div
                  onClick={() => setProfileUpdateSidebar(!profileUpdateSidebar)}
                >
                  Edit Profile
                </div>
              </div>
            </div>
            <div className={classNames.search}>
              <input type="text" placeholder="Search PlanB’s" />
              <div>
                <img src={searchIcon} alt="searchIcon" />
              </div>
            </div>
          </div>
          <div className={classNames.content}>
            <PlanBDrawerItem content="content" />
            <PlanBDrawerItem />
            <PlanBDrawerItem />
          </div>
        </>
      ) : (
        <div className={classNames.noAccount}>
          <div onClick={() => setProfileUpdateSidebar(!profileUpdateSidebar)}>
            <div>Create Your Retirement Profile</div>
            <div className={classNames.arrowDiv}>
              <ArrowComponent delay={'0s'} />
              <ArrowComponent delay={'0.2s'} />
              <ArrowComponent delay={'0.4s'} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const Pincard = () => {
  const {
    globalappIcon,
    setGlobalAppIcon,
    globalappColor,
    setGlobalAppColor,
    pin,
    setPin,
  } = useMarketContext();

  const [loading, setLoading] = useState(false);

  const pinValidator = (pinStr) => {
    if (otpRegex.test(pinStr)) setPin(pinStr);
  };

  useEffect(() => {
    if (pin == '4141') {
      let pinValidate = document.querySelector('#pinValidate');
    }
  }, [pin]);

  return (
    <div className="pinCard">
      <div className="imageDiv">
        {globalappIcon ? (
          <img src={globalappIcon} alt="" className="logo" />
        ) : (
          ''
        )}
      </div>
      <OtpInput
        containerStyle="otp-input-wrapper"
        value={pin}
        onChange={(otp) => pinValidator(otp)}
        numInputs={4}
        separator={<span> </span>}
        inputStyle="otp-input"
      />
      <Link className="closeDiv" to="vault" onClick={() => setPin('')}>
        Close
      </Link>
    </div>
  );
};

export const PlanBDrawerItem = ({ content }) => {
  const {
    email,
    username,
    name,
    profileImg,
    sidebarCollapse,
    selectedTopItem,
    setSelectedTopItem,
    planBAccountDetails,
  } = useContext(BankContext);

  const { setplanBCreate, setinsideplanBCreate } = useContext(planBContext);

  const { planBOpened, setPlanBOpened, icon } = useMarketContext();

  return (
    <div className={classNames.drawerItem}>
      {content ? (
        <div>
          <div
            className={classNames.content}
            onClick={() => {
              setPlanBOpened(!planBOpened);
            }}
          >
            <div>
              <img
                src={
                  icon
                    ? icon
                    : 'https://drivetest.globalxchange.io/gxsharepublic/?full_link=bets.brain.stream/87aa85500485decb550e820f4ffdba63'
                }
                alt="profileImg"
              />
            </div>
            <div>
              <div>
                {planBAccountDetails && planBAccountDetails.data[0]
                  ? planBAccountDetails.data[0]?.firstName
                  : username}
              </div>
              <div>Edit Profile</div>
            </div>
          </div>
          <div className={classNames.openPlanBtn}>Open Plan</div>
        </div>
      ) : (
        <div className={classNames.addPlan}>
          <img
            src={plusIcon}
            alt="plusIcon"
            onClick={() => {
              setplanBCreate(true);
              setinsideplanBCreate('createplanb');
            }}
          />
          <div>Create New PlanB</div>
        </div>
      )}
    </div>
  );
};

function ArrowComponent({ delay }) {
  return (
    <svg
      // width="22"
      // height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clip-path="url(#clip0_1_88)"
        className={classNames.dottedarrowgroup}
        style={{ animationDelay: delay }}
      >
        <rect width="22" height="21" fill="white" />
        <path d="M1.18217 2.09285C1.76009 2.09285 2.22859 1.62435 2.22859 1.04643C2.22859 0.468501 1.76009 0 1.18217 0C0.604243 0 0.135742 0.468501 0.135742 1.04643C0.135742 1.62435 0.604243 2.09285 1.18217 2.09285Z" />
        <path d="M4.28971 5.2638C4.86764 5.2638 5.33614 4.79529 5.33614 4.21737C5.33614 3.63944 4.86764 3.17094 4.28971 3.17094C3.71179 3.17094 3.24329 3.63944 3.24329 4.21737C3.24329 4.79529 3.71179 5.2638 4.28971 5.2638Z" />
        <path d="M4.28971 2.09285C4.86764 2.09285 5.33614 1.62435 5.33614 1.04643C5.33614 0.468501 4.86764 0 4.28971 0C3.71179 0 3.24329 0.468501 3.24329 1.04643C3.24329 1.62435 3.71179 2.09285 4.28971 2.09285Z" />
        <path d="M7.46085 5.2638C8.03878 5.2638 8.50728 4.79529 8.50728 4.21737C8.50728 3.63944 8.03878 3.17094 7.46085 3.17094C6.88293 3.17094 6.41443 3.63944 6.41443 4.21737C6.41443 4.79529 6.88293 5.2638 7.46085 5.2638Z" />
        <path d="M7.46085 8.3714C8.03878 8.3714 8.50728 7.9029 8.50728 7.32497C8.50728 6.74705 8.03878 6.27855 7.46085 6.27855C6.88293 6.27855 6.41443 6.74705 6.41443 7.32497C6.41443 7.9029 6.88293 8.3714 7.46085 8.3714Z" />
        <path d="M7.46085 2.09285C8.03878 2.09285 8.50728 1.62435 8.50728 1.04643C8.50728 0.468501 8.03878 0 7.46085 0C6.88293 0 6.41443 0.468501 6.41443 1.04643C6.41443 1.62435 6.88293 2.09285 7.46085 2.09285Z" />
        <path d="M10.5684 5.2638C11.1463 5.2638 11.6148 4.79529 11.6148 4.21737C11.6148 3.63944 11.1463 3.17094 10.5684 3.17094C9.99047 3.17094 9.52197 3.63944 9.52197 4.21737C9.52197 4.79529 9.99047 5.2638 10.5684 5.2638Z" />
        <path d="M10.5684 8.3714C11.1463 8.3714 11.6148 7.9029 11.6148 7.32497C11.6148 6.74705 11.1463 6.27855 10.5684 6.27855C9.99047 6.27855 9.52197 6.74705 9.52197 7.32497C9.52197 7.9029 9.99047 8.3714 10.5684 8.3714Z" />
        <path d="M10.5684 2.09285C11.1463 2.09285 11.6148 1.62435 11.6148 1.04643C11.6148 0.468501 11.1463 0 10.5684 0C9.99047 0 9.52197 0.468501 9.52197 1.04643C9.52197 1.62435 9.99047 2.09285 10.5684 2.09285Z" />
        <path d="M13.6759 5.2638C14.2539 5.2638 14.7224 4.79529 14.7224 4.21737C14.7224 3.63944 14.2539 3.17094 13.6759 3.17094C13.098 3.17094 12.6295 3.63944 12.6295 4.21737C12.6295 4.79529 13.098 5.2638 13.6759 5.2638Z" />
        <path d="M13.6759 8.3714C14.2539 8.3714 14.7224 7.9029 14.7224 7.32497C14.7224 6.74705 14.2539 6.27855 13.6759 6.27855C13.098 6.27855 12.6295 6.74705 12.6295 7.32497C12.6295 7.9029 13.098 8.3714 13.6759 8.3714Z" />
        <path d="M10.5684 11.4791C11.1463 11.4791 11.6148 11.0106 11.6148 10.4326C11.6148 9.8547 11.1463 9.3862 10.5684 9.3862C9.99047 9.3862 9.52197 9.8547 9.52197 10.4326C9.52197 11.0106 9.99047 11.4791 10.5684 11.4791Z" />
        <path d="M13.6759 11.4791C14.2539 11.4791 14.7224 11.0106 14.7224 10.4326C14.7224 9.8547 14.2539 9.3862 13.6759 9.3862C13.098 9.3862 12.6295 9.8547 12.6295 10.4326C12.6295 11.0106 13.098 11.4791 13.6759 11.4791Z" />
        <path d="M7.4608 14.65C8.03877 14.65 8.5073 14.1815 8.5073 13.6035C8.5073 13.0256 8.03877 12.557 7.4608 12.557C6.88284 12.557 6.41431 13.0256 6.41431 13.6035C6.41431 14.1815 6.88284 14.65 7.4608 14.65Z" />
        <path d="M10.5683 14.65C11.1463 14.65 11.6148 14.1815 11.6148 13.6035C11.6148 13.0256 11.1463 12.557 10.5683 12.557C9.99038 12.557 9.52185 13.0256 9.52185 13.6035C9.52185 14.1815 9.99038 14.65 10.5683 14.65Z" />
        <path d="M13.676 14.65C14.254 14.65 14.7225 14.1815 14.7225 13.6035C14.7225 13.0256 14.254 12.557 13.676 12.557C13.098 12.557 12.6295 13.0256 12.6295 13.6035C12.6295 14.1815 13.098 14.65 13.676 14.65Z" />
        <path d="M4.28971 17.7615C4.86764 17.7615 5.33614 17.293 5.33614 16.7151C5.33614 16.1372 4.86764 15.6687 4.28971 15.6687C3.71179 15.6687 3.24329 16.1372 3.24329 16.7151C3.24329 17.293 3.71179 17.7615 4.28971 17.7615Z" />
        <path d="M7.46085 17.7615C8.03878 17.7615 8.50728 17.293 8.50728 16.7151C8.50728 16.1372 8.03878 15.6687 7.46085 15.6687C6.88293 15.6687 6.41443 16.1372 6.41443 16.7151C6.41443 17.293 6.88293 17.7615 7.46085 17.7615Z" />
        <path d="M10.5684 17.7615C11.1463 17.7615 11.6148 17.293 11.6148 16.7151C11.6148 16.1372 11.1463 15.6687 10.5684 15.6687C9.99047 15.6687 9.52197 16.1372 9.52197 16.7151C9.52197 17.293 9.99047 17.7615 10.5684 17.7615Z" />
        <path d="M13.6759 17.7615C14.2539 17.7615 14.7224 17.293 14.7224 16.7151C14.7224 16.1372 14.2539 15.6687 13.6759 15.6687C13.098 15.6687 12.6295 16.1372 12.6295 16.7151C12.6295 17.293 13.098 17.7615 13.6759 17.7615Z" />
        <path d="M16.9143 8.3714C17.4923 8.3714 17.9608 7.9029 17.9608 7.32497C17.9608 6.74705 17.4923 6.27855 16.9143 6.27855C16.3364 6.27855 15.8679 6.74705 15.8679 7.32497C15.8679 7.9029 16.3364 8.3714 16.9143 8.3714Z" />
        <path d="M16.9143 11.4791C17.4923 11.4791 17.9608 11.0106 17.9608 10.4326C17.9608 9.8547 17.4923 9.3862 16.9143 9.3862C16.3364 9.3862 15.8679 9.8547 15.8679 10.4326C15.8679 11.0106 16.3364 11.4791 16.9143 11.4791Z" />
        <path d="M16.9144 14.65C17.4924 14.65 17.9609 14.1815 17.9609 13.6035C17.9609 13.0256 17.4924 12.557 16.9144 12.557C16.3365 12.557 15.8679 13.0256 15.8679 13.6035C15.8679 14.1815 16.3365 14.65 16.9144 14.65Z" />
        <path d="M1.18212 21C1.76008 21 2.22861 20.5315 2.22861 19.9535C2.22861 19.3755 1.76008 18.907 1.18212 18.907C0.604152 18.907 0.13562 19.3755 0.13562 19.9535C0.13562 20.5315 0.604152 21 1.18212 21Z" />
        <path d="M4.28978 21C4.86775 21 5.33628 20.5315 5.33628 19.9535C5.33628 19.3755 4.86775 18.907 4.28978 18.907C3.71182 18.907 3.24329 19.3755 3.24329 19.9535C3.24329 20.5315 3.71182 21 4.28978 21Z" />
        <path d="M7.4608 21C8.03877 21 8.5073 20.5315 8.5073 19.9535C8.5073 19.3755 8.03877 18.907 7.4608 18.907C6.88284 18.907 6.41431 19.3755 6.41431 19.9535C6.41431 20.5315 6.88284 21 7.4608 21Z" />
        <path d="M10.5683 21C11.1463 21 11.6148 20.5315 11.6148 19.9535C11.6148 19.3755 11.1463 18.907 10.5683 18.907C9.99038 18.907 9.52185 19.3755 9.52185 19.9535C9.52185 20.5315 9.99038 21 10.5683 21Z" />
        <path d="M20.0259 11.4791C20.6038 11.4791 21.0723 11.0106 21.0723 10.4326C21.0723 9.8547 20.6038 9.3862 20.0259 9.3862C19.448 9.3862 18.9795 9.8547 18.9795 10.4326C18.9795 11.0106 19.448 11.4791 20.0259 11.4791Z" />
      </g>
      <defs>
        <clipPath id="clip0_1_88">
          <rect width="22" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
