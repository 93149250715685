import axios from 'axios';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';

const BuyBondStepTwo = ({ bondCount, setBondCount, setPayCoin }) => {
  const { email, token, setSidebarCollapse } = useContext(BankContext);
  const {
    ownerEmail,
    appColorCode,
    setActionFlow,
    selectedBond,
    setSelectedBond,
    selectedCurrency,
    appCode,
  } = useAppContextDetails();

  const [amount, setAmount] = useState(0);
  const [allVaults, setAllVaults] = useState([]);
  const [filteredVaults, setFilteredVaults] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    axios
      .post(`https://comms.globalxchange.io/gxb/apps/register/user`, {
        email: email,
        app_code: 'ice',
      })
      .then((res) => {
        console.log(res.data);
      });
    axios
      .post(`https://comms.globalxchange.io/coin/vault/service/coins/get`, {
        app_code: appCode,
        email: email,
        with_balances: 'true',
        orderby_dsc: true,
      })
      .then(({ data }) => {
        setAllVaults(data.coins_data);
        setFilteredVaults(data.coins_data);
      });
  }, []);

  useEffect(() => {
    const tempArr = allVaults.filter(
      (item) =>
        item?.coinSymbol?.toLowerCase()?.includes(query.toLowerCase()) ||
        item?.coinName?.toLowerCase()?.includes(query.toLowerCase())
    );

    setFilteredVaults([...tempArr]);
  }, [query]);

  useEffect(() => {
    if (bondCount > 0) {
      const tempAmount =
        Number(bondCount) * Number(selectedBond.coinsData[0]?.bondCost);
      setAmount(tempAmount);
    }
  }, [bondCount]);

  return (
    <>
      <div>
        <div style={{ background: appColorCode, height: '15vh' }}>&nbsp;</div>
        <div className="imgWrapper">
          <div className="imgCointainer">
            <img
              src={selectedBond?.bankerData?.profilePicURL}
              alt=""
              style={{ width: '60px', height: '60px' }}
            />
          </div>
        </div>
        <div
          style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}
        >
          {selectedBond?.bankerData?.displayName} Bond
        </div>
        <div style={{ textAlign: 'center' }}>
          <span onClick={(e) => setActionFlow('BuyBondStepOne')}>Amount</span>{' '}
          {`>`} <span style={{ fontWeight: 'bold' }}>Payment Assets</span>
        </div>
        <div className="dataContainer">
          <div>
            <div
              style={{
                paddingTop: '30px',
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              Step 2: Payment Assets
            </div>
          </div>

          <div>
            <input
              className="inputWrapper2"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />

            <div className="vaultsWrapper">
              {filteredVaults.length > 0 ? (
                filteredVaults.map((item) => {
                  return (
                    <div
                      onClick={(e) => {
                        setPayCoin(item);
                        setActionFlow('BuyBondStepThree');
                      }}
                      // onPress={(e) =>
                      //   navigate('StepThree', {
                      //     bankerdata: bankerdata,
                      //     bondCount: bondCount,
                      //     appData: appData,
                      //     payCoin: item,
                      //   })
                      // }
                    >
                      <div className="vault">
                        <img
                          src={item.coinImage}
                          style={{ width: '45px', height: '45px' }}
                        />
                        <div className="vaultName">{item.coinSymbol}</div>
                        <div className="vaultValue">
                          {item.coinValue.toFixed(6)}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="vaultsWrapper">
                  {Array(6)
                    .fill(0)
                    .map((_, index) => (
                      <div className="vault" style={{ height: '110px' }}>
                        <Skeleton
                          height={45}
                          width={45}
                          className="cryptoIcon"
                        />
                        {/* <Skeleton
                          height={45}
                          width={45}
                          className="cryptoIcon"
                        />
                        <Skeleton
                          height={45}
                          width={45}
                          className="cryptoIcon"
                        /> */}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className="doubleButton">
            {/* <div
              className="submitButton"
              style={{ background: 'white' }}
              //   onClick={(e) => setActionFlow('AddForexStepFour')}
            >
              <div style={{ fontWeight: 'bold' }}>Back</div>
            </div> */}
            <div
              className="submitButton"
              style={{ background: appColorCode, width: '100%' }}
              onClick={(e) => setActionFlow('BuyBondStepOne')}
              //   onClick={confirmQuoteFunction}
            >
              <div style={{ color: 'white', fontWeight: 'bold' }}>Back</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBondStepTwo;
