import axios from 'axios';
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { planBContext } from '../../../context/PlanBContext';
import classNames from './progresscomponents.module.scss';
import moment from 'moment';

const OverallBar = () => {
  let { retiredid } = useParams();

  const { overallProgressBar, setoverallProgressBar, blockData, setblockData } =
    useContext(planBContext);

  useEffect(() => {
    getOverallData();
    getBlockData();
  }, []);

  function getOverallData() {
    axios
      .get(
        `https://comms.globalxchange.io/retired/block/get/All/retirementplans?retirement_plan_id=${retiredid}`
      )
      .then((response) => {
        console.log(response?.data, 'responseee overall');

        setoverallProgressBar(response?.data);
      });
  }

  function getBlockData() {
    axios
      .get(
        `https://comms.globalxchange.io/retired/contract/get/all/blockcontracts?retirement_plan_id=${retiredid}`
      )
      .then((response) => {
        // console.log(response?.data, 'response from bonds');
        setblockData(response?.data);
      });
  }

  return (
    <div className={classNames.overallBar}>
      <div className={classNames.headDiv}>
        <div>
          <div className={classNames.title}>Financial Progress</div>
          <div className={classNames.description}>
            Representing the percentage of the total PlanB cost
          </div>
        </div>
        <div className={classNames.completedOverall}>
          <div>
            {overallProgressBar?.totalBlockFinishedAmountPercentage
              ? overallProgressBar?.totalBlockFinishedAmountPercentage + '%'
              : '0%'}
          </div>
          <div>Completed</div>
        </div>
      </div>
      <div className={classNames.barDiv}>
        <div className={classNames.wholeBar}>
          <div
            className={classNames.completedPerc}
            style={{
              width: overallProgressBar
                ? overallProgressBar?.totalBlockFinishedAmountPercentage + '%'
                : '',
            }}
          ></div>
        </div>
        <div className={classNames.contentDiv}>
          <div>
            {overallProgressBar?.data?.length > 0 &&
            overallProgressBar?.data[0].startingDate
              ? moment(overallProgressBar?.data[0].startingDate).format(
                  'MMM Do [20]YY'
                )
              : ''}
          </div>
          <div>
            <div>Completion</div>
            <div className={classNames.totalAmount}>
              {overallProgressBar?.data?.length > 0 &&
              overallProgressBar?.data[0]?.CurrencyForTheAmount
                ? overallProgressBar?.data[0]?.amountToAccomplish +
                  ' ' +
                  overallProgressBar?.data[0]?.CurrencyForTheAmount
                : overallProgressBar?.data?.length > 0
                ? overallProgressBar?.data[0]?.amountToAccomplish
                : ''}
            </div>
          </div>
          <div
            className={classNames.currentPerc}
            style={{
              left: overallProgressBar?.totalBlockFinishedAmountPercentage
                ? overallProgressBar?.totalBlockFinishedAmountPercentage -
                  3 +
                  '%'
                : '0%',
            }}
          >
            <div>Today</div>
            <div>
              {blockData &&
              overallProgressBar?.data?.length > 0 &&
              overallProgressBar?.data[0]?.CurrencyForTheAmount
                ? blockData?.overallTotalBlockValue +
                  ' ' +
                  overallProgressBar?.data[0]?.CurrencyForTheAmount
                : blockData && overallProgressBar?.data?.length > 0
                ? blockData?.overallTotalBlockValue
                : ''}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.detailsDiv}>
        <div>
          <div>Remaining Amount:</div>
          <div>
            {overallProgressBar?.totalBlockRemainingAmount &&
            overallProgressBar?.data?.length > 0 &&
            overallProgressBar?.data[0]?.CurrencyForTheAmount
              ? overallProgressBar?.totalBlockRemainingAmount +
                ' ' +
                overallProgressBar?.data[0]?.CurrencyForTheAmount
              : overallProgressBar?.totalBlockRemainingAmount
              ? overallProgressBar?.totalBlockRemainingAmount
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverallBar;
