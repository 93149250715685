import React, { useContext } from 'react';
import VaultFab from '../VaultFab';
import { ASSET_CLASSES } from '../../../config/constants';
import { VaultPageContext } from '../../../context/VaultPageContext';
import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import { VaultContents } from './VaultContents';
import { useCoinContextData } from '../../../context/CoinContext';
import { useHistory } from 'react-router-dom';
import searchIcon from '../../../static/images/search.svg';
import vault from '../../../static/images/vaults/fulllogo.svg';
import ddown from '../../../static/images/malls/ddown.svg';
import { NetWorthContext } from '../../../context/NetWorthContext';

export const VaultComponent = () => {
  const { assetClass, setAssetClass, vaultSelected, trackerData } =
    useContext(VaultPageContext);
  const history = useHistory();
  const { coinType, setCoinType } = useCoinContextData();
  const { netWorth, liquidCrypto, liquidFiat } = useContext(NetWorthContext);

  return (
    <>
      {/* <div className={classNames.assetClassWrap}>
        <div className={classNames.assetClass}>
          {ASSET_CLASSES.map((assetClassCard, i) => (
            <div
              key={`${assetClassCard.name}-${i}`}
              className={`${classNames.assetClassCard} ${
                classNames[assetClassCard === assetClass]
              } ${classNames}`}
              onClick={() => {
                if (assetClassCard.name) {
                  setAssetClass(assetClassCard);
                }
              }}
            >
              <img
                src={assetClassCard.icon}
                alt=""
                className={classNames.icon}
              />
            </div>
          ))}
        </div>
      </div> */}
      <div className={classNames.vaultssnavbar}>
        <div className={classNames.vaultssicon}>
          <img src={vault} alt="vault" />
        </div>
        <div className={classNames.searchvaultss}>
          {/* <div className={classNames.all}>
            <p>All Assets</p>
            <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
              <img src={ddown} alt="" />
            </div>
          </div> */}
          <div className={classNames.im}>
            <input
              type="text"
              placeholder="Ex. Bitcoin..."
              // onChange={(event) => filterItem(event.target.value)}
              // onChange={(event) => {
              //   setSearchedValue(event.target.value);
              // }}
            />
            <div className={classNames.arroww} style={{ cursor: 'pointer' }}>
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
        <div className={classNames.righttop}>
          <div
            style={{ borderRight: '1px solid #e7e7e7', paddingRight: '3rem' }}
            className={classNames.divv}
          >
            <div>
              <p style={{ opacity: '0.25' }}>
                {coinType === 'crypto'
                  ? 'Liquid Crypto'
                  : coinType === 'tracker'
                  ? 'Deposits'
                  : coinType === 'fiat'
                  ? 'Liquid Forex'
                  : 'Liquid Holdings'}
              </p>
              <p style={{ fontWeight: '700' }}>
                {trackerData?.perAppCode?.length > 0 && coinType === 'tracker'
                  ? trackerData?.perAppCode[0]?.count
                  : coinType === 'tracker'
                  ? '0'
                  : coinType === 'crypto'
                  ? `$${liquidCrypto ? liquidCrypto : '0.00'} USD`
                  : coinType === 'fiat'
                  ? `$${liquidFiat ? liquidFiat : '0.00'} USD`
                  : '$0.00 USD'}
              </p>
            </div>
          </div>
          <div style={{ paddingLeft: '2rem' }} className={classNames.divv}>
            <div>
              <p style={{ opacity: '0.25' }}>
                {coinType === 'tracker' ? 'Withdrawals' : 'Total Net-Worth'}
              </p>
              <p style={{ fontWeight: '700' }}>
                {coinType === 'tracker'
                  ? '0'
                  : `$${netWorth ? netWorth : '0.00'} USD`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.wspace}>
        <div className={classNames.optionss} style={{ width: '100%' }}>
          <div className={classNames.optionssDivider}>
            <div
              className={`${classNames.eachOption} ${
                coinType === 'crypto' ? classNames.eachOptionSelected : ''
              }`}
              onClick={() => {
                history.push('/vault');
                setCoinType('crypto');
              }}
            >
              <div>
                <p>Crypto</p>
              </div>
            </div>
            <div
              className={`${classNames.eachOption} ${
                coinType === 'fiat' ? classNames.eachOptionSelected : ''
              }`}
              onClick={() => {
                history.push('/vault');
                setCoinType('fiat');
              }}
            >
              <div>
                <p>Forex</p>
              </div>
            </div>
            <div
              className={`${classNames.eachOption} ${
                coinType === 'bonds' ? classNames.eachOptionSelected : ''
              }`}
              onClick={() => {
                history.push('/vault');
                setCoinType('bonds');
              }}
            >
              <div>
                <p>Staking</p>
              </div>
            </div>
            <div
              className={`${classNames.eachOption} ${
                coinType === 'moneyMarkets' ? classNames.eachOptionSelected : ''
              }`}
              onClick={() => {
                history.push('/vault');
                setCoinType('moneyMarkets');
              }}
            >
              <div>
                <p>MoneyMarkets</p>
              </div>
            </div>
            <div
              className={`${classNames.eachOption} ${
                coinType === 'RSIPs' ? classNames.eachOptionSelected : ''
              }`}
              // style={{
              //   fontWeight: coinType === 'fiat' ? '650' : '300',
              // }}
              onClick={() => {
                history.push('/vault');
                setCoinType('RSIPs');
              }}
            >
              <div>
                <p>RSIP’s</p>
              </div>
            </div>
            <div
              className={`${classNames.eachOption} ${
                coinType === 'RSPs' ? classNames.eachOptionSelected : ''
              }`}
              // style={{
              //   fontWeight: coinType === 'fiat' ? '650' : '300',
              // }}
              onClick={() => {
                history.push('/vault');
                setCoinType('RSPs');
              }}
            >
              <div>
                <p>RSP’s</p>
              </div>
            </div>
          </div>
          <div className={classNames.lastoption}></div>
        </div>
      </div>
      <VaultContents />
      <VaultFab vaultSelected={vaultSelected} />
    </>
  );
};
