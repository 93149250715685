import React from 'react';
import './functions.scss';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useState } from 'react';

const QuestionsDiv = ({
  title,
  content,
  index,
  toggleSelected,
  toggleSelectedFunc,
}) => {
  return (
    <div className="questionsDiv">
      <div>
        <span>{title}</span>
        <div>
          {toggleSelected == index ? (
            <AiFillCaretUp
              onClick={() => {
                // console.log('its working');
                toggleSelectedFunc(index);
              }}
            />
          ) : (
            <AiFillCaretDown
              onClick={() => {
                // console.log('its working');
                toggleSelectedFunc(index);
              }}
            />
          )}
        </div>
      </div>
      <span
        className="content"
        style={{
          maxHeight: toggleSelected == index ? '9999px' : '0',
          paddingBottom: toggleSelected == index ? '1.5rem' : '',
        }}
      >
        {content}
      </span>
    </div>
  );
};

export default QuestionsDiv;
