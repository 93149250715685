import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const ForexAddSkeletons = () =>
  [1, 2, 3, 4, 5].map(({}, i) => (
    <div key={i} className="each-card">
      <div className="eachapp">
        <div style={{ marginLeft: '25px' }}>
          <Skeleton width={40} height={40} borderRadius={50} />
        </div>
        <Skeleton width={150} height={30} style={{ marginLeft: '15px' }} />
      </div>
    </div>
  ));
