import React, { useRef, useContext, useState, createRef } from 'react';
import classNames from '../../../../pages/VaultsPage/vaultsPage.module.scss';
import { VaultPageContext } from '../../../../context/VaultPageContext';
import moment from 'moment';
import {
  FormatCurrency,
  YesterdayToday,
} from '../../../../utils/FunctionTools';
import credit from '../../../../static/images/txnIcons/credit.svg';
import debit from '../../../../static/images/txnIcons/debit.svg';
import chat from '../../../../static/images/txnIcons/chat.svg';
import edit from '../../../../static/images/txnIcons/edit.svg';
import txnHash from '../../../../static/images/txnIcons/txnHash.svg';
import OnOutsideClick from '../../../../utils/OnOutsideClick';
import TransactionExpand from '../../../VaultsPage/VaultComponent/TransactionExpand';

function VaultItemRSIP({ txn }) {
  const { assetClass } = useContext(VaultPageContext);
  const [txnOpen, setTxnOpen] = useState(false);

  const elRefs = useRef([]);
  if (elRefs.current.length !== 3) {
    // add or remove refs
    elRefs.current = Array(3)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      setTxnOpen((txnOpen) => !txnOpen);
    },
    refOut,
    elRefs
  );

  return (
    <div
      className={`${classNames.vaultItmWrap} ${classNames[txnOpen]}`}
      ref={refOut}
    >
      <div
        className={classNames.vaultsItm}
        style={{ width: '100%', justifyContent: 'space-between' }}
      >
        <img
          src={txn?.deposit ? debit : credit}
          alt=""
          className={classNames.img}
        />
        <div
          className={classNames.nameDate}
          style={{
            flex: '0 0 calc(30% - 80px)',
            maxWidth: 'calc(30% - 80px)',
          }}
        >
          <div className={classNames.name}>
            {txn?.deposit ? 'RSIP Interest Payment' : 'Withdrawal'}
          </div>
          <div className={classNames.date}>
            {moment(txn?.date).format('MMMM Do YYYY [at] h:mm:ss A zz')}
          </div>
        </div>
        {/* <div className={classNames.buttons} ref={elRefs.current[0]}>
          <div className={classNames.btnNotes}>
            <span>Notes</span>
          </div>
          <div className={classNames.btnEdit}>
            <img src={edit} alt="" className={classNames.btnIcon} />
          </div>
          <div className={classNames.btnTxn}>
            <img src={txnHash} alt="" className={classNames.btnIcon} />
          </div>
          <div className={classNames.btnChat}>
            <img src={chat} alt="" className={classNames.btnIcon} />
          </div>
        </div> */}
        <div
          className={classNames.credit}
          style={{
            justifyContent: 'flex-start',
            flex: '0 0 20%',
            maxWidth: '20%',
          }}
        >
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {txn?.credited_interest
              ? txn?.credited_interest?.toFixed(6)
              : '0.000000'}
          </span>
        </div>
        {/* <div
          className={classNames.debit}
          style={{
            justifyContent: 'flex-start',
            flex: '0 0 20%',
            maxWidth: '20%',
          }}
        >
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(
              txn?.withdraw
                ? assetClass.name === 'fiat'
                  ? txn?.amt_credited
                  : txn?.amount
                : 0,
              txn?.coin
            )}
          </span>
        </div> */}
        {/* <div
          className={classNames.balance}
          style={{
            justifyContent: 'flex-start',
            flex: '0 0 20%',
            maxWidth: '20%',
          }}
        >
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(txn?.updated_balance, txn?.coin)}
          </span>
        </div> */}
        <div
          className={classNames.balance}
          style={{
            justifyContent: 'flex-start',
            flex: '0 0 20%',
            maxWidth: '20%',
          }}
        >
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(txn?.updated_balance, txn?.coin)}
          </span>
        </div>
        <div
          className={classNames.balance}
          style={{
            justifyContent: 'flex-start',
            flex: '0 0 20%',
            maxWidth: '20%',
          }}
        >
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(txn?.updated_balance, txn?.coin)}
          </span>
        </div>
        <div
          className={classNames.balance}
          style={{
            justifyContent: 'flex-start',
            flex: '0 0 20%',
            maxWidth: '20%',
          }}
        >
          <span className={classNames.expand}>Expand</span>
          <span className={classNames.value}>
            {FormatCurrency(txn?.updated_balance, txn?.coin)}
          </span>
        </div>
      </div>
      {txnOpen && <TransactionExpand txn={txn} forwardedRef={elRefs} />}
    </div>
  );
}

export default VaultItemRSIP;
