import React from 'react';
import OverallBar from './components/OverallBar';
import TimeBar from './components/TimeBar';
import classNames from './progress.module.scss';

const Progress = () => {
  return (
    <div className={classNames.progress}>
      <OverallBar />
      <TimeBar />
    </div>
  );
};

export default Progress;
