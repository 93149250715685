import React from 'react';
import classNames from '../../BuyBlocks/components/components.module.scss';
import editIcon from '../../../static/images/editIcon.svg';
import { useContext } from 'react';
import { planBContext } from '../../../context/PlanBContext';

export const InputDivs = ({
  heading,
  valueText,
  setFunc,
  addText,
  pic,
  type,
  subValueText,
  noedit,
  funcValue,
  currency,
}) => {
  const { setEditingConfigurationField } = useContext(planBContext);

  return (
    <div className={classNames.inputDivs}>
      <div className={classNames.heading}>{heading ? heading : ''}</div>
      {valueText ? (
        <div className={classNames.contentBox}>
          {type == 'pic' ? (
            valueText?.length > 7 ? (
              <div className={classNames.imageBox}>
                <img src={valueText} alt="plan image" />
              </div>
            ) : (
              <div
                className={classNames.newDataAdd}
                onClick={() =>
                  setFunc(funcValue, setEditingConfigurationField, currency)
                }
              >
                + Add {addText ? addText : ''}
              </div>
            )
          ) : type == 'date' ? (
            <div className={classNames.dateBox}>
              <div>{valueText?.split(' ')[0]}</div>
              <div>{valueText?.split(' ')[1]?.split(',')[0]}</div>
              <div>{valueText?.split(' ')[2]}</div>
            </div>
          ) : (
            <div
              className={classNames.valueBox}
              style={{ justifyContent: subValueText ? 'space-between' : '' }}
            >
              {addText == 'Type' ? (
                <img src={pic ? pic : ''} alt="typePic" />
              ) : (
                ''
              )}
              <div>{valueText ? valueText : ''}</div>
              {subValueText && <div>{subValueText}</div>}
            </div>
          )}

          <div
            style={{
              display:
                type == 'noedit' || (valueText?.length < 7 && noedit)
                  ? 'none'
                  : '',
            }}
            onClick={() =>
              setFunc(funcValue, setEditingConfigurationField, currency)
            }
          >
            <img src={editIcon} alt="editIcon" />
          </div>
        </div>
      ) : (
        <div
          className={classNames.newDataAdd}
          onClick={() =>
            setFunc(funcValue, setEditingConfigurationField, currency)
          }
        >
          + Add {addText ? addText : ''}
        </div>
      )}
    </div>
  );
};
