import axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { planBContext } from '../../../context/PlanBContext';
import classNames from './components.module.scss';

import tempCanadaFlag from '../../../static/images/tempCanadaFlag.svg';
import { useState } from 'react';

const AllPlans = () => {
  const [filteredallPlansList, setFilteredAllPlansList] = useState([]);
  const {
    allPlansList,
    setallPlansList,
    insideplanBCreate,
    setinsideplanBCreate,
    planBAccountType,
    setplanBAccountType,
    creationLoading,
  } = useContext(planBContext);

  useEffect(() => {
    axios
      .get('https://comms.globalxchange.io/retired/block/getallgoals')
      .then((response) => {
        // console.log(response?.data?.data, 'alldataplans');
        setallPlansList(response?.data?.data);
        setFilteredAllPlansList(response?.data?.data);
      });
  }, [creationLoading]);

  function filterPlanList(searchText) {
    let filteredRes = allPlansList.filter((eachplan) => {
      return eachplan?.name?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    setFilteredAllPlansList(filteredRes);
  }

  return (
    <div className={classNames.planTypeCreate}>
      <div className={classNames.title}>Select Plan Type</div>
      <PlanTypeSelection
        filterPlanList={filterPlanList}
        filteredallPlansList={filteredallPlansList}
        setinsideplanBCreate={setinsideplanBCreate}
      />
    </div>
  );
};

export default AllPlans;

const PlanSingleMap = ({ name, icon, goal_id }) => {
  const {
    planBAccountType,
    setplanBAccountType,
    setinsideplanBCreate,
    allPlansList,
    insideplanBCreate,
    planBAccountGoalId,
    setplanBAccountGoalId,
    setplanBAccountTypeIcon,
  } = useContext(planBContext);

  function setAccountTypeValue(name) {
    allPlansList.forEach((each) => {
      // console.log(each?.goal_id, 'eachselected', name, each.name);
      if (name.includes(each.name)) {
        setplanBAccountGoalId(each?.goal_id);
        setplanBAccountTypeIcon(each?.icon);
      }
    });
  }

  return (
    <div
      className={classNames.singlePlan}
      onClick={() => {
        setAccountTypeValue(name);
        setplanBAccountType(name);
        setinsideplanBCreate('createplanb');
      }}
    >
      <img src={icon?.length > 10 ? icon : tempCanadaFlag} alt="icon" />
      <div>{name ? name : ''}</div>
    </div>
  );
};

export const PlanTypeSelection = ({
  filterPlanList,
  filteredallPlansList,
  setinsideplanBCreate,
}) => {
  return (
    <>
      <div className={classNames.searchDiv}>
        <input
          type="text"
          placeholder="Search..."
          onChange={(event) => filterPlanList(event?.target?.value)}
        />
        <div>
          <div
            onClick={() => {
              setinsideplanBCreate('createplantype');
            }}
          >
            +
          </div>
        </div>
      </div>
      <div className={classNames.allPlans}>
        {filteredallPlansList?.length > 0 &&
          filteredallPlansList?.map((eachplan, i) => {
            return <PlanSingleMap {...eachplan} key={eachplan.name + i} />;
          })}
      </div>
    </>
  );
};
