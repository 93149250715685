import React, { Fragment, useContext, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { useCoinContextData } from '../../../context/CoinContext';
import { selectCoinToGenerateAddress } from '../helpers';
import { useAppContextDetails } from '../../../context/AppContext';
import { BankContext } from '../../../context/Context';
import { LoadingAnimation } from '../../LoadingAnimation';
import { useCoinData, useSubmitHash } from '../api';
import { useUserApps } from '../../../queryHooks';
import paste from '../../../static/images/paste.svg';
import manualEntry from '../../../static/images/manualEntry.svg';
import pullFromExplorer from '../../../static/images/pullFromExplorer.svg';
import { pasteSelected } from '../../../utils/FunctionTools';

export const AddAction = () => {
  const iconSize = 30;
  const {
    selectedCoin,
    coinType,
    setSelectedCoin,
    setCoinLoadingInAddAction,
    setUpdateVaultData,
    addActionStep,
    setHashText,
    hashText,
    setAddActionStep,
    submitHashEnabled,
    setSubmitHashEnabled,
    setCoinAction,
  } = useCoinContextData();
  const { appCode, appLogo, appCurrencyName } = useAppContextDetails();
  const { email, sidebarCollapse } = useContext(BankContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const marginLeftSize = { marginLeft: sidebarCollapse ? '15px' : '35px' };

  const { data: userApps } = useUserApps(email);
  const userAppsData = userApps?.filter((e) => e.app_code === appCode);
  const userProfileId = userAppsData?.[0]?.profile_id;

  const { mutate: submitHash, isLoading: isSubmittingHash } = useSubmitHash(
    setAddActionStep,
    setSubmitHashEnabled,
    selectedCoin
  );

  const {
    mutate: getCoinData,
    data: vaultData,
    isLoading: isCoinDataLoading,
  } = useCoinData();

  useEffect(() => {
    if (isLoading || addActionStep === 4) {
      getCoinData({
        app_code: appCode,
        displayCurrency: appCurrencyName,
        email,
        orderby_dsc: true,
        post_app_prices: true,
        type: coinType,
        include_coins: [selectedCoin.coinSymbol],
      });
      setUpdateVaultData(true);
    }
    setIsLoading(false);
  }, [isLoading, addActionStep]);

  useEffect(() => {
    if (vaultData) {
      setSelectedCoin(vaultData?.data?.coins_data?.[0]);
    }
  }, [setSelectedCoin, vaultData]);

  useEffect(() => {
    setCoinLoadingInAddAction(isCoinDataLoading);
  }, [isCoinDataLoading]);

  useEffect(() => {
    const isSubmitHashEnabled =
      addActionStep === 3 && submitHashEnabled && hashText;

    const isSubmitHashEnabledForEth =
      isSubmitHashEnabled &&
      (selectedCoin.eth_token || selectedCoin.coinSymbol === 'ETH');
    const isSubmitHashEnabledForTrx =
      isSubmitHashEnabled && selectedCoin.coinSymbol === 'TRX';
    const isSubmitHashEnabledForXrp =
      isSubmitHashEnabled && selectedCoin.coinSymbol === 'XRP';
    const isSubmitHashEnabledForSol =
      isSubmitHashEnabled && selectedCoin.coinSymbol === 'SOL';

    if (isSubmitHashEnabledForEth) {
      const apiBody = {
        app_code: appCode,
        email,
        coin: selectedCoin.coinSymbol,
        txn_hash: hashText,
      };
      submitHash(apiBody);
    } else if (isSubmitHashEnabledForTrx) {
      const apiBody = {
        email,
        app_code: appCode,
        hash: hashText,
      };
      submitHash(apiBody);
    } else if (isSubmitHashEnabledForXrp) {
      const apiBody = {
        email,
        app_code: appCode,
        hash: hashText,
      };
      submitHash(apiBody);
    } else if (isSubmitHashEnabledForSol) {
      const apiBody = {
        email,
        app_code: appCode,
        txn_signature: hashText,
      };
      submitHash(apiBody);
    }
  }, [submitHashEnabled, addActionStep, hashText]);

  const isDataLoading =
    isCoinDataLoading || isGenerating || isLoading || isSubmittingHash;
  const isSolCoin = selectedCoin.coinSymbol === 'SOL';
  const breadcrumbCheckForSol = isSolCoin ? 'Submit Txn Sign' : 'Submit Hash';

  return (
    <>
      <div className="breadCrumbs">
        {addActionStep === 1 && (
          <div className="gt">
            <span onClick={() => setCoinAction(['Menu'])}>
              {selectedCoin.coinName} Actions &nbsp;&gt;&nbsp;
            </span>
            <span>Add</span>
          </div>
        )}
        {addActionStep === 2 && (
          <div className="gt">
            <span onClick={() => setAddActionStep(1)}>
              Add &nbsp;&gt;&nbsp;
            </span>
            <span>{breadcrumbCheckForSol}</span>
          </div>
        )}
        {addActionStep === 3 && (
          <div className="gt">
            <span onClick={() => setAddActionStep(2)}>
              {breadcrumbCheckForSol} &nbsp;&gt;&nbsp;
            </span>
            <span>Manual Entry</span>
          </div>
        )}
      </div>

      {addActionStep === 1 && (
        <div
          className="qr-code-wrapper"
          style={{
            opacity: isDataLoading ? 0.5 : 1,
          }}
        >
          <div className="d-flex align-items-center justify-content-center p-5 mb-3">
            <img
              src={selectedCoin.coinImage}
              alt="Coin Icon"
              width={iconSize}
            />
            <h2 className="ml-2">{selectedCoin.coinName}</h2>
          </div>

          {selectedCoin.native_deposit ? (
            selectedCoin.coin_address ? (
              <QRCode
                value={selectedCoin.coin_address}
                renderAs="svg"
                size={200}
              />
            ) : (
              <div className="p-1 w-75 text-center">
                <h4 className="add-content">
                  {`Looks Like Its Your First Time Add ${selectedCoin.coinSymbol}. Please Request Your
                Wallet Address By Clicking The Button Below`}
                </h4>
              </div>
            )
          ) : (
            <div className="p-1 w-75 text-center">
              <h4 className="add-content">
                <b>Sorry</b> We Don’t Currently Support Native Deposits For
                {selectedCoin.coinName} But We Are Working On It!
              </h4>
            </div>
          )}

          {selectedCoin.native_deposit && (
            <div className="p-5 mt-3">
              {selectedCoin.coin_address ? (
                <input
                  className="form-control address-input"
                  disabled
                  value={selectedCoin.coin_address}
                />
              ) : (
                <button
                  className="generate-btn"
                  onClick={() =>
                    selectCoinToGenerateAddress(
                      selectedCoin.coinSymbol,
                      email,
                      appCode,
                      setIsLoading,
                      selectedCoin.eth_token,
                      userProfileId,
                      setIsGenerating
                    )
                  }
                >
                  Generate Address
                </button>
              )}
            </div>
          )}
        </div>
      )}

      {addActionStep === 2 && (
        <Fragment>
          <div
            className="d-flex sidebar-container clickable-div mt-3"
            onClick={() => setAddActionStep(3)}
          >
            <img
              className="sidebar-content-icon"
              src={manualEntry}
              alt="manual entry"
              width={60}
              style={marginLeftSize}
            />
            {!sidebarCollapse && (
              <div className="col my-auto p-0">
                <h5 className="action-label">Manual entry</h5>
                <div className="getStartedBtn action-sublabel">
                  Enter The Hash From Senders Side
                </div>
              </div>
            )}
          </div>
          <div className="sidebar-spacer" />
          <div
            style={{ opacity: 0.25, pointerEvents: 'none' }}
            className="d-flex sidebar-container"
            onClick={() => null}
          >
            <img
              className="sidebar-content-icon"
              src={pullFromExplorer}
              alt="PullFromExplorer"
              width={60}
              style={marginLeftSize}
            />
            {!sidebarCollapse && (
              <div className="col my-auto p-0">
                <h5 className="action-label">Pull From Explorer</h5>
                <div className="getStartedBtn action-sublabel">
                  See All Hashes Affecting This Address
                </div>
              </div>
            )}
          </div>
          <div className="sidebar-spacer" />
        </Fragment>
      )}

      {addActionStep === 3 && (
        <div
          className="manual-entry"
          style={{
            opacity: isSubmittingHash ? 0.5 : 1,
          }}
        >
          {isSolCoin ? <h4>Enter Txn Signature</h4> : <h4>Enter Hash</h4>}
          {isSolCoin ? (
            <p className="enter-hash">
              For The {selectedCoin.coinSymbol} Deposit Transaction To{' '}
              <b>{selectedCoin.coin_address}</b>
            </p>
          ) : (
            <p className="enter-hash">
              Enter The Hash Of The {selectedCoin.coinSymbol} Deposit
              Transaction To &nbsp;
              <b>{selectedCoin.coin_address}</b>
            </p>
          )}
          <div className="address-div mt-4 mb-4">
            <input
              className="address-input"
              key="Hash"
              id="clipboard"
              placeholder={isSolCoin ? 'Txn Signature..' : 'Hash...'}
              type="text"
              onChange={(e) => setHashText(e.target.value)}
            />
          </div>
          <div className="each-paste" key="paste">
            <div>
              <img className="paste-btn" src={paste} alt="Paste" width={20} />
            </div>
            <p
              className="paste-text"
              onClick={() => pasteSelected(setHashText)}
            >
              Paste Clipboard
            </p>
          </div>
        </div>
      )}

      {addActionStep === 4 && (
        <div
          className="manual-entry"
          style={{
            opacity: isDataLoading ? 0.5 : 1,
          }}
        >
          {isSolCoin ? (
            <p className="enter-hash">
              Congratulations. You Have Successully Submitted The Txn Signature
              For Your Lasted {selectedCoin.coinSymbol} Deposit
            </p>
          ) : (
            <p className="enter-hash">
              Congratulations. You Have Successully Submitted The Hash For Your
              Lasted {selectedCoin.coinSymbol} Deposit
            </p>
          )}
          <div>
            <p className="balance">{`New ${selectedCoin.coinSymbol} Balance`}</p>
          </div>
          <div className="withdraw-success-box">
            <div className="div-success-input">
              <input
                className="from-input"
                placeholder="0.00"
                key="from-1"
                id="from-1"
                type="text"
                value={selectedCoin.coinValue}
              />
              <div className="post-withdraw-from-coin" key="from-coin">
                <img
                  className="coinimgsmall"
                  src={selectedCoin.coinImage}
                  alt=""
                />
                <p className="coinsymbolsmall">{selectedCoin.coinSymbol}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDataLoading && (
        <div className="coin-address-loading">
          <LoadingAnimation icon={appLogo} width={200} />
        </div>
      )}
    </>
  );
};
