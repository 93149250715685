import React, { useContext, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

import Routes from './Routes';
import PortfolioContextProvider from './context/PortfolioContext';
import NetWorthContextProvider from './context/NetWorthContext';
import { BankContext } from './context/Context';
import ChatContextProvider from './context/ChatContext';
import DefiContextProvider from './context/DefiContext';
import ChatsIoContextProvider from './context/ChatsIoContext';
import SupportChatContextProvider from './context/SupportChatContext';
import InvestmentVaultContextProvider from './context/InvestmentVaultContext';
import AppContextProvider from './context/AppContext';
import { CoinContextProvider } from './context/CoinContext';
import RegistrationContextProvider from './context/RegistrationContext';
import { MarketContextProvider } from './context/MarketContext';
import PlanBContextProvider from './context/PlanBContext';

function App() {
  const { refreshPage } = useContext(BankContext);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <RegistrationContextProvider>
        <AppContextProvider>
          <MarketContextProvider>
            <CoinContextProvider>
              <PortfolioContextProvider
                key={refreshPage === 'portfolio'.toString()}
              >
                <NetWorthContextProvider
                  key={refreshPage === 'networth'.toString()}
                >
                  <ChatContextProvider>
                    <ChatsIoContextProvider>
                      <SupportChatContextProvider>
                        <DefiContextProvider>
                          <InvestmentVaultContextProvider>
                            <PlanBContextProvider>
                              <BrowserRouter>
                                <Routes />
                              </BrowserRouter>
                            </PlanBContextProvider>
                          </InvestmentVaultContextProvider>
                        </DefiContextProvider>
                      </SupportChatContextProvider>
                    </ChatsIoContextProvider>
                  </ChatContextProvider>
                </NetWorthContextProvider>
              </PortfolioContextProvider>
            </CoinContextProvider>
          </MarketContextProvider>
        </AppContextProvider>
      </RegistrationContextProvider>
    </QueryClientProvider>
  );
}

export default App;
