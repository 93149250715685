import React, { useContext } from 'react';
import { planBContext } from '../../context/PlanBContext';
import CreatePlanB from './components/CreatePlanB';
import CreatePlanType from './components/CreatePlanType';
import classNames from './createPlanB.module.scss';
import AllPlans from './components/AllPlans';
import rightArrow from '../../static/images/rightArrow.svg';
import { LoadingAnimation } from '../LoadingAnimation';
import { useMarketContext } from '../../context/MarketContext';

const CreatePlanBContainer = () => {
  const { globalappIcon } = useMarketContext();
  const {
    insideplanBCreate,
    setinsideplanBCreate,
    setplanBCreate,
    creationLoading,
    setCreationLoading,
    setSelectedDropDown,
  } = useContext(planBContext);
  return (
    <div className={classNames.createPlanBWhole}>
      <div
        className={classNames.closeBtn}
        onClick={() => {
          setSelectedDropDown('');
          if (insideplanBCreate == 'createplanb') {
            setplanBCreate(false);
            setinsideplanBCreate('createplanb');
          } else if (insideplanBCreate == 'plantype') {
            setinsideplanBCreate('createplanb');
          } else {
            setinsideplanBCreate('plantype');
          }
        }}
      >
        {insideplanBCreate == 'createplanb' ? (
          <div>x</div>
        ) : (
          <img src={rightArrow} alt="rightArrow" />
        )}
      </div>
      {creationLoading ? (
        <div className={classNames.loadingDiv}>
          <LoadingAnimation icon={globalappIcon} width={200} />
        </div>
      ) : (
        ''
      )}
      {insideplanBCreate === 'createplanb' ? (
        <CreatePlanB />
      ) : insideplanBCreate === 'plantype' ? (
        <AllPlans />
      ) : insideplanBCreate === 'createplantype' ? (
        <CreatePlanType />
      ) : (
        ''
      )}
    </div>
  );
};

export default CreatePlanBContainer;
