import React, { useState, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import './selectAsset.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import { useCoinContextData } from '../../../context/CoinContext';
import { getAssets } from '../api';
import { useAppContextDetails } from '../../../context/AppContext';

const SelectAsset = () => {
  const [assetsList, setAssetsList] = useState([]);
  const [assetSearchTerm, setAssetSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {
    assetName,
    setAssetName,
    setSelectAsset,
    setFromAsset,
    setCoinAction,
    coinAction,
    selectedCoin,
  } = useCoinContextData();
  const { appCode } = useAppContextDetails();

  const handleAssetData = () => {
    if (assetName === '') {
      setIsLoading(true);
    }
    let obj = {
      fromCurrency: assetName,
      toCurrency: selectedCoin.coinSymbol,
      appCode: appCode,
    };

    getAssets(obj).then((response) => {
      let result = response.data;
      if (result.status) {
        if (assetName === '') {
          setIsLoading(false);
          setAssetsList(result.pathData.from_currency);
        } else {
          setIsLoading(false);
          setFromAsset(result.pathData);
          handleNavBack();
        }
      }
    });
  };

  const handleNavBack = () => {
    setSelectAsset(false);
    setCoinAction([coinAction[0]]);
  };

  const handleSelect = (each) => {
    setAssetName(each.coin_metadata.coinSymbol);
  };

  useEffect(() => {
    handleAssetData();
  }, [assetName]);

  return (
    <div className="assetcomp">
      <div className="selectmaster">
        <div className="sub-card">
          <p className="selecthead">Select Sell Asset</p>
          <div className="search-card">
            <div
              className="searchboxasset"
              style={{ cursor: isLoading ? 'not-allowed' : '' }}
            >
              <input
                className="search"
                type="search"
                placeholder="Search Assets..."
                value={assetSearchTerm}
                onChange={(e) => setAssetSearchTerm(e.target.value)}
                disabled={isLoading ? 'disabled' : ''}
              />
            </div>
            <>
              {isLoading ? (
                <>
                  {[1, 2, 3, 4, 5].map(({}, i) => (
                    <div className="coin-box" key={i}>
                      <Skeleton className="first-div" />
                      <Skeleton className="second-div" />
                    </div>
                  ))}
                </>
              ) : (
                <div className="assetlistcard">
                  {assetsList
                    ?.filter((element) => {
                      return element.coin_metadata.coinName
                        .toLowerCase()
                        .includes(assetSearchTerm.toLowerCase());
                    })
                    .map((each) => {
                      return (
                        <div
                          className="coin-box"
                          key={each._id}
                          onClick={() => handleSelect(each)}
                        >
                          <img
                            className="coin-Image"
                            src={each.coin_metadata.coinImage}
                            alt=""
                          />
                          <p className="coin-Title">
                            {each.coin_metadata.coinName}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAsset;
