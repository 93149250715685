import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useMarketContext } from '../../context/MarketContext';
import { planBContext } from '../../context/PlanBContext';
import CreatePlanType from '../BuyBlocks/components/CreatePlanType';
import { LoadingAnimation } from '../LoadingAnimation';
import EditSidebar from './components/EditSidebar';
import { InputDivs } from './components/InputDiv';
import classNames from './configuration.module.scss';
import { editConfiguration } from './func/editConfiguration';
import rightArrow from '../../static/images/rightArrow.svg';

const Configuration = () => {
  let { retiredid } = useParams();
  const { globalappIcon } = useMarketContext();

  const {
    setplanBAccountPic,
    configurationValues,
    setConfigurationValues,
    configurationGoalValues,
    setConfigurationGoalValues,
    configurationDateValues,
    setConfigurationDateValues,
    editingConfigurationField,
    setEditingConfigurationField,
    editFuncApi,
    editingConfigurationLoading,
    planTypeCreate,
    setplanTypeCreate,
  } = useContext(planBContext);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/retired/block/get/All/retirementplans?retirement_plan_id=${retiredid}`
      )
      .then((response) => {
        let res = response?.data?.data;

        if (res?.length > 0) {
          // console.log(res[0]?.icon, 'account pic');
          setplanBAccountPic(res?.icon);
          res = res[0]?.GoalDetails;
          //   console.log(res, 'goalsd red 1');

          if (res?.length > 0) {
            res = res[0]?.GoalDetails;
            // console.log(res, 'goalsd red 2');
            if (res?.length > 0) {
              //   console.log(res[0], 'goalsd red 3');
              setConfigurationGoalValues(res[0]);
            }
          }
        }
        if (response?.data?.data?.length > 0) {
          if (!response?.data?.data[0]?.date_of_accomplishment) {
            setConfigurationDateValues('');
          } else {
            convertDateCorrectly(
              response?.data?.data[0]?.date_of_accomplishment
            );
          }
        }
        // console.log(response?.data?.data, 'goal res');
        setConfigurationValues(response?.data?.data);
      });
  }, [editingConfigurationField]);

  function convertDateCorrectly(date) {
    let datee = date.split('/');
    datee = datee[1] + '/' + datee[0] + '/' + datee[2];
    let converted = moment(datee).format('LL');
    setConfigurationDateValues(converted);
  }

  return (
    <div className={classNames.configurationContainer}>
      <div>
        <div className={classNames.contentContainer}>
          <div className={classNames.title}>Configurations</div>
          <div className={classNames.dataContainer}>
            {configurationValues?.length > 0 ? (
              <>
                <InputDivs
                  heading="PlanB Name"
                  valueText={configurationValues[0]?.retirement_plan_name}
                  setFunc={editConfiguration}
                  addText="Name"
                  funcValue="name"
                />
                <InputDivs
                  heading="PlanB Type"
                  valueText={configurationGoalValues?.goalName}
                  setFunc={editConfiguration}
                  addText="Type"
                  pic={configurationGoalValues?.goalIcon}
                  funcValue="planbtype"
                />
                <InputDivs
                  heading="PlanB Description"
                  valueText={configurationValues[0]?.description}
                  setFunc={editConfiguration}
                  addText="Description"
                  funcValue="description"
                />
                <InputDivs
                  heading="Desired Date Of Accomplishment"
                  valueText={configurationDateValues}
                  setFunc={editConfiguration}
                  addText="Date Of Accomplishment"
                  type="date"
                  funcValue="accomplishmentdate"
                />
                <InputDivs
                  heading="PlanB Icon"
                  valueText={configurationValues[0]?.icon}
                  setFunc={editConfiguration}
                  addText="Icon"
                  type="pic"
                  noedit="noedit"
                  funcValue="planicon"
                />
                <InputDivs
                  heading="PlanB Currency"
                  valueText={configurationValues[0]?.CurrencyForTheAmount}
                  setFunc={editConfiguration}
                  addText="Currency"
                  funcValue="accomplishmentcurrency"
                />
                <InputDivs
                  heading="Estimated Amount To Accomplishment"
                  valueText={configurationValues[0]?.amountToAccomplish}
                  setFunc={editConfiguration}
                  addText="Amount"
                  type="amountwithcurreny"
                  subValueText={configurationValues[0]?.CurrencyForTheAmount}
                  funcValue="accomplishmentamount"
                  currency={configurationValues[0]?.CurrencyForTheAmount}
                />
                <InputDivs
                  heading="PlanB Creation Date"
                  valueText={
                    configurationValues[0]?.date
                      ? moment(configurationValues[0]?.date).format(
                          'MMMM Do YYYY'
                        )
                      : ''
                  }
                  type="noedit"
                />
                <InputDivs
                  heading="PlanB Starting Date"
                  valueText={
                    configurationValues[0]?.startingDate
                      ? configurationValues[0]?.startingDate
                      : 'Connect A SIP To Start'
                  }
                  type="noedit"
                />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames.editSidebar}
        style={{ right: editingConfigurationField ? '0' : '' }}
      >
        <div className={classNames.closeBtn}>
          {planTypeCreate ? (
            <img
              src={rightArrow}
              alt="rightArrow"
              onClick={() => setplanTypeCreate(false)}
            />
          ) : (
            <div onClick={() => setEditingConfigurationField('')}>x</div>
          )}
        </div>
        {!planTypeCreate ? (
          <>
            <div className={classNames.title}>Edit PlanB</div>
            <div className={classNames.contentDiv}>
              <EditSidebar />
              <div
                className={classNames.updateBtn}
                onClick={() => editFuncApi(retiredid)}
              >
                Update PlanB
              </div>
            </div>
          </>
        ) : (
          <CreatePlanType />
        )}
        {editingConfigurationLoading ? (
          <div className="loadingIcon">
            <LoadingAnimation icon={globalappIcon} width={200} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Configuration;
