import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from './visionBoard.module.scss';
import './visionboard.scss';

import plusIcon from '../../static/images/icons/plus.svg';
import { useContext } from 'react';
import { BankContext } from '../../context/Context';
import { useMarketContext } from '../../context/MarketContext';

const VisionBoard = () => {
  let { retiredid } = useParams();

  const [fakegoalsList, setFakeGoalsList] = useState([
    { addGoal: 'addGoal' },
    ...[...Array(6)],
  ]);

  const {
    goalsList,
    setGoalsList,
    goalsDetails,
    sidebarShowProgress,
    setsidebarShowProgress,
    goalCreating,
    refreshGoals, setRefreshGoals
  } = useContext(BankContext);

  // const { planBOpened } = useMarketContext();

  useEffect(() => {
    let showDetail = goalsList.filter((eachgoal) => {
      return (
        eachgoal?.retirement_plan_id == goalsDetails?.retirement_plan_id &&
        eachgoal?.name?.includes(goalsDetails?.name)
      );
    });
    setsidebarShowProgress(showDetail);
    // console.log(showDetail, 'goalsLisshowDetailt');
  }, [goalsDetails]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/retired/vision/get/all/visionplans?retirement_plan_id=${retiredid}`
      )
      .then((response) => {
        setGoalsList([
          ...response?.data?.data,
          { addGoal: 'addGoal' },
          ...[...Array(6)],
        ]);
        // console.log(response?.data?.data, 'response for golsa', goalsList);
      });
  }, [goalCreating, refreshGoals]);

  return (
    <div className={classNames.VisionBoard}>
      <div className={classNames.container}>
        {goalsList?.length > 0
          ? goalsList.map((eachgoal, i) => {
            return <Square index={i} {...eachgoal} key={'goalsList' + i} />;
          })
          : fakegoalsList.map((eachgoal, i) => {
            return (
              <Square
                index={i}
                {...eachgoal}
                fake="fake"
                key={'goalsList' + i}
              />
            );
          })}
      </div>
    </div>
  );
};

export default VisionBoard;

const Square = ({ index, image, link, addGoal, retirement_plan_id, name }) => {
  const { goalsDetails, setGoalsDetails, setcreateGoals } =
    useContext(BankContext);

  return (
    <div
      className={`${classNames.squareCard} ${index == '2'
          ? 'column-span'
          : index == '3'
            ? 'row-span'
            : index == '5'
              ? 'row-span'
              : index % 8 == '0' && index > 5
                ? 'column-span'
                : index % 9 == '0' && index > 5
                  ? 'row-span'
                  : index % 11 == '0' && index > 5
                    ? 'row-span'
                    : ''
        }`}
      style={{ border: image ? 'none' : '' }}
    >
      {image ? (
        <div className={classNames.displayImageDiv}>
          <img
            src={image ? image : ''}
            alt="goalimage"
            onClick={() => {
              // console.log('clicked');
              setGoalsDetails({
                retirement_plan_id: retirement_plan_id,
                name: name,
              });
            }}
          />
          <div
            onClick={() => {
              // console.log('clicked');
              setGoalsDetails({
                retirement_plan_id: retirement_plan_id,
                name: name,
              });
            }}
          >
            {name ? name : ''}
          </div>
        </div>
      ) : addGoal == 'addGoal' ? (
        <div className="addPlan" onClick={() => setcreateGoals(true)}>
          <img src={plusIcon} alt="plusIcon" />
          <div>Add New Goal</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
