import React, { useContext, useEffect, useState } from 'react'
import { uploadImageFunc } from '../../utils/imageUpload';
import classNames from '../VaultsPage/vaultsPage.module.scss';
import { planBContext } from '../../context/PlanBContext';
import axios from 'axios';
export default function Addnew({ setopentoggleclass, investdeatil,opentoggleclass, storeproductdetail, storeasubsstesdetail, storeasstesdetail, list, setlist }) {

    const [uploadedImageTracker, setuploadedImageTracker] = useState("")



    const [namelist, setnamelist] = useState("")
    const [des, setdes] = useState("")

    // useEffect(() => {
    //     setlist("main")
    // }, [])
    console.log("uploadedImageTracker", uploadedImageTracker)


    

    const postapi = () => {
        axios.post(

            'https://comms.globalxchange.io/retired/asset/add',
            {


                "name": namelist,
                "icon": uploadedImageTracker,
                "description": des




            }
        ).then(({ data }) => {
            if (data.status) {
                setnamelist("")
                setdes("")
                setuploadedImageTracker("")
                setopentoggleclass(false)
            }
        });
    }

    const calldeleteassetsclasss = (e) => {
        axios.delete(

            `https://comms.globalxchange.io/retired/asset/delete?asset_id=${e}`

        ).then(({ data }) => {
            if (data.status) {
                setopentoggleclass(false)
            }
        });
    }


    const calldeleteassetsclassssubcalsss = (e) => {
        axios.delete(

            `https://comms.globalxchange.io/retired/subasset/delete?sub_asset_id=${e}`

        ).then(({ data }) => {
            if (data.status) {
                setopentoggleclass(false)
            }
        });
    }

    const produtdelete = (e) => {
        axios.delete(

            `https://comms.globalxchange.io/retired/product/delete?product_id=${e}`

        ).then(({ data }) => {
            if (data.status) {
                setopentoggleclass(false)
            }
        });
    }

    const invstdeletefun = (e) => {
        axios.delete(

            `https://comms.globalxchange.io/retired/investmentprovider/delete?investment_provider_id=${e}`

        ).then(({ data }) => {
            if (data.status) {
                setopentoggleclass(false)
            }
        });
    }

    // const [uploadedImageTracker,setuploadedImageTracker]=useState("")
    const [subname, setsubname] = useState("")
    const [subdes, setsubdes] = useState("")
    const [subuploadedImageTracker, setsubuploadedImageTracker] = useState("")
    const [datadrop, setdatadrop] = useState([])
    useEffect(() => {
        subclassdrowpfucntion()
        countrydrowpfucntion()
        prodiverFucntion()
    }, [])
    const subclassdrowpfucntion = () => {

        axios.get(
            `https://comms.globalxchange.io/retired/asset/get`
        ).then(({ data }) => {
            if (data.status) {
                console.log("data?.dataddddddddddddd", data?.data)
                setdatadrop(data?.data);

            }
        });
    }
    const [countriesName, setcountriesName] = useState([])
    const countrydrowpfucntion = () => {

        axios.get(
            `https://comms.globalxchange.io/coin/vault/countries/data/get`
        ).then(({ data }) => {
            if (data?.status) {
                console.log("data?.datadddddddd1111111111111111ddddd", data?.countries)
                setcountriesName(data?.countries)
                // setdatadrop(data?.countries);

            }
        });
    }
    const [selectlabel, setselectlabel] = useState({
        name: "Click to select",
        id: ""
    })

    const [selectlabelassets, setselectlabelassets] = useState({
        name: "Click to select",
        id: ""
    })


    const [droptoggle, setdroptoggle] = useState(false)
    const handldropdown = (e) => {
        setdroptoggle(false)
        setselectlabel({
            name: e?.name,
            id: e?.asset_id
        })
    }

    const handldropdownassets = (e) => {
        setdroptoggle(false)
        setselectlabelassets({
            name: e?.name,
            id: e?.asset_id
        })
        subassetsfuc(e?.asset_id)
    }
    const [subclasslist, setsubclasslist] = useState([])
    const [subassetsName, setsubassetsName] = useState({
        name: "Click to select",
        id: ""
    })
    const [togglesubclass, settogglesubclass] = useState(false)
    const subhandldropdownassets = (e) => {
        settogglesubclass(false)
        setsubassetsName({
            name: e?.name,
            id: e?.sub_asset_id
        })

    }


    const subassetsfuc = (e) => {

        axios.get(
            `https://comms.globalxchange.io/retired/subasset/get?parent_asset_class_id=${e}`
        ).then(({ data }) => {
            if (data.status) {
                console.log("data?.dataddddddddddddd", data?.data)
                setsubclasslist(data?.data);

            }
            else {
                setsubclasslist([]);
                setsubassetsName({
                    name: "Click to select",
                    id: ""
                })
            }
        });
    }


    const postapisub = () => {
        axios.post(

            'https://comms.globalxchange.io/retired/asset/add',
            {


                "name": subname,
                "icon": subuploadedImageTracker,
                "description": subdes,
                "parent_asset_class_id": selectlabel?.id




            }
        ).then(({ data }) => {
            if (data.status) {
                setsubname("")
                setsubuploadedImageTracker("")
                setsubdes("")
                setopentoggleclass(false)
                setselectlabel({
                    name: "Click to select",
                    id: ""
                })
            }
        });
    }

    const [provideName, setprovideName] = useState("")
    const [fullprovideName, setfullprovideName] = useState('')
    const [Website, setWebsite] = useState("")
    const [profiversubdes, setprofiversubdes] = useState("")
    const [imageprovide, setimageprovide] = useState("")
    const [providerselect, setproviderselect] = useState({
        name: "Click to select",
        id: ""
    })
    const [countryName, setcountryName] = useState("Click to select")
    const [countrydropdown, setcountrydropdown] = useState(false)
    const handldropdownprodivcecountry = (e) => {
        setcountryName(e?.name)
        setcountrydropdown(false)
    }
    const handldropdownprodivce = (e) => {
        setdroptoggle(false)
        setproviderselect({
            name: e?.name,
            id: e?.asset_id
        })
    }




    const postapisubProvider = () => {
        axios.post(

            'https://comms.globalxchange.io/retired/investmentprovider/add',
            {



                "displayName": provideName,
                "fullName": fullprovideName,
                "icon": imageprovide,
                "description": profiversubdes,
                "website": Website,
                "asset_class_id": providerselect?.id,
                "country": countryName





            }
        ).then(({ data }) => {
            if (data.status) {
                setprovideName("")
                setfullprovideName("")
                setimageprovide("")
                setprofiversubdes("")
                setWebsite("")
                setcountryName("")
                setopentoggleclass(false)
                setproviderselect({
                    name: "Click to select",
                    id: ""
                })
            }
        });

    }



    const [productName, setproductName] = useState("")
    const [code, setcode] = useState("")
    const [productdes, setproductdes] = useState("")
    const [avgreturn, setavgreturn] = useState("")
    const [frequencyNo, setfrequencyNo] = useState("Click to select")
    const [contributionNo, setcontributionNo] = useState("")
    const [contrinutiontoggle, setcontrinutiontoggle] = useState(false)
    const closefrquency = (e) => {
        setfrequencyNo(e)
        setcontrinutiontoggle(false)
    }

    const [produvierName, setproduvierName] = useState({
        name: "Click to select",
        id: ""
    })
    const [provderdata, setprovderdata] = useState([])
    const [prodertoggle, setprodertoggle] = useState(false)
    const handleProviderItem = (e) => {
        setprodertoggle(false)
        setproduvierName({
            name: e?.displayName,
            id: e?.investment_provider_id
        })

    }



    console.log("czcjxjnvxj", opentoggleclass)
    const closesvg = () => {

        setopentoggleclass(false)
    }

    const postapiNewform = () => {
        axios.post(

            'https://comms.globalxchange.io/retired/product/add',
            {




                "creationAdvisorEmail": localStorage.getItem('nvestBankLoginAccount') || '',
                "productName": productName,
                "investment_provider_id": produvierName?.id,
                "asset_class_id": selectlabelassets?.id,
                "sub_asset_class_id": subassetsName?.id,
                "description": productdes,
                "avg_rate_of_return": avgreturn,
                "contribution_frequency": frequencyNo,
                "minimum_contribution": contributionNo,
                "scheme_mode": code






            }
        ).then(({ data }) => {
            if (data.status) {
                setproductName("")
                setproduvierName({
                    name: "Click to select",
                    id: ""
                })

                setselectlabelassets({
                    name: "Click to select",
                    id: ""
                })
                setsubassetsName({
                    name: "Click to select",
                    id: ""
                })
                setopentoggleclass(false)
                setcontributionNo("")
                setproductdes("")
                setavgreturn("")
                setfrequencyNo("")
                setcontributionNo("")
                setcode("")

            }
        });
    }




    const prodiverFucntion = (e) => {

        axios.get(
            `https://comms.globalxchange.io/retired/investmentprovider/get`
        ).then(({ data }) => {
            if (data.status) {
                console.log("data?.dataddddddddddddd", data?.data)
                setprovderdata(data?.data);

            }
        });
    }


    const mainfunct = () => {
        switch (list) {

            case "Add New Product":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Add New Product</div>
                                <svg

                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>




                                <div className='upload-txxt'>Product name</div>

                                <input placeholder='Name...'
                                    value={productName}
                                    onChange={(e) => setproductName(e.target.value)}
                                />
                                <div className='upload-txxt'>Scheme code</div>

                                <input placeholder='Name...'
                                    value={code}
                                    onChange={(e) => setcode(e.target.value)}
                                />





                                <div className='upload-txxt'>Describe the product</div>
                                <textarea
                                    value={productdes}
                                    placeholder='Description...'

                                    onChange={(e) => setproductdes(e.target.value)}
                                />


                                <div className='upload-txxt'>Avg rate of return</div>

                                <input placeholder='0.00%'
                                    value={avgreturn}
                                    onChange={(e) => setavgreturn(e.target.value)}
                                />



                                <div className='upload-txxt'
                                // style={{
                                //     marginTop: "9rem"
                                // }}
                                >Contribution frequency</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => setcontrinutiontoggle(!contrinutiontoggle)}
                                    >

                                        <div
                                            style={{
                                                opacity: frequencyNo == "Click to select" && 0.25
                                            }}
                                        > {frequencyNo}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        contrinutiontoggle &&
                                        <>
                                            <div className='selectbox'>


                                                <div className='boxselext'
                                                    onClick={() => closefrquency("Monthly")}
                                                >

                                                    <div>
                                                        <div className='nameofmutual'>Monthly</div>
                                                        {/* <div className='mutualid'>{item?.asset_id}</div> */}
                                                    </div>
                                                </div>


                                            </div>
                                        </>
                                    }

                                </div>
                                {/*  */}

                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "2rem"
                                    }}
                                >Minimum contribution</div>

                                <input placeholder='0.00%'
                                    value={contributionNo}
                                    onChange={(e) => setcontributionNo(e.target.value)}
                                />


                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "2rem"
                                    }}
                                >Asset class</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => setdroptoggle(!droptoggle)}
                                    >

                                        <div
                                            style={{
                                                opacity: selectlabelassets?.name == "Click to select" && 0.25
                                            }}
                                        > {selectlabelassets?.name}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        droptoggle &&
                                        <>
                                            <div className='selectbox'>

                                                {
                                                    datadrop?.map(item => {
                                                        return (
                                                            <div className='boxselext'
                                                                onClick={() => handldropdownassets(item)}
                                                            >
                                                                <img src={item?.icon} />
                                                                <div>
                                                                    <div className='nameofmutual'>{item?.name}</div>
                                                                    <div className='mutualid'>{item?.asset_id}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }

                                </div>





                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "2rem"
                                    }}
                                >Sub asset class</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => settogglesubclass(!togglesubclass)}
                                    >

                                        <div
                                            style={{
                                                opacity: subassetsName?.name == "Click to select" && 0.25
                                            }}
                                        > {subassetsName?.name}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        togglesubclass &&
                                        <>
                                            <div className='selectbox'>

                                                {
                                                    subclasslist?.map(item => {
                                                        return (
                                                            <div className='boxselext'
                                                                onClick={() => subhandldropdownassets(item)}
                                                            >
                                                                <img src={item?.icon} />
                                                                <div>
                                                                    <div className='nameofmutual'>{item?.name}</div>
                                                                    <div className='mutualid'>{item?.sub_asset_id}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }

                                </div>


                                {/*  */}


                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "2rem"
                                    }}
                                >Sub investment provider</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => setprodertoggle(!prodertoggle)}
                                    >

                                        <div
                                            style={{
                                                opacity: produvierName?.name == "Click to select" && 0.25
                                            }}
                                        > {produvierName?.name}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        prodertoggle &&
                                        <>
                                            <div className='selectbox'>

                                                {
                                                    provderdata?.map(item => {
                                                        return (
                                                            <div className='boxselext'
                                                                onClick={() => handleProviderItem(item)}
                                                            >
                                                                <img src={item?.icon} />
                                                                <div>
                                                                    <div className='nameofmutual'>{item?.displayName}</div>
                                                                    <div className='mutualid'>{item?.investment_provider_id}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }

                                </div>


                                <div className='Submit'
                                    style={{ marginTop: "2.5rem" }}
                                    onClick={postapiNewform}
                                >
                                    Submit
                                </div>
                                <div className='Submit-go'
                                    onClick={() => setlist("main")}
                                >
                                    Go Back
                                </div>
                            </div>
                        </div>
                    </>
                )
                break;


            case "Investment Provide":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Add New Investment Provider</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>




                                <div className='upload-txxt'>Display name</div>

                                <input placeholder='Name...'
                                    value={provideName}
                                    onChange={(e) => setprovideName(e.target.value)}
                                />
                                <div className='upload-txxt'>Full name</div>

                                <input placeholder='Name...'
                                    value={fullprovideName}
                                    onChange={(e) => setfullprovideName(e.target.value)}
                                />


                                <div className='upload-txxt'>Website</div>

                                <input placeholder='Link...'
                                    value={Website}
                                    onChange={(e) => setWebsite(e.target.value)}
                                />




                                <div className='upload-txxt'>Describe the investment provider</div>
                                <textarea
                                    value={profiversubdes}
                                    placeholder='Description...'

                                    onChange={(e) => setprofiversubdes(e.target.value)}
                                />

                                <div className='upload-txxt'>Company Logo</div>
                                <ImageUploadDivsub
                                    heading="Upload transaction image..."
                                    setFunc={setimageprovide}
                                    funcValue={imageprovide}
                                />
                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "9rem"
                                    }}
                                >Select parent asset class</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => setdroptoggle(!droptoggle)}
                                    >

                                        <div
                                            style={{
                                                opacity: providerselect?.name == "Click to select" && 0.25
                                            }}
                                        > {providerselect?.name}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        droptoggle &&
                                        <>
                                            <div className='selectbox'>

                                                {
                                                    datadrop?.map(item => {
                                                        return (
                                                            <div className='boxselext'
                                                                onClick={() => handldropdownprodivce(item)}
                                                            >
                                                                <img src={item?.icon} />
                                                                <div>
                                                                    <div className='nameofmutual'>{item?.name}</div>
                                                                    <div className='mutualid'>{item?.asset_id}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }

                                </div>
                                {/*  */}

                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "2rem"
                                    }}
                                >Select country</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => setcountrydropdown(!countrydropdown)}
                                    >

                                        <div
                                            style={{
                                                opacity: countryName == "Click to select" && 0.25
                                            }}
                                        > {countryName}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        countrydropdown &&
                                        <>
                                            <div className='selectbox'>

                                                {
                                                    countriesName?.map(item => {
                                                        return (
                                                            <div className='boxselext'
                                                                onClick={() => handldropdownprodivcecountry(item)}
                                                            >
                                                                <img src={item?.image} />
                                                                <div>
                                                                    <div className='nameofmutual'>{item?.name}</div>
                                                                    {/* <div className='mutualid'>{item?.asset_id}</div> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }

                                </div>
                                <div className='Submit'
                                    style={{ marginTop: "2.5rem" }}
                                    onClick={postapisubProvider}
                                >
                                    Submit
                                </div>
                                <div className='Submit-go'
                                    onClick={() => setlist("main")}
                                >
                                    Go Back
                                </div>
                            </div>
                        </div>
                    </>
                )
                break;

            case "sub Asset class input":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Add New Sub Asset Class</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>




                                <div className='upload-txxt'>What is the name of the asset class</div>

                                <input placeholder='Name...'
                                    value={subname}
                                    onChange={(e) => setsubname(e.target.value)}
                                />
                                <div className='upload-txxt'>Describe the asset class</div>
                                <textarea
                                    value={subdes}
                                    placeholder='Description...'

                                    onChange={(e) => setsubdes(e.target.value)}
                                />

                                <div className='upload-txxt'>Upload the icon</div>
                                <ImageUploadDivsub
                                    heading="Upload transaction image..."
                                    setFunc={setsubuploadedImageTracker}
                                    funcValue={subuploadedImageTracker}
                                />
                                <div className='upload-txxt'
                                    style={{
                                        marginTop: "9rem"
                                    }}
                                >Select parent asset class</div>
                                <div class="select-dropdown">
                                    <div className='selectsected'
                                        onClick={() => setdroptoggle(!droptoggle)}
                                    >

                                        <div
                                            style={{
                                                opacity: selectlabel?.name == "Click to select" && 0.25
                                            }}
                                        > {selectlabel?.name}</div>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                                            <path opacity="0.25" d="M0.160288 1.50893L3.87188 7.59337C4.15738 8.13554 4.78552 8.13554 5.12812 7.59337L8.83971 1.50893C9.23944 0.846288 8.83971 0.00286102 8.21161 0.00286102L0.788388 0.00286102C0.160287 -0.0573721 -0.239442 0.846246 0.160288 1.50893Z" fill="#7D7D7D" />
                                        </svg>
                                    </div>

                                    {
                                        droptoggle &&
                                        <>
                                            <div className='selectbox'>

                                                {
                                                    datadrop?.map(item => {
                                                        return (
                                                            <div className='boxselext'
                                                                onClick={() => handldropdown(item)}
                                                            >
                                                                <img src={item?.icon} />
                                                                <div>
                                                                    <div className='nameofmutual'>{item?.name}</div>
                                                                    <div className='mutualid'>{item?.asset_id}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </>
                                    }

                                </div>
                                <div className='Submit'
                                    style={{ marginTop: "2.5rem" }}
                                    onClick={postapisub}
                                >
                                    Submit
                                </div>
                                <div className='Submit-go'
                                    onClick={() => setlist("main")}
                                >
                                    Go Back
                                </div>
                            </div>
                        </div>
                    </>
                )
                break;

            case "delete invest":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <div
                                    onClick={() => setopentoggleclass(false)}
                                >
                                    <svg

                                        xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                        <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                            <div className='areyouseture'>Are you sure you want to delete {investdeatil?.fullName}?</div>
                            <div className='card-bottomclass'>
                                <div className='name'
                                    onClick={() => invstdeletefun(investdeatil?.investment_provider_id)}
                                >Yes</div>
                                <div className='name'

                                    onClick={() => invstdeletefun(investdeatil?.investment_provider_id)}
                                >Delete investment provider</div>


                            </div>


                        </div>

                    </>
                )

                break;

            case "click invest":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <div
                                    onClick={closesvg}
                                >
                                    <svg

                                        xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                        <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                    </svg>
                                </div>
                            </div>

                            <div className='card-bottomclass'>
                                <div className='name'

                                >Edit investment provider</div>
                                <div className='name'

                                    onClick={() => setlist("delete invest")}
                                >Delete investment provider</div>


                            </div>


                        </div>

                    </>
                )

            case "click product":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <div
                                    onClick={closesvg}
                                >
                                    <svg

                                        xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                        <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                    </svg>
                                </div>
                            </div>

                            <div className='card-bottomclass'>
                                <div className='name'

                                >Edit product</div>
                                <div className='name'

                                    onClick={() => setlist("delete productfun")}
                                >Delete product</div>


                            </div>


                        </div>

                    </>
                )
            case "delete productfun":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <div
                                    onClick={() => setopentoggleclass(false)}
                                >
                                    <svg

                                        xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                        <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                    </svg>
                                </div>
                            </div>
                            <div className='areyouseture'>Are you sure you want to delete {storeproductdetail?.productName}?</div>
                            <div className='card-bottomclass'>
                                <div className='name'
                                    onClick={() => produtdelete(storeproductdetail?.product_id)}
                                >Yes</div>
                                <div className='name'

                                    onClick={() => produtdelete(storeproductdetail?.product_id)}
                                >Delete product</div>


                            </div>


                        </div>

                    </>
                )

                break;


            case "Asset Sub class list":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>
                                <div className='name'

                                >Edit sub asset class</div>
                                <div className='name'

                                    onClick={() => setlist("delete Sub Asset class")}
                                >Delete sub Asset Class</div>


                            </div>


                        </div>

                    </>
                )

                break;
            case "delete Sub Asset class":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>
                            <div className='areyouseture'>Are you sure you want to delete {storeasubsstesdetail?.name}?</div>
                            <div className='card-bottomclass'>
                                <div className='name'
                                    onClick={() => calldeleteassetsclassssubcalsss(storeasubsstesdetail?.sub_asset_id)}
                                >Yes</div>
                                <div className='name'

                                    onClick={() => calldeleteassetsclassssubcalsss(storeasubsstesdetail?.sub_asset_id)}
                                >Delete sub asset class</div>


                            </div>


                        </div>

                    </>
                )

                break;


            case "Asset class list":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>
                                <div className='name'

                                >Edit asset class</div>
                                <div className='name'

                                    onClick={() => setlist("delete Asset class")}
                                >Delete Asset Class</div>


                            </div>


                        </div>

                    </>
                )

                break;
            case "delete Asset class":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Actions</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>
                            <div className='areyouseture'>Are you sure you want to delete {storeasstesdetail?.name}?</div>
                            <div className='card-bottomclass'>
                                <div className='name'
                                    onClick={() => calldeleteassetsclasss(storeasstesdetail?.asset_id)}
                                >Yes</div>
                                <div className='name'

                                    onClick={() => calldeleteassetsclasss(storeasstesdetail?.asset_id)}
                                >Delete asset class</div>


                            </div>


                        </div>

                    </>
                )

                break;


            case "Asset class":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Add New Asset Class</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>




                                <div className='upload-txxt'>Describe the asset class</div>

                                <input placeholder='Name...'
                                    value={namelist}
                                    onChange={(e) => setnamelist(e.target.value)}
                                />
                                <div className='upload-txxt'>Describe the asset class</div>
                                <textarea
                                    value={des}
                                    placeholder='Description...'

                                    onChange={(e) => setdes(e.target.value)}
                                />

                                <div className='upload-txxt'>Upload the icon</div>
                                <ImageUploadDiv
                                    heading="Upload transaction image..."
                                    setFunc={setuploadedImageTracker}
                                    funcValue={uploadedImageTracker}
                                />
                                <div className='Submit'
                                    onClick={postapi}
                                >
                                    Submit
                                </div>
                                <div className='Submit-go'
                                    onClick={() => setlist("main")}
                                >
                                    Go Back
                                </div>
                            </div>
                        </div>
                    </>
                )
                break;
            case "main":
                return (
                    <>
                        <div className='addnewclass'>
                            <div className='toptitle'>
                                <div className='addnew'>Add New</div>
                                <svg
                                    onClick={() => setopentoggleclass(false)}
                                    xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <circle cx="12.5" cy="12.5" r="12.5" fill="#44C2F4" />
                                    <path d="M15.9624 8.53769C16.0608 8.63615 16.1161 8.76968 16.1161 8.90892C16.1161 9.04816 16.0608 9.1817 15.9624 9.28015L12.9925 12.25L15.9624 15.2198C16.0608 15.3183 16.1161 15.4518 16.1161 15.5911C16.1161 15.7303 16.0608 15.8639 15.9624 15.9623C15.8639 16.0608 15.7304 16.1161 15.5911 16.1161C15.4519 16.1161 15.3184 16.0608 15.2199 15.9623L12.2501 12.9925L9.28021 15.9623C9.18176 16.0608 9.04822 16.1161 8.90898 16.1161C8.76974 16.1161 8.63621 16.0608 8.53775 15.9623C8.43929 15.8639 8.38398 15.7303 8.38398 15.5911C8.38398 15.4518 8.43929 15.3183 8.53775 15.2198L11.5076 12.25L8.53775 9.28015C8.43929 9.1817 8.38398 9.04816 8.38398 8.90892C8.38398 8.76968 8.43929 8.63615 8.53775 8.53769C8.63621 8.43923 8.76974 8.38392 8.90898 8.38392C9.04822 8.38392 9.18176 8.43923 9.28021 8.53769L12.2501 11.5075L15.2199 8.53769C15.3184 8.43923 15.4519 8.38392 15.5911 8.38392C15.7304 8.38392 15.8639 8.43923 15.9624 8.53769Z" fill="white" />
                                </svg>
                            </div>

                            <div className='card-bottomclass'>
                                <div className='name'
                                    onClick={() => setlist("Asset class")}
                                >Asset class</div>
                                <div className='name'
                                    onClick={() => setlist("sub Asset class input")}

                                >Sub Asset Class</div>
                                <div className='name'
                                    onClick={() => setlist("Investment Provide")}

                                >Investment Provider</div>
                                <div className='name'
                                    onClick={() => setlist("Add New Product")}

                                >Product</div>

                            </div>


                        </div>

                    </>
                )

                break;

            default:
                break;
        }
    }

    return (
        <>
            {mainfunct()}
        </>
    )
}




export const ImageUploadDiv = ({ heading, setFunc, funcValue }) => {
    // const {planBAccountPicUploading, setplanBAccountPicUploading} =
    //     useContext(planBContext);
    const [planBAccountPicUploading, setplanBAccountPicUploading] = useState(false)
    return (
        <div
            className={classNames.imageUploadDiv}
            style={{ position: 'relative' }}
        >
            <div className={classNames.heading} style={{ display: 'none' }}>
                {heading}
            </div>
            <div
                className={classNames.imageDiv}

            >
                {funcValue ? (
                    <img
                        style={{
                            width: "137px",
                            height: "137px",
                            borderRadius: "154px",
                        }}
                        src={funcValue}
                        alt="planBAccountPic"
                        className={classNames.profileImg}
                        htmlFor="profileUpdateImgPlanB"
                    // style={{ width: '100%', height: '100%' }}
                    />
                ) : (
                    ''
                )}
                {planBAccountPicUploading ? (
                    <div className={classNames.overlayDiv}>
                        {/* <img
                            src={uploading}
                            alt="uploading"
                            className={classNames.uploadingimg}
                        /> */}
                    </div>
                ) : (
                    ''
                )}
            </div>



            <label
                htmlFor="profileUpdateImgPlanB"
                className={classNames.uploadFileDiv}
            >
                <input
                    className={classNames.uploadNewPicPlanB}
                    type="file"
                    onChange={(e) => {
                        uploadImageFunc(e, setFunc, setplanBAccountPicUploading);
                    }}
                    accept="image/*"
                    id="profileUpdateImgPlanB"
                    style={{ display: 'none' }}
                />
                <div
                    style={{
                        width: "137px",
                        height: "137px",
                        inset: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: '700',
                        fontSize: '1.5rem',
                        borderRadius: "154px",
                        border: "1px solid #d3d3d3",
                        position: "absolute",
                        // background: "#FFF"
                    }}
                >
                    {funcValue
                        ? ''
                        : planBAccountPicUploading
                            ? 'Uploading...'
                            :

                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="64" viewBox="0 0 60 64" fill="none">
                                <path d="M14.18 34.828C14.504 35.544 15.216 36 16 36H24L24 62C24 63.104 24.896 64 26 64H34C35.104 64 36 63.104 36 62V36H44C44.784 36 45.496 35.54 45.82 34.828C46.148 34.112 46.02 33.276 45.504 32.684L31.504 16.684C31.124 16.248 30.576 16 30 16C29.424 16 28.876 16.252 28.496 16.684L14.496 32.684C13.976 33.272 13.856 34.112 14.18 34.828Z" fill="#E5E5E5" />
                                <path d="M8 20V8L52 8V20H60V4C60 1.788 58.208 0 56 0L4 0C1.788 0 0 1.788 0 4V20H8Z" fill="#E5E5E5" />
                            </svg>
                    }
                </div>
            </label >
        </div >
    );
};




















export const ImageUploadDivsub = ({ heading, setFunc, funcValue }) => {
    // const {planBAccountPicUploading, setplanBAccountPicUploading} =
    //     useContext(planBContext);
    const [subplanBAccountPicUploading, setsubplanBAccountPicUploading] = useState(false)
    return (
        <div
            className={classNames.imageUploadDiv}
            style={{ position: 'relative' }}
        >
            <div className={classNames.heading} style={{ display: 'none' }}>
                {heading}
            </div>
            <div
                className={classNames.imageDiv}

            >
                {funcValue ? (
                    <img
                        style={{
                            width: "137px",
                            height: "137px",
                            borderRadius: "154px",
                        }}
                        src={funcValue}
                        // alt="planBAccountPic"
                        className={classNames.profileImg}
                        htmlFor="profileUpdateImgPlanB"
                    // style={{ width: '100%', height: '100%' }}
                    />
                ) : (
                    ''
                )}
                {subplanBAccountPicUploading ? (
                    <div className={classNames.overlayDiv}>
                        {/* <img
                            src={uploading}
                            alt="uploading"
                            className={classNames.uploadingimg}
                        /> */}
                    </div>
                ) : (
                    ''
                )}
            </div>



            <label
                htmlFor="profileUpdateImgPlanB"
                className={classNames.uploadFileDiv}
            >
                <input
                    className={classNames.uploadNewPicPlanB}
                    type="file"
                    onChange={(e) => {
                        uploadImageFunc(e, setFunc, setsubplanBAccountPicUploading);
                    }}
                    accept="image/*"
                    id="profileUpdateImgPlanB"
                    style={{ display: 'none' }}
                />
                <div
                    style={{
                        width: "137px",
                        height: "137px",
                        inset: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: '700',
                        fontSize: '1.5rem',
                        borderRadius: "154px",
                        border: "1px solid #d3d3d3",
                        position: "absolute",
                        // background: "#FFF"
                    }}
                >
                    {funcValue
                        ? ''
                        : subplanBAccountPicUploading
                            ? 'Uploading...'
                            :

                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="64" viewBox="0 0 60 64" fill="none">
                                <path d="M14.18 34.828C14.504 35.544 15.216 36 16 36H24L24 62C24 63.104 24.896 64 26 64H34C35.104 64 36 63.104 36 62V36H44C44.784 36 45.496 35.54 45.82 34.828C46.148 34.112 46.02 33.276 45.504 32.684L31.504 16.684C31.124 16.248 30.576 16 30 16C29.424 16 28.876 16.252 28.496 16.684L14.496 32.684C13.976 33.272 13.856 34.112 14.18 34.828Z" fill="#E5E5E5" />
                                <path d="M8 20V8L52 8V20H60V4C60 1.788 58.208 0 56 0L4 0C1.788 0 0 1.788 0 4V20H8Z" fill="#E5E5E5" />
                            </svg>
                    }
                </div>
            </label >
        </div >
    );
};