import React, { useContext, useEffect } from 'react';
import classNames from './bplanner.module.scss';
import Layout from '../../Layout/Layout';
import planBIcon from '../../static/images/sidebar-icons/planB.svg';
import useWindowDimensions from '../../utils/WindowSize';
import NewMobileLayout from '../../Layout/MobilePages/NewMobileLayout';
import { BankContext } from '../../context/Context';
import { Redirect } from 'react-router-dom';
import ProgressPage from '../../components/ProgressPage';
import { useMarketContext } from '../../context/MarketContext';
import { PlanB } from '../../components/ReactCollapsedSidebar';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useAppContextDetails } from '../../context/AppContext';
import { getAppByCode } from '../../queryHooks';
import { useState } from 'react';
import { LoadingAnimation } from '../../components/LoadingAnimation';
import EditSidebar from './Components/EditSidebar';
import { Pincard } from '../../components/CollapsedSidebar';

function BPlannerPage() {
  const { width, height } = useWindowDimensions();
  const { email, iceSidebarOpen, sidebarCollapse, selectedTopItem } =
    useContext(BankContext);
  const {
    planBOpened,
    setPlanBOpened,
    buyBlocks,
    setBuyBlocksprofileUpdateSidebar,
    profileUpdateSidebar,
    setProfileUpdateSidebar,
    globalappIcon,
    iconLoading,
    setIconLoading,
    planBPin,
    setPlanBPin,
  } = useMarketContext();

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {width > 768 || width > height ? (
        <Layout
          active="bplanner"
          className={classNames.bplannerPage}
          hideFooter
        >
          <PlanB />
          {/* {planBOpened ? <PlanB /> : planBPin ? <ProgressPage /> : <PlanB />} */}
          <EditSidebar />

          {iconLoading ? (
            <div className="loadingIcon">
              <LoadingAnimation icon={globalappIcon} width={200} />
            </div>
          ) : (
            ''
          )}
        </Layout>
      ) : (
        <NewMobileLayout />
      )}
    </>
  );
}

export default BPlannerPage;

export const BuyBlocksContainer = () => {
  const [appColor, setAppColor] = useState('');
  const [appIcon, setAppIcon] = useState('');

  const [confirmBlockBuy, setConfirmBlockBuy] = useState(false);

  const {
    email,
    token,
    buyBlocksLeft,
    setBuyBlocksLeft,
    blocksContainerBlock,
    setBlocksContainerBlock,
    blockPerc,
    setBlockPerc,
    blockAmount,
    setBlockAmount,
    roundedBlockAmount,
    setRoundedBlockAmount,
    profileID,
    setProfileID,
    purchasedBlockResponse,
    setPurchasedBlockResponse,
    blockLoading,
    setBlockLoading,
    sidebarCollapse,
  } = useContext(BankContext);

  const { globalappIcon, setGlobalAppIcon, globalappColor, setGlobalAppColor } =
    useMarketContext();

  const { appCode } = useAppContextDetails();

  const deactivatedContainer = {
    opacity: '0.5',
    pointerEvents: 'none',
  };

  const applyStyle = (condition) => {
    if (condition) {
      return deactivatedContainer;
    }
  };

  useEffect(() => {
    let obj = {
      app_code: 'gxpulse',
      email: email,
      displayCurrency: 'USD',
      include_coins: ['USDT'],
    };

    axios
      .post('https://comms.globalxchange.io/coin/vault/service/coins/get', obj)
      .then((response) => {
        let res = response.data;
        setBuyBlocksLeft(res);
      });

    axios
      .get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${email}`
      )
      .then((response) => {
        let res = response.data.userApps;
        let result = res?.filter((item) => {
          return item.app_code == 'gxpulse';
        });
        setProfileID(result);
      });
  }, []);

  useEffect(() => {
    let roundedAmount = Math.floor(blockAmount / 100);
    setRoundedBlockAmount(roundedAmount * 100);
    setBlockPerc();
  }, [blockAmount]);

  useEffect(() => {
    if (blockPerc) {
      let amount = buyBlocksLeft.coins_data[0].coinValue * (blockPerc / 100);
      setBlockAmount(amount);
      let roundedAmount = Math.floor(amount / 100);
      setRoundedBlockAmount(roundedAmount * 100);
    }
  }, [blockPerc]);

  function buyBlocksApi() {
    setConfirmBlockBuy(false);
    setBlockLoading(true);
    let objj = {
      email,
      token,
      coin: 'USDT',
      block_template_id: '66mejkslanq40sg',
      no_of_blocks: roundedBlockAmount / 100,
      app_code: 'gxpulse',
      profile_id: profileID[0].profile_id,
    };

    axios
      .post(
        'https://comms.globalxchange.io/retired/block/user/buy/blocktemplate',
        objj
      )
      .then((response) => {
        let res = response.data;
        setBlockLoading(false);
        setPurchasedBlockResponse(res);
      });
  }

  useEffect(() => {
    getAppByCode(appCode).then((response) => {
      const color = response?.color_codes[0];
      const logo = response?.app_icon;
      setAppColor(color);
      setAppIcon(logo);
    });
  }, [appCode]);

  useEffect(() => {
    getAppByCode(appCode).then((response) => {
      const color = response?.color_codes[0];
      const logo = response?.app_icon;
      setGlobalAppColor(color);
      setGlobalAppIcon(logo);
    });
  }, []);

  return (
    <div className={classNames.buyBlocksWhole}>
      <div
        className={classNames.balanceContainer}
        style={applyStyle(blockLoading)}
      >
        <div
          className={classNames.resetBtn}
          onClick={() => {
            if (confirmBlockBuy) {
              setConfirmBlockBuy(false);
            } else {
              let blockCountt = document.querySelector('.blockCount');
              setBlockPerc('');
              setBlockAmount('');
              setRoundedBlockAmount('');
              setBlocksContainerBlock(false);
              setBlocksContainerBlock(false);
              blockCountt.value = 0;
            }
          }}
          // style={{ left: sidebarCollapse ? '17%' : '17%' }}
        >
          {confirmBlockBuy ? (
            <svg
              width="30"
              height="20"
              viewBox="0 0 30 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.212866 10.102L8.35609 18.8648C8.50676 19.0267 8.71808 19.1193 8.9394 19.1193H12.4386C13.1345 19.1193 13.4959 18.2896 13.022 17.7801L5.37701 9.55996L13.022 1.33983C13.4959 0.830294 13.1345 0.000652313 12.4386 0.000652313H8.9394C8.71808 0.000652313 8.50676 0.0925579 8.35609 0.25511L0.212866 9.01791C-0.0709753 9.32363 -0.0709753 9.79628 0.212866 10.102Z"
                fill="#44C2F4"
              />
              <path
                d="M8.79885 10.102L16.9421 18.8642C17.0928 19.0267 17.3041 19.1187 17.5254 19.1187H21.0246C21.7205 19.1187 22.0818 18.289 21.6079 17.7795L17.2491 13.0924L28.7496 13.0924C29.4398 13.0924 30 12.5322 30 11.842V7.27612C30 6.5859 29.4398 6.02572 28.7496 6.02572L17.2497 6.02572L21.6086 1.33922C22.0825 0.829685 21.7211 4.3869e-05 21.0253 4.3869e-05H17.526C17.3047 4.3869e-05 17.0934 0.0919495 16.9427 0.254501L8.79948 9.0173C8.51501 9.32302 8.51501 9.7963 8.79885 10.102Z"
                fill="#44C2F4"
              />
            </svg>
          ) : (
            <svg
              viewBox="0 0 25 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7391 9.12725C22.4767 8.7171 21.9352 8.63506 21.5415 8.91397L18.8836 10.9156C18.5555 11.1616 18.4571 11.6211 18.6704 11.9819C19.343 13.1304 19.6875 14.3609 19.6875 15.6406C19.6875 19.7093 16.3734 23.0234 12.3047 23.0234C8.23601 23.0234 4.92189 19.7093 4.92189 15.6406C4.92189 11.8507 7.79298 8.7171 11.4844 8.30694V10.7187C11.4844 11.2109 11.8781 11.5226 12.3047 11.539C12.4687 11.539 12.6493 11.4898 12.7969 11.3749L19.3594 6.45314C19.8024 6.12501 19.8024 5.46871 19.3594 5.14058L12.7969 0.164008C12.6493 0.0492735 12.4688 0 12.3047 0C11.8781 0 11.4844 0.344587 11.4844 0.820314V3.36881C5.08589 3.77891 0 9.12725 0 15.6406C0 22.4328 5.51251 28 12.3047 28C19.0969 28 24.6094 22.4328 24.6094 15.6406C24.6094 13.3437 23.9531 11.096 22.7391 9.12725Z"
                fill="#44C2F4"
              />
            </svg>
          )}
        </div>
        <div className={classNames.balanceBox}>
          <div>Buy Blocks</div>
          <div>How Much Do You Want To Invest?</div>
          <div>
            <div>Balance</div>
            <div>
              <div>
                {buyBlocksLeft ? (
                  <img src={buyBlocksLeft.coins_data[0].coinImage} alt="test" />
                ) : (
                  <Skeleton circle width={23} height={23} />
                )}
              </div>
              <div>
                {buyBlocksLeft ? (
                  buyBlocksLeft.coins_data[0].coinSymbol
                ) : (
                  <Skeleton width={100} />
                )}
              </div>
            </div>
            <div>
              {buyBlocksLeft ? (
                buyBlocksLeft.coins_data[0].coinValue.toFixed(2)
              ) : (
                <Skeleton width={100} />
              )}
            </div>
          </div>
        </div>
      </div>
      {confirmBlockBuy ? (
        <ConfirmBlock
          buyBlocksApi={buyBlocksApi}
          roundedBlockAmount={roundedBlockAmount}
        />
      ) : purchasedBlockResponse ? (
        <div className={classNames.blockPurchasedResponse}>
          <div className={classNames.topDiv}>
            <div>{purchasedBlockResponse.status ? 'Success' : 'Failed'}</div>
            <div>
              {purchasedBlockResponse.status
                ? `Your Have Bought ${
                    roundedBlockAmount / 100
                  } New Blocks. Here Is
              Your Updated Liquid Balance`
                : purchasedBlockResponse.message}
            </div>
            <div>
              <div>
                <div>
                  {buyBlocksLeft ? (
                    <img
                      src={buyBlocksLeft.coins_data[0].coinImage}
                      alt="test"
                    />
                  ) : (
                    <Skeleton circle width={23} height={23} />
                  )}
                </div>
                <div>
                  {buyBlocksLeft ? (
                    buyBlocksLeft.coins_data[0].coinSymbol
                  ) : (
                    <Skeleton width={100} />
                  )}
                </div>
              </div>
              <div>
                {purchasedBlockResponse.status
                  ? purchasedBlockResponse?.result?.data?.PaymentResponse?.updated_balance?.toFixed(
                      2
                    )
                  : buyBlocksLeft?.coins_data[0]?.coinValue?.toFixed(2)}
              </div>
            </div>
          </div>
          <div className={classNames.bottomBtns}>
            <div onClick={() => setPurchasedBlockResponse('')}>
              Buy More Blocks
            </div>
            <div onClick={() => setPurchasedBlockResponse('')}>
              See Block Vault
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames.buySection}>
          <div className={classNames.buyOptions}>
            <div style={applyStyle(blocksContainerBlock)}>
              <div className={classNames.percentageOption}>
                <div>Enter Percentage Of Balance</div>
                <div
                  className="buyBlocksMap1"
                  style={{ pointerEvents: buyBlocksLeft ? 'auto' : 'none' }}
                >
                  <div onClick={() => setBlockPerc(50)}>50%</div>
                  <div onClick={() => setBlockPerc(100)}>100%</div>
                  <div>
                    <input
                      type="number"
                      onChange={(event) => {
                        setBlockPerc(event.target.value);
                      }}
                      placeholder="0.00%"
                      value={blockPerc}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames.otherOption}>
                <div>Amount</div>
                <div>
                  <input
                    type="number"
                    placeholder=" 0.00 USDT"
                    value={blockAmount}
                    onChange={(event) => {
                      setBlockAmount(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className={classNames.otherOption}>
                <div>Rounded</div>
                <div>
                  <input
                    type="number"
                    placeholder=" 0.00 USDT"
                    value={roundedBlockAmount}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                borderTop: '1px solid #e5e5e5',
                height: '1px',
                marginTop: '1.5rem',
              }}
            ></div>
            <div
              className={classNames.blocksOption}
              style={applyStyle(blockPerc || blockAmount)}
            >
              <div>
                <div>Amount Of Blocks</div>
                <div
                  className={`${classNames.otherOption} buyBlocksMap2`}
                  style={applyStyle(blockPerc || blockAmount)}
                >
                  <div
                    onClick={() => {
                      setBlocksContainerBlock(1);
                      setRoundedBlockAmount(100);
                    }}
                  >
                    1
                  </div>
                  <div
                    onClick={() => {
                      setBlocksContainerBlock(2);
                      setRoundedBlockAmount(200);
                    }}
                  >
                    2
                  </div>
                  <div
                    onClick={() => {
                      setBlocksContainerBlock(5);
                      setRoundedBlockAmount(500);
                    }}
                  >
                    5
                  </div>
                </div>
                <div className={classNames.otherOption}>
                  <div>Custom</div>
                  <div>
                    <input
                      type="number"
                      className="blockCount"
                      onClick={() => setBlocksContainerBlock(true)}
                      onChange={(event) => {
                        setBlocksContainerBlock(event.target.value);
                        setRoundedBlockAmount(event.target.value * 100);
                      }}
                      placeholder="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames.buyButton}
            style={{
              background: roundedBlockAmount / 100 > 0 ? '#44C2F4' : '',
              color: roundedBlockAmount / 100 > 0 ? 'white' : '',
              pointerEvents: buyBlocksLeft ? 'auto' : 'none',
            }}
            // onClick={roundedBlockAmount / 100 > 0 ? buyBlocksApi : ''}
            onClick={() => setConfirmBlockBuy(true)}
          >
            Purchase {roundedBlockAmount ? roundedBlockAmount / 100 : 0} Blocks
            {/* <div></div> */}
          </div>
          {blockLoading ? (
            <div className="loadingIcon" style={{ height: '100%' }}>
              <LoadingAnimation icon={appIcon} width={'20vw'} />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

const ConfirmBlock = ({ roundedBlockAmount, buyBlocksApi }) => {
  return (
    <div className={classNames.confirmBlock}>
      <div className={classNames.contentContainer}>
        <div>Are You Sure?</div>
        <div>
          By making this purchase you are committing to allocate{' '}
          {roundedBlockAmount?.toFixed(2)} USDT per month starting on this date
        </div>
      </div>
      <div onClick={() => buyBlocksApi()}>Confirm Purchase</div>
    </div>
  );
};
