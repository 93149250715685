import React from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from './affiliate.module.scss';
import Layout from '../../Layout/Layout';
import affiliate from '../../static/images/sidebarFullLogos/affiliate.svg';
import appleDownload from '../../static/images/appleDownload.svg';
import androidDownload from '../../static/images/androidDownload.svg';
import { useLoadAffiliateAppData } from './api';
import HeaderAdvisor from './HeaderAdvisor';
function AffiliatePage() {
  const { data: affiliateResponseData, isLoading: isAffiliateResponseLoading } =
    useLoadAffiliateAppData();

  const iosLink = affiliateResponseData?.data?.logs?.[0]?.ios_app_link;
  const androidLink = affiliateResponseData?.data?.logs?.[0]?.android_app_link;

  return (
    <Layout active="react" className={classNames.affiliatePage} hideFooter>
      <HeaderAdvisor />


      
    </Layout>
  );
}

export default AffiliatePage;
